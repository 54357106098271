import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "components/ui/card";
import { SubOrgContext } from 'context/SubOrganizationContext';
import { Combobox } from 'components/ui/combobox';
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';
import { Button } from 'components/ui/button';
import { FileSelectionModal } from '../Common/File/FileSelectionModal';
import { FileTypeChecker } from './helper';
import PDFViewer from './Viewer/PDFViewer';
import OfficeViewer from './Viewer/OfficeViewer';
import ImageViewer from './Viewer/ImageViewer';


const DocumentDisplay = () => {
    const { collections } = useContext(SubOrgContext);
    const { state, dispatch } = useContext(AiLabContext);
    const [isFileSelectOpen, setIsFileSelectOpen] = useState(false);
    const coll_id = state?.aiTeacher?.ragForm?.collection_id || '';
    const selectedDoc = state?.aiTeacher?.selectedDocument || null;
    const page = state?.aiTeacher?.currentPage || 1;

    const handleSetPage = (num) => {
        dispatch({type: "SET_CURRENT_PAGE", value: num})
    }

    const handleCollectionSelection = (coll) => {
        dispatch({type: "UPDATE_RAG_FIELD", field: "collection_id", value: coll._id})
        console.log(coll)
    };

    const handleFileSelect = (file) => {
        console.log("confirmed", file)
        dispatch({type: "SET_DOCUMENT", value: file})
    }


    return (
        <Card className="w-full flex flex-col justify-between h-[700px] p-2 gap-0 p-0 overflow-hidden">
            
            <CardHeader className="p-0 bg-[#34343c] text-gray-100 rounded-t-md text-xs">
                <div className="flex flex-row items-center justify-start p-2 gap-2 shadow select-none">
                    <Combobox 
                        className=""
                        comboboxWidth='w-[240px]' 
                        items={collections} 
                        setter={handleCollectionSelection} 
                        selectedValue={collections?.find((coll) => coll._id === coll_id)} 
                        defaultDisplayed="Select Collection"
                        disabled={collections?.length === 0} 
                    />
                    <Button
                        onClick={() => setIsFileSelectOpen(true)}
                        disabled={state?.aiTeacher?.ragForm?.collection_id?.trim() === ''}
                    >
                        Select File
                    </Button>
                    <span className="text-center">
                        {selectedDoc ? selectedDoc?.name : "No document selected"}
                    </span>
                </div>
            </CardHeader>

            <CardContent className="flex flex-row p-0 h-full overflow-hidden">
                {!selectedDoc ?
                    <div className='flex items-center justify-center w-full text-gray-600'>
                        <span>Select document to display</span>
                    </div>
                : FileTypeChecker.isPDF(selectedDoc?.name) ? 
                    <PDFViewer document={selectedDoc} page={page} setPage={handleSetPage}/>
                : FileTypeChecker.isOFFICEFILE(selectedDoc?.name) ?
                    <OfficeViewer document={selectedDoc} page={page} />
                : FileTypeChecker.isIMAGE(selectedDoc?.name) ?
                    <ImageViewer  document={selectedDoc} page={page} />
                : <div className='flex items-center justify-center w-full text-gray-600'>
                    <span>No renderer for this file type</span>
                </div>}
            </CardContent>

            {/* File Selection Modal */}
            <FileSelectionModal
                isOpen={isFileSelectOpen} 
                setIsOpen={setIsFileSelectOpen}
                collection_id={coll_id} 
                onFileConfirm={handleFileSelect}
                fileSystem={state?.aiTeacher?.fileSystem}
                setFileSystem={(files) => dispatch({type: "SET_FILE_SYSTEM", value: files})}
            />
        </Card>
    );
};

export default DocumentDisplay;