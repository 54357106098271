import React, { useEffect, useState } from "react";
import { ChevronRight, File, Folder } from "lucide-react";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table";
import { ScrollArea } from "components/ui/scroll-area";

export function FileSelection({ fileSystem, onFileClick }) {
  // Find the first folder as the default
  const defaultFolder = fileSystem.length > 0 ? fileSystem[0] : null;
  const defaultFolderId = defaultFolder ? defaultFolder.id : "root";

  const [currentFolderId, setCurrentFolderId] = useState(defaultFolderId);
  const [path, setPath] = useState(defaultFolder ? [{ id: defaultFolder.id, name: defaultFolder.name }] : []);
  const [selectedFile, setSelectedFile] = useState(null); // Store only one selected file

  // Get files in the current folder
  const getCurrentFiles = (folderId) => {
    const folder = findItemById(folderId);
    return folder?.children || [];
  };

  // Find a file/folder by ID
  const findItemById = (id, nodes = fileSystem) => {
    for (const item of nodes) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItemById(id, item.children);
        if (found) return found;
      }
    }
    return null;
  };

  // Handle navigation into a folder
  const handleClick = (item) => {
    if (item.type === "folder") {
      setCurrentFolderId(item.id);
      setPath([...path, { id: item.id, name: item.name }]); // Append to path (left-to-right)
      setSelectedFile(null); // Clear selection when entering a folder
    }
  };

  // Handle breadcrumb navigation
  const handleBreadcrumbClick = (index) => {
    const newPath = path.slice(0, index + 1);
    setPath(newPath);
    setCurrentFolderId(newPath[newPath.length - 1].id);
    setSelectedFile(null);
  };

  // Handle file selection (only one file can be selected)
  const handleFileSelection = (file) => {
    setSelectedFile(selectedFile?.id === file.id ? null : file);
    onFileClick(selectedFile?.id === file.id ? null : file);
  };

  useEffect(() => {
    setSelectedFile(null);
    onFileClick(null);
  }, [])

  const currentFiles = getCurrentFiles(currentFolderId);

  return (
    <div className="w-full mx-auto p-4 space-y-4">
      {/* Breadcrumb Navigation */}
      <Breadcrumb>
        <BreadcrumbList>
          {path.map((item, index) => (
            <React.Fragment key={item.id}>
              {index < path.length - 1 ? (
                <>
                  <BreadcrumbItem>
                    <BreadcrumbLink onClick={() => handleBreadcrumbClick(index)} href="#">
                      {item.name}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator>
                    <ChevronRight className="h-4 w-4" />
                  </BreadcrumbSeparator>
                </>
              ) : (
                <BreadcrumbItem>
                  <BreadcrumbPage>{item.name}</BreadcrumbPage>
                </BreadcrumbItem>
              )}
            </React.Fragment>
          ))}
        </BreadcrumbList>
      </Breadcrumb>

      {/* File/Folder List */}
      <div className="border rounded-lg">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50%]">Name</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <ScrollArea className="max-h-72">
              {currentFiles.map((file) => (
                <TableRow
                  key={file.id}
                  className={`cursor-pointer ${selectedFile?.id === file.id ? "bg-blue-50" : ""}`}
                  onClick={file.type === "file" ? () => handleFileSelection(file) : undefined}
                  onDoubleClick={file.type === "folder" ? () => handleClick(file) : undefined}
                >
                  <TableCell>
                    <div className="flex items-center gap-2">
                      {file.type === "folder" ? <Folder className="h-4 w-4 text-yellow-400" /> : <File className="h-4 w-4" />}
                      {file.name}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {currentFiles.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} className="text-center text-muted-foreground">
                    This folder is empty
                  </TableCell>
                </TableRow>
              )}
            </ScrollArea>
          </TableBody>
        </Table>
      </div>

      {/* Selected File Display */}
      <div className="text-sm text-muted-foreground">
        {selectedFile ? `Selected File: ${selectedFile.name}` : "No file selected"}
      </div>
    </div>
  );
}
