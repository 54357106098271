import React from 'react';
import { FileTypeChecker } from '../helper';

const OfficeViewer = ({ document, page }) => {
    // Construct the Office Web Viewer URL
    const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        document?.presigned_url || ''
    )}`;

    return (
        <div className="h-full flex flex-col w-full">
            <div className="h-full w-full relative">
                 {/* Download prevent overlay */}
                 {FileTypeChecker.isPPT(document?.name) ? 
                <div className="absolute z-10 bg-opacity-0 bg-blue-200 w-[200px] h-[1.6rem] bottom-0 right-0"></div>
                : FileTypeChecker.isWORD(document?.name) ?
                <div className="absolute z-10 bg-opacity-0 bg-blue-200 w-[200px] h-[1.3rem] bottom-0 right-0"></div>
                : FileTypeChecker.isSPREADSHEET(document?.name) ?
                <div className="absolute z-10 bg-opacity-0 bg-blue-200 w-[200px] h-[1.6rem] bottom-0 right-0"></div>
                : ""
                }
                <iframe
                    title="Office Viewer"
                    src={officeViewerUrl}
                    className="w-full h-full rounded-b-md"
                    frameBorder="0"
                    onError={() => {
                        console.log('Error loading Office document');
                    }}
                />
            </div>
        </div>
    );
};

export default OfficeViewer;