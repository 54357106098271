/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../context/GlobalState';
import { httpRequest } from '../../../../utils/httpsRequest';
import { AppLoader } from '../../../../components/LoaderSpinner';
import NewStyledTable from '../../../../components/ui/StyledTable';
import { MdDelete, MdEdit } from 'react-icons/md'
import AlertModal from '../../../../components/Alertmodal'
import './sub-organization-styles.css'
import { Link, useParams } from 'react-router-dom';
import CreateSubOrganizationDialog from '../../../../components/AddSubOrgForm';
import UpdateSubOrganizationDialog from '../../../../components/UpdateSubOrganizationDialog';
import { OrganizationContext } from 'context/OrganizationContext';

function SubOrganizationSetting() {
    const [tableData, setTableData] = useState([]);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    
    const { addNewNotifcation, user} = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    const { oragID } = useParams()

    useEffect(() => {
        setTableData(selectedOrganization?.subOrganizations.map(item => ({...item, id: item._id})));
        setLoading(false);
    }, [selectedOrganization]);

    const handleDelete = (itemId) => {
        if(selectedOrganization.userRole === 'superAdmin' || selectedOrganization.userRole === 'admin') {
            setAlertModal(true)
            const data = tableData.find((item) => item.id === itemId)
            setSelectedItem(data)
        }
        else {
            addNewNotifcation('Sorry, you are not authorized to delete sub-organization', 'warning')
        }
    }

    const handleUpdate = (itemId) => {
        if(selectedOrganization.userRole === 'superAdmin' || selectedOrganization.userRole === 'admin') {
            setOpenUpdateModal(true)
            const data = tableData.find((item) => item.id === itemId)
            setSelectedItem(data)
        } else {
            addNewNotifcation('Sorry, you are not authorized to update sub-organization', 'warning')
        }
    }

    const handleOnDelete = () => {
        setBtnLoading(true)
        const deleteObj = {
            subOrganizationId: selectedItem._id,
            organizationId: oragID
        }
        httpRequest.delete(`/db/sub-organizations/delete-sub-organization`, {data: deleteObj})
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Sub-organization deleted successfully', 'success')
            setTimeout(() => {
                window.location = `/organizations/${oragID}/sub`
            }, 1500)
        })
        .catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Sub-organization could not be deleted', 'danger')
        })
        .finally(() => {
            setBtnLoading(false)
            setAlertModal(false)
        })
    }

    const handleAddNewSubOrg = () => {
        if (selectedOrganization.userRole !== 'superAdmin') {
            addNewNotifcation('Sorry, you are not authorized to add sub-organization', 'warning')
        } else {
            setShow(true)
        }
    }

    const subOrgRowActions = [
        {'name': 'Edit', 'icon': <MdEdit size={18} />, 'onclick': handleUpdate},
        {'name': 'Remove', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleDelete, isDelete: true},
    ]

    const subOrgTableActions = [
        // {'name': 'Delete Selected', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleDeleteSelected},
        {'name': 'Add New', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleAddNewSubOrg}
    ]

    const organizationMembersWithSuperAdmin = selectedOrganization.members.length + 1

    return (
        <div className='flex p-8 overflow-y-scroll w-full min-w-[700px]'>
            <div className="w-full">
                <div className='py-4 h-auto'>
                    {loading ? (
                        <AppLoader />
                    ) : (
                        <NewStyledTable
                        cardTitle={`Sub Organizations (${tableData.length})`}
                        columns={[
                            { 
                            field: 'name', 
                            headerName: 'Name', 
                            renderCell: (rowData) => (
                                <div onClick={() => localStorage.setItem('selectedSubOrg', rowData.id)}>
                                    <Link className='flex items-center gap-2 text-[blue] hover:underline' to={`${rowData.id}/ai-lab`}>
                                        {rowData.name}
                                    </Link>
                                </div>
                            ) 
                            }, 
                            { 
                            field: 'members', 
                            headerName: 'Members', 
                            renderCell: (rowData) => rowData.isMain ? organizationMembersWithSuperAdmin : rowData.members.length 
                            }
                        ]}
                        data={tableData}
                        rowActions={subOrgRowActions}
                        tableActions={subOrgTableActions}
                        // multiSelect={true}
                        />
                    )}  
                    <CreateSubOrganizationDialog show={show} setShow={setShow} addNewNotifcation={addNewNotifcation} />

                    <UpdateSubOrganizationDialog
                    show={openUpdateModal}
                    setShow={setOpenUpdateModal}
                    subOrg={selectedItem}
                    setSubOrg={setSelectedItem}
                    user={user}
                    addNewNotification={addNewNotifcation}
                    />

                    <AlertModal
                        loading={btnLoading}
                        setopenModal={setAlertModal}
                        openModal={alertModal}
                        onDelete={handleOnDelete}
                    />
                </div>
            </div>
        </div>
    );
}

export default SubOrganizationSetting;