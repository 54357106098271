import React, { useContext, useEffect, useRef, useState } from 'react'
import { PageContent, PageContentHeader, PageContentHeaderText, PageContentHeaderTitle, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from 'components/NewDesignComponents/PageLayout';
import { Button } from 'components/ui/button';
import { OpCodeBuilderContext } from './OpCodeBuilderHAndC/OpCodeBuilderContext';
import OpCodeBuilderCreate from './OpCodeBuilderCreate';
import OpCodeBuilderCard from './OpCodeBuilderCard';
import SearchItems from 'components/NewDesignComponents/SearchItems';
import OpCodeBuilderUpdate from './OpCodeBuilderUpdate';

const OpCodeBuilder = () => {
    const [show, setShow] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const { isOpcodeLoading, opCodes, selectedOpCode, actions } = useContext(OpCodeBuilderContext);
    const [filteredOpCodes, setFilteredOpCodes] = useState([...opCodes])
    const scrollAreaRef = useRef(null);

    const handleFilterOpCodes = (searchTerm) => {
        const filteredItems = searchTerm? opCodes.filter(opcode =>
            opcode?.opcode_id?.toLowerCase().includes(searchTerm.toLowerCase())
            ) : opCodes;

        setFilteredOpCodes(filteredItems)
    }

    useEffect(() => {
        if(!show){
            actions({type:"SET_GLOBAL_CONTEXT", payload:[]})
        }
    }, [show])
    
    return (
        <PageLayout ref={scrollAreaRef}>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Build your Enterprise
                    </PageTitle>
                    <PageSubTitle>
                        Workflow Blueprints
                    </PageSubTitle>
                    <PageDescription>
                        Design intelligent, scalable Opcodes with agentic AI and operational codes. Streamline execution, optimize processes, and ensure precision with customizable blueprints for enterprise success
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button onClick={() => setShow(true)} className='w-full'> Add new opcode </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent loading={isOpcodeLoading}>

                <PageContentHeader>
                    <PageContentHeaderTitle>Opcode Builder:</PageContentHeaderTitle>
                    <PageContentHeaderText>Manage your Agentic AI Operational Codes</PageContentHeaderText>
                </PageContentHeader>

                <div className="w-full mx-auto p-2 md:p-6">
                    <div className="space-y-4">
                        <SearchItems items={opCodes} handleFilterItems={handleFilterOpCodes}/>
                        {filteredOpCodes.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredOpCodes.map((opCode) => (
                                    <OpCodeBuilderCard 
                                    key={opCode?.opcode_id}  
                                    opCode={opCode} 
                                    opCodes={opCodes}
                                    onManage={() => {
                                        actions({type:"SET_SELECTED_OPCODE", payload:opCode})
                                        setUpdateModal(true)
                                    }}
                                     />
                                ))}
                            </div>
                        ) : (
                            <div className='flex flex-grow justify-center items-center'>
                                <span className="text-lg font-semibold mb-2">No opcodes Found</span>
                            </div>
                        )}
                    </div>
                </div>
            </PageContent>

            {/* create new opcode dialog */}
            <OpCodeBuilderCreate openModal={show} setOpenModal={setShow} scrollAreaRef={scrollAreaRef}/>

            {/* update collection dialog */}
            {selectedOpCode && updateModal && <OpCodeBuilderUpdate opCode={selectedOpCode} openModal={updateModal} setOpenModal={setUpdateModal} ishome={true} />}
        </PageLayout>
    )
}

export default OpCodeBuilder