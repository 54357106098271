

import React from 'react'
import { Input } from 'components/ui/input'
import { Search } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"

export function SearchBar({ onSearch }) {

    const handleSearch = (value) => {
        onSearch(value)
    }

    return (
        <div className="relative flex-grow">
            <Input
                type="text"
                placeholder="Search listings..."
                // value={search}
                onChange={(e) => handleSearch(e.target.value)}
                className="pl-10 bg-white"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
    )
    
}



const instructionTypes = [
    {id: 1, name: 'All', value: 'All'},
    {id: 2, name: 'Watson', value: 'IBM'},
    {id: 3, name: 'Groq', value: 'Groq'},
    {id: 4, name: 'SambaNova', value: 'SambaNova'}
]


export function FilterBar({ selectModelType, onSearch, types=instructionTypes, placeholder='Filter model type' }) {

    const onChange = (value) => {
        onSearch(value)
    }

    return (
        <Select onValueChange={onChange} value={selectModelType}>
            <SelectTrigger className="w-[180px] bg-white" >
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                {types.map((type) => (
                    <SelectItem key={type.id} value={type.value}>
                        {type.name}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}