import { useState } from "react"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Switch } from "components/ui/switch"
import { ScrollArea } from "components/ui/scroll-area"
import { Card, CardContent } from "components/ui/card"
import { XIcon, SearchIcon } from "lucide-react"
// import AlertModal from "components/Alertmodal"


export function ContextSelector({ sortedAndFilteredContext, contextFields, onAddContext, onRemoveContext, inputGroups, isCondition= false }) {
    const [searchTerm, setSearchTerm] = useState("")
    // const [delModal, setDelModal] = useState(false)

    // const findContextInSteps = (contextField) => {
    //     return inputGroups.reduce((acc, step, index) => {
    //         if (step.step_type === "Non-LLM") {
    //           if (step?.input?.query_text?.includes(`{{context['${contextField}']}}`) ?? false) {
    //             acc.push(index + 1)
    //           }
    //         } else if(step.step_type === "Loop") {
    //             if ((typeof step?.input?.collection === "string" && step?.input?.collection?.includes(`{{context['${contextField}']}}`)) || step?.input?.break_condition?.includes(`{{context['${contextField}']}}`)) {
    //                 acc.push(index + 1)
    //             }
    //         } else {
    //           if (typeof step.input === "string" && step.input.includes(`{{context['${contextField}']}}`)) {
    //             acc.push(index + 1)
    //           }
    //         }
    //         return acc
    //       }, [])
    // }


    return (
        <Card className="w-full">
            <CardContent className="p-4">
                <div className="flex items-center space-x-2 mb-4">
                    <SearchIcon className="text-muted-foreground" />
                    <Input
                        type="text"
                        placeholder="Search context fields..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="flex-grow"
                    />
                </div>
                <ScrollArea className="max-h-[250px]">
                    <div className="space-y-2">
                        {sortedAndFilteredContext(searchTerm)?.map((field) => (
                            <Card key={field} className="bg-accent/50 w-full">
                                <CardContent className="p-2 flex items-center justify-between w-full">
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                        id={`context-Steps-${field}`}
                                        checked={isCondition? contextFields === field : contextFields.includes(field)}
                                        onCheckedChange={(checked) => onAddContext(field, checked)}
                                        />
                                        <div className="flex items-center gap-2">
                                            <label
                                            htmlFor={`context-Steps-${field}`}
                                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                            >
                                                {field}
                                            </label>
                                            {/* <div className="flex items-center text-sm text-gray-400 gap-1 max-w-[300px] overflow-auto">
                                                (Used in
                                                {findContextInSteps(field).map(step => (
                                                    <span key={step} className="">step {step}</span>
                                                ))})
                                            </div> */}
                                        </div>
                                                
                                    </div>
                                    <Button variant="ghost" size="icon" onClick={() => onRemoveContext(field)} className="h-8 w-8 p-0">
                                        <XIcon className="h-4 w-4" />
                                        <span className="sr-only">Remove {field}</span>
                                    </Button>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </ScrollArea>
            </CardContent>

            {/* <AlertModal openModal={delModal} setopenModal={setDelModal} onDelete={handleDelete} /> */}
        </Card>
    )
}

