
export function checkRequiredFields(instructionObj, requiredFields) {
    const emptyFields = [];

    requiredFields.forEach(field => {
        if (!instructionObj[field.field] || instructionObj[field.field].trim() === '') {
            emptyFields.push(field);
        }
    });

    if (emptyFields.length > 0) {
        return {
            isValid: false,
            missingFields: emptyFields
        };
    }

    return { isValid: true };
}


export const getParameterType = (instructionForm) => {
    if (instructionForm.model_type  === 'IBM') {
        return instructionForm.watsonParameters
    } else if (instructionForm.model_type  === 'Groq') {
        return instructionForm.groqParameters
    } else if (instructionForm.model_type === 'SambaNova') {
        return instructionForm.sambaNovaParameters
    }
}
