import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog";
import { SubOrgContext } from "context/SubOrganizationContext";
import { useContext } from "react";
import { FileSelection } from "./FileList";
import { getFileSystem } from "./FileSystemHelper";
import { Context } from "context/GlobalState";
import { LoaderSpinner } from "components/LoaderSpinner";
import { Button } from "components/ui/button";

export function FileSelectionModal({ isOpen, setIsOpen, collection_id, onFileConfirm, fileSystem, setFileSystem }) {
    const { collections } = useContext(SubOrgContext);
    const { addNewNotifcation } = useContext(Context);
    const collectionDetails = collections?.find((coll) => coll._id === collection_id);
    const [selectedFile, setSelectedFile] = useState([]);
    const [isFetchingFiles, setIsFetchingFiles] = useState(false);
    const { oragID, subOragID } = useParams();

    const initializeFileSystem = async () => {
        const maxRetries = 5;
        let retryCount = 0;
        let success = false;
    
        while (retryCount < maxRetries && !success) {
            try {
                setFileSystem([]);
                console.log("Fetching files from the collection...");
                setIsFetchingFiles(true);
                const files = await getFileSystem(collections, oragID, subOragID, collection_id);
                setFileSystem(files);
                success = true; // If successful, exit the loop
                addNewNotifcation("Files fetched successfully.", "success");
            } catch (error) {
                retryCount++;
                console.error(`Attempt ${retryCount} failed:`, error);
    
                if (retryCount === maxRetries) {
                    console.error("Max retries reached. Could not retrieve the files.");
                    addNewNotifcation("Could not retrieve the files after multiple attempts.", "danger");
                } else {
                    console.log(`Retrying... (${retryCount}/${maxRetries})`);
                }
            } finally {
                setIsFetchingFiles(false);
            }
        }
    };

    const handleConfirmFile = () => {
        onFileConfirm(selectedFile);
        setIsOpen(false);
    }

    const onFileClick = (file) => {
        setSelectedFile(file);
    }

    useEffect(() => {
        if (collection_id && collection_id != '')
            initializeFileSystem();
    }, [collection_id]);

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent className="sm:max-w-6xl select-none h-fit max-h-[650px] min-h-[300px]">
                <DialogHeader>
                    <DialogTitle>Select a file from the collection: {collectionDetails?.name || 'undefined'}</DialogTitle>
                    <DialogDescription>
                        Get selected file details from the collection.
                    </DialogDescription>
                </DialogHeader>

                {isFetchingFiles ? 
                    <div className="flex flex-row mx-auto gap-2 h-fit p-2 text-gray-400">
                        <span>Fetching Files</span>
                        <LoaderSpinner className='w-6 h-6'/>
                    </div>
                : !fileSystem ? 
                    <div className="flex flex-row mx-auto gap-2 h-fit p-2 text-gray-400">
                        <span>No Files</span>
                    </div>
                : <FileSelection fileSystem={fileSystem} onFileClick={onFileClick}/>}

            <DialogFooter>
                <Button variant="outline" onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button disabled={!selectedFile} onClick={handleConfirmFile}>
                    Select
                </Button>
            </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}