import { createApiClient } from './';

const PROJECT_O_API_BASE_URL = process.env.REACT_APP_PROJECT_O_SERVER_URL
const BEX_API_ENDPOINT_PREFIX = "/projecto/api/v1";

/**
 * HTTP client for the Project O API endpoints /  services.
 *
 * @type {ApiClient}
 */
const projectOHttpClient = createApiClient(
    PROJECT_O_API_BASE_URL, 
    BEX_API_ENDPOINT_PREFIX
);
projectOHttpClient.defaults.withCredentials = false;
export { projectOHttpClient }

const BEX_WEBSOCKET_HOST = process.env.REACT_APP_PROJECT_O_WEB_SOCKET_URL
const BEX_WEBSOCKET_PREFIX = "/projecto/api/v1";
export const BEX_WEBSOCKET_URL = `${BEX_WEBSOCKET_HOST}${BEX_WEBSOCKET_PREFIX}`;

/**
 * A Dynamic WebSocket client maker with event interceptors.
 * @param {string} endpoint - The specific WebSocket endpoint to connect to (e.g., "/notifications").
 * @param {Object} [options={}] - Options to configure WebSocket events.
 * @param {Function} [options.onOpen] - Optional function to be called on WebSocket open event.
 * @param {Function} [options.onMessage] - Optional function to be called on WebSocket message event.
 * @param {Function} [options.onError] - Optional function to be called on WebSocket error event.
 * @param {Function} [options.onClose] - Optional function to be called on WebSocket close event.
 * @returns {WebSocket} The WebSocket instance for managing connections.
 */
export const createWebSocketClient = (
    endpoint,
    {
        onOpen = () => console.log("WebSocket connected"),
        onMessage = (message) => console.log("Received:", message.data),
        onError = (error) => console.error("WebSocket error:", error),
        onClose = () => console.log("WebSocket closed")
    } = {}
) => {
    // Construct WebSocket URL by combining base URL, prefix, and endpoint
    const wsURL = `${BEX_WEBSOCKET_URL}${endpoint}`;

    // Create WebSocket instance
    const wsClient = new WebSocket(wsURL);

    // Set up WebSocket event listeners with optional interceptors
    wsClient.onopen = (event) => onOpen(event, wsClient);
    wsClient.onmessage = (message) => onMessage(message);
    wsClient.onerror = (error) => onError(error);
    wsClient.onclose = (event) => onClose(event);

    /**
     * Function to send a message to the WebSocket server.
     * @param {string | ArrayBuffer | Blob | ArrayBufferView} data - The data to be sent.
     */
    wsClient.sendMessage = (data) => {
        if (wsClient.readyState === WebSocket.OPEN) {
            wsClient.send(data);
        } else {
            console.warn("WebSocket is not open. Unable to send message.");
        }
    };

    return wsClient;
};
