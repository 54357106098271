import { knowledgeBuilderHttpClient, createWebSocketClient } from "api/clients/knowledgeBuilder.httpClient";


class UnifiedModelWebsocketService {

    
    /**
     * Runs an experiment using the model.
     * @param {Object} data - The experiment data to run.
     * @param {String} model_type - The model type to use from ["IBM", "Groq"]
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async experiment(data, model_type) {
        // Step 1: Get Task ID
        const response = await knowledgeBuilderHttpClient.post(`/models/model/experiment?model_type=${model_type}`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/models/ws/experiment/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                const defaultResult = { "websocket_error": "Websocket did not receive a result." };
            
                switch (message.state) {
                    case "PENDING":
                        console.log("Task registered...");
                        break;
                    case "RECEIVED":
                        console.log("Task received by a worker...");
                        break;
                    case "STARTED":
                        console.log("Task execution started...");
                        break;
                    case "SUCCESS":
                        console.log("Task is completed");
                        const result = message?.result || defaultResult;
                        if (!message?.result) {
                            console.log("Websocket did not receive a result.");
                        }
                        resolve(result); // Resolve the promise with the final result
                        socket.close(); // Close the socket when the task is done
                        break;
                    case "FAILURE":
                        const errorResult = typeof message?.result === 'object' ? JSON.stringify(message?.result) : message?.result || "Undefined";
                        console.error(`Error: ${errorResult}`);
                        reject(errorResult); // Reject the promise on error
                        socket.close();
                        break;
                    default:
                        console.warn(`Unknown message state: ${message.state}`);
                        break;
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }
    
    /**
     * Predict using a created model (Instruction).
     * @param {Object} data - The experiment data to run.
     * @param {String} model_type - The model type to use from ["IBM", "Groq"]
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async predict(data, model_type) {
        // Step 1: Get Task ID
        const response = await knowledgeBuilderHttpClient.post(`/models/model/predict?model_type=${model_type}`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/models/celery/ws/predict/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

}

const unifiedModelWSService = new UnifiedModelWebsocketService();
export { unifiedModelWSService };