import { useState } from "react";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";

export function QueryForm({ onSubmit, onClear, isQuerying }) {
    const [query, setQuery] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(query);
    };

    return (
        <form className="flex flex-row items-center space-x-2" onSubmit={handleSubmit}>
            <div className="grid flex-1 gap-2">
                <Input value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter your query here." />
            </div>
            <Button type="submit" size="sm" className="px-3" disabled={isQuerying || !query}>
                {isQuerying ? "Querying..." : "Send Query"}
            </Button>
            <Button variant="secondary" type="reset" size="sm" className="px-3" onClick={() => { setQuery(""); onClear(); }}>
                Clear
            </Button>
        </form>
    );
}