import { useEffect, useState } from 'react'
import { ActivityFilters } from './ActivityFilters'
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { activityLogService } from 'api'
import { Badge } from 'components/ui/badge'
import { formatDateToDDMMYYY } from 'utils/timeUtils'
import NewStyledTable from 'components/ui/StyledTable'

export default function UserActivityPage() {


    const [filteredActivities, setFilteredActivities] = useState([])
    const [userActivities, setUserActivities] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const handleFilterChange = (filters) => {
        const filtered = userActivities.filter(activity => {
            const activityDate = new Date(activity.timestamp)
            const startDate = filters.startDate ? new Date(filters.startDate) : null
            const endDate = filters.endDate ? new Date(filters.endDate) : null

            const statusCode = activity.responseStatus;
            const getStatusCode = (status) => {
                if (status === '200') {
                    return statusCode >= 200 && statusCode < 300;
                } else if (status === '400') {
                    return statusCode >= 400 && statusCode < 500;
                } else if (status === '500') {
                    return statusCode >= 500;
                }
            }

            return (
                (!filters.userEmail || activity.userId.email.includes(filters.userEmail)) &&
                (!startDate || activityDate >= startDate) &&
                (!endDate || activityDate <= endDate) && (filters.status === 'all' ? true : getStatusCode(filters.status))
            )
        })
        setFilteredActivities(filtered)
    }

    useEffect(() => {
        activityLogService.getActivityLog()
        .then((res) => {
            setFilteredActivities(res.data)
            setUserActivities(res.data)
        }).catch((error) => {
            console.log('error: ', error);
            setError(error.message)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const columns = [
        { headerName: 'User Email', renderCell: (row) => row.userId.email },
        { headerName: 'API Endpoint', field: 'apiEndpoint' },
        { headerName: 'Method', field: 'method' },
        { headerName: 'IP Address', field: 'ipAddress' },
        { headerName: 'Timestamp', renderCell: (row) => formatDateToDDMMYYY(row.timestamp) },
        { headerName: 'Response Status', field: 'responseStatus' },
        { headerName: 'Success', renderCell: (row) => (
            <Badge className={`${row.success ? 'bg-green-500' : 'bg-red-500'} text-white py-1 px-2 text-sm`}>
                {row.success ? 'Success' : 'Failed'}
            </Badge>
        ) },
    ]

    return (
        <NestedPageLayout loading={loading} error={error}>
            <Card>
                <CardHeader>
                    <CardTitle> User Activity Details </CardTitle>
                </CardHeader>
                <CardContent>
                    <ActivityFilters onFilterChange={handleFilterChange} />
                    <NewStyledTable cardTitle={`Activity log`} columns={columns} data={filteredActivities} multiSelect={false}/>
                </CardContent>
            </Card>
        </NestedPageLayout>
    )
}

