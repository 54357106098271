import React from 'react'
import ActivityLogViewer from './ActivityLogViewer'

export default function AssistantLogs() {
    return (
        <div className='flex flex-grow flex-col'>
            <ActivityLogViewer />
        </div>
    )
}
