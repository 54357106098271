import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'components/ui/button';
import { ZoomIn, ZoomOut, RotateCw, Undo2 } from 'lucide-react';

const ImageViewer = ({ document, page }) => {
    const [scale, setScale] = useState(1); // Zoom scale
    const [rotation, setRotation] = useState(0); // Rotation angle
    const [position, setPosition] = useState({ x: 0, y: 0 }); // Pan position
    const imageRef = useRef(null);

    // Handle zoom in
    const handleZoomIn = () => {
        setScale((prevScale) => prevScale + 0.1);
    };

    // Handle zoom out
    const handleZoomOut = () => {
        setScale((prevScale) => (prevScale > 0.1 ? prevScale - 0.1 : 0.1));
    };

    // Handle rotation
    const handleRotate = () => {
        setRotation((prevRotation) => (prevRotation + 90) % 360);
    };

    // Handle panning (mouse drag)
    const handleMouseDown = (e) => {
        e.preventDefault();
        const startX = e.clientX - position.x;
        const startY = e.clientY - position.y;

        const handleMouseMove = (e) => {
            setPosition({
                x: e.clientX - startX,
                y: e.clientY - startY,
            });
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    // Reset zoom, rotation, and pan
    const handleReset = () => {
        setScale(1);
        setRotation(0);
        setPosition({ x: 0, y: 0 });
    };

    useEffect(() => {
        handleReset();
    }, [document])

    return (
        <div className="h-full w-full flex flex-col items-center justify-center bg-white p-4">
            <div className="relative overflow-hidden border border-gray-300">
                <img
                    ref={imageRef}
                    src={document?.presigned_url || ''}
                    alt="Image Viewer"
                    className="max-w-full max-h-full"
                    style={{
                        transform: `scale(${scale}) rotate(${rotation}deg)`,
                        cursor: 'grab',
                        transformOrigin: 'center center',
                        position: 'relative',
                        left: position.x,
                        top: position.y,
                    }}
                    onMouseDown={handleMouseDown}
                    onError={() => console.log('Error loading image')}
                />
            </div>
            <div className="mt-4 flex space-x-4">
                <Button
                    onClick={handleZoomIn}
                    size="icon"
                    variant="outline"
                >
                    <ZoomIn className='w-5 h-5'/>
                </Button>
                <Button
                    onClick={handleZoomOut}
                    size="icon"
                    variant="outline"
                >
                    <ZoomOut className='w-5 h-5'/>
                </Button>
                <Button
                    onClick={handleRotate}
                    size="icon"
                    variant="outline"
                >
                    <RotateCw className='w-5 h-5'/>
                </Button>
                <Button
                    onClick={handleReset}
                    size="icon"
                    variant="destructive"
                >
                    <Undo2 className='w-5 h-5'/>
                </Button>
            </div>
        </div>
    );
};

export default ImageViewer;