import React, { useContext, useRef, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/ui/dialog"
import { Context } from "context/GlobalState"
import { channelService } from "api"
import { NewChannelInput } from './WebChatSetup'
import { ScrollArea } from 'components/ui/scroll-area'
import AppearanceAndLauncher from './WebchatConfig/AppearanceAndLauncher'
import HomeScreen from './WebchatConfig/HomeScreen'


export default function UpdateWebChannelWrapper({ isOpen, setOpenEmailModal, channel, setEmailChannel, type }) {
    
    const [loading, setLoading] = useState(false)
    const [emailUpdateObject, setEmailUpdateObject] = useState(channel)

    const { addNewNotifcation } = useContext(Context)
    const modalRef = useRef(null)

    const handleSave = async (webChatConfig, closeModal) => {
        const channelObj1 = {
            organizationId: emailUpdateObject.organizationId,
            subOrganizationId: emailUpdateObject.subOrganizationId,
            channelId: emailUpdateObject._id,
            topic: emailUpdateObject.topic,
            address: emailUpdateObject.address,
            origins: emailUpdateObject.origins,
            assistantId: emailUpdateObject?.assistantId === 'none' ? '' : emailUpdateObject?.assistantId,
            webChatConfig,
        }

        try {
            setLoading(true)
            const res = await channelService.updateChannel(channelObj1)
            console.log('res: ', res);
            setEmailChannel({...emailUpdateObject, ...channelObj1})
            setEmailUpdateObject({...emailUpdateObject, ...channelObj1})
            addNewNotifcation('Channel Updated successfully', 'success')
            closeModal? setOpenEmailModal(false) : ""
        } catch(error) {
            console.log('error: ', error);
            if (error.response?.status === 404) {  
                addNewNotifcation('Channel not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        } finally {
            setLoading(false)
        }
    }

    const getUpdateType = () => {

        switch (type) {
            case 'appearance-launcher':
                return <AppearanceAndLauncher handleSave={handleSave} loading={loading} emailUpdateObject={emailUpdateObject}/>
            case 'home-screen':
                return <HomeScreen handleSave={handleSave} loading={loading} emailUpdateObject={emailUpdateObject} modalRef={modalRef}/>
        }

    }

    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="p-0 sm:max-w-[1200px] h-[90vh]" ref={modalRef}>
                <ScrollArea className='p-4 relative h-full'>
                    {getUpdateType()}
                </ScrollArea>
            </DialogContent>
        </Dialog>

    )
}

export const WebChannelUpdateDialog = ({ openEmailModal, setOpenEmailModal, emailUpdateObject, setEmailChannels, setEmailUpdateObject }) => {

    const { addNewNotifcation } = useContext(Context)

    const handleOnSubmit = async (channelObj) => {

        const channelObj1 = {
            organizationId: channelObj.organizationId,
            subOrganizationId: channelObj.subOrganizationId,
            channelId: channelObj._id,
            welcomeMessage: '',
            homePageMeaage: '',
            topic: channelObj.topic,
            address: channelObj.address,
            origins: channelObj.origins,
            assistantId: channelObj?.assistantId === 'none' ? '' : channelObj?.assistantId,
            includeConvoHistory:channelObj.includeConvoHistory
        }

        try {
            const res = await channelService.updateChannel(channelObj1)
            console.log('res: ', res);
            setEmailChannels(prev => {
                return prev.map((item) => {
                    if (item._id === emailUpdateObject._id){
                        return {...channelObj}
                    } else {
                        return {...item}
                    }
                })
            })
            setEmailUpdateObject({...emailUpdateObject, ...channelObj})
            addNewNotifcation('Channel Updated successfully', 'success')
            setOpenEmailModal(false)
        } catch(error) {
            console.log('error: ', error);
            if (error.response?.status === 404) {  
                addNewNotifcation('Channel not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }
    }

    if (!emailUpdateObject) return 

    return (
        <Dialog open={openEmailModal} onOpenChange={setOpenEmailModal}>
            <DialogContent className="p-0 sm:max-w-[1200px] h-[90vh]">

                <ScrollArea className='p-4 relative h-full'>

                    <DialogHeader>
                        <DialogTitle> Update web channel </DialogTitle>
                    </DialogHeader>
                            
                    <NewChannelInput
                    newChannelObj={emailUpdateObject}
                    isUpdate={true}
                    channelType={'web-chat'} setOpenEmailModal={setOpenEmailModal} 
                    onSubmit={handleOnSubmit} />

                </ScrollArea>

            </DialogContent>
        </Dialog>
    )
}
