import React, { useContext, useState } from 'react'
import Breadcrumb from 'components/Breadcrumb';
import InstructionForm from './InstructionForm';
import { Button } from 'components/ui/button';
import CommonInstructionSelectionModal from '../Common/CommonInstructionSelectionModal';
import { RotateCcw } from 'lucide-react';
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';


const LivePrompt = () => {
    const [openSelection, setOpenSelection] = useState(false);
    const [loadedInstruction, setLoadedInstruction] = useState(null);
    const { state, dispatch } = useContext(AiLabContext);

    const form = state?.promptLab?.form;
    
    const setForm = (field, value) => {
        dispatch({
            type: 'UPDATE_FIELD',
            field,
            value,
        });
    };
    
    const handleClearForm = () => {
        dispatch({type:"RESET_FORM"});
        setLoadedInstruction(null);
    };

    return (
        
        <div className="flex flex-col w-full h-full p-8 overflow-y-scroll">
            <div className='my-3 px-2'>
                <Breadcrumb maxPaths={2} />
            </div>
            <div className="flex flex-row justify-between items-center my-4">
                <h1 className="text-xl font-bold">Prompt Lab</h1>
                <div className='flex flex-row items-center justify-center gap-2'>
                    <Button variant="outline" size="icon" onClick={handleClearForm}>
                        <RotateCcw className='w-5 h-5'/>
                    </Button>
                    <Button onClick={() => {setOpenSelection(true)}}>
                        Load Instruction
                    </Button>
                </div>
            </div>

            {/* Instruction Form */}
            <InstructionForm form={form} setForm={setForm} loadedInstruction={loadedInstruction} setLoadedInstruction={setLoadedInstruction} />

            {/* Instruction Selection Modal */}
            {openSelection && <CommonInstructionSelectionModal setForm={setForm} openModal={openSelection} setOpenModal={setOpenSelection} onLoadInstruction={setLoadedInstruction}/>}
        </div>
    );
};
export default LivePrompt;

