import { bexHttpClient } from 'api/clients';

/**
 * Service class for performing File Manager operations.
 */
class SecretKeysService {

    /**
     * list organization milvus secrets keys
     * @returns {Promise<any[]>}
     */
    async listMilvusSecretKeys(organizationId) {
        const response = await bexHttpClient.get(`admin/get-organization-milvus-secrets/${organizationId}`)
        return response;
    }

    /**
     * list organization elasticsearch secrets keys
     * @returns {Promise<any[]>}
     */
    async listElasticsearchSecretKeys(organizationId) {
        const response = await bexHttpClient.get(`admin/get-organization-elasticsearch-secrets/${organizationId}`)
        return response;
    }

    /**
     * list organization redis secrets keys
     * @returns {Promise<any[]>}
     */
    async listRedisSecretKeys(organizationId) {
        const response = await bexHttpClient.get(`admin/get-organization-redis-secrets/${organizationId}`)
        return response;
    }

    /**
     * list organization milvus secrets keys
     * @returns {Promise<any[]>}
     */
    async updateMilvusSecretKeys(data) {
        const response = await bexHttpClient.put(`admin/update-organization-milvus-secrets`, data)
        return response;
    }

    /**
     * list organization elasticsearch secrets keys
     * @returns {Promise<any[]>}
     */
    async updateElasticsearchSecretKeys(data) {
        const response = await bexHttpClient.put(`admin/update-organization-elasticsearch-secrets`, data)
        return response;
    }

    /**
     * list organization secrets keys
     * @returns {Promise<any[]>}
     */
    async updateRedisSecretKeys(data) {
        const response = await bexHttpClient.put(`admin/update-organization-redis-secrets`, data)
        return response;
    }

    /**
     * check elasticsearch deployment
     * @returns {Promise<any[]>}
     */
    async checkElasticsearchDeployment(organizationId) {
        const response = await bexHttpClient.get(`admin/check-elasticsearch-deployment/${organizationId}`)
        return response;
    }

}

const secretKeysService = new SecretKeysService()
export { secretKeysService }
