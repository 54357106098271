import React, { useContext, useState } from 'react'
import { Context } from '../../../context/GlobalState'
import ResetUserPasswordForm from './ResetUserPasswordForm'
import './profile-styles.css'
import { httpRequest } from '../../../utils/httpsRequest'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { Button } from 'components/ui/button'


export default function Profile() {

    const [openPassModal, setOpenPassModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const { userAdmin, addNewNotifcation } = useContext(Context);
    const [userPassInput, setUserPassInput] = useState({ oldPass: '', newPass: '', confirmNewPass: '' });
    
    const handleResetPass = () => {
        if (userPassInput.newPass !== userPassInput.confirmNewPass) {
            addNewNotifcation('Password could not be updated. Password does not match. Please check all your entries.', 'warning');
        }
        else {
            const passObj = {
                adminId: userAdmin.id,
                oldPassword: userPassInput.oldPass,
                newPassword: userPassInput.newPass
            }
            console.log('passObj', passObj);
            
            setBtnLoading(true);

            httpRequest.put('/admin/update-admin-password', passObj)
            .then((res) => {
                console.log('Response', res);
                addNewNotifcation('Password has been updated successfully.', 'success');
                setUserPassInput({ oldPass: '', newPass: '', confirmNewPass: '' })
                setOpenPassModal(false);
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 404)
                    addNewNotifcation('Password could not be updated. User is not registered. Please check all your entries.', 'warning')
                else if (error.response?.status === 400)
                    addNewNotifcation('Password could not be updated. Old password is not correct. Please enter the correct password.', 'danger')
                else
                    addNewNotifcation('Password could not be updated. Please check all your entries.', 'danger')
            })
            .finally(() => setBtnLoading(false));
        }

    };
    

    return (
        <div className='profile-settings-container'>
            <div className='profile-container'>
                <div >
                    <h3 className='profile-label'>Name</h3>
                    <h3>{userAdmin.name}</h3>
                </div>
            </div>
            <hr className='hr-line' />
            <div className='profile-container'>
                <div >
                    <h3 className='profile-label'>Email</h3>
                    <h3>{userAdmin.email}</h3>
                </div>
            </div>
            <hr className='hr-line' />
            <div className='profile-container'>
                <div >
                    <h3 className='profile-label'>Update Password</h3>
                </div>
                <div>
                    <button className='setting-edit-btn'  onClick={() => setOpenPassModal(true)}>Edit</button>
                    <Dialog onOpenChange={setOpenPassModal} open={openPassModal} text={'Edit User Name'}>
                        <DialogContent>

                            <DialogHeader>
                                <DialogTitle> Reset Password </DialogTitle>
                            </DialogHeader>

                            <div>
                                <ResetUserPasswordForm userPassInput={userPassInput} setUserPassInput={setUserPassInput} />
                            </div>

                            <DialogFooter>
                                <Button onClick={() => setOpenPassModal(false)}> Cancel </Button>
                                <Button disabled={btnLoading} onClick={handleResetPass}> Update </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}