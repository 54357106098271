import { instructionService } from 'api/services/BEX/instruction.service'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Context } from 'context/GlobalState'
import { Button } from 'components/ui/button'
import InstructionForm from './InstructionForm'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper'
import { checkRequiredFields, getParameterType } from './helper'


const UpdateInstructionDialog = ({ openModal, setOpenModal, selectedCard, copy, onSuccess }) => {

    const initialData = {

        unique_name: '',
        model_type: 'IBM',
        modelDescription:'',
        selectedModel: '',

        instruction: '',
        context: '',
        targetFormat: '',
        sampleInput: '',
        sampleOutput: '',
        inputData: '',

        creation_timestamp: '',

        groqParameters: {
            max_tokens: 200,
            n: 1,
            top_p: 1,
            stop: ["<|endoftext|>", "<|endofcode|>"],
            temperature: 0,
            frequency_penalty: 1.2,
            presence_penalty: 0,
            response_format: {"type": "text"},
        },

        watsonParameters: {
            decoding_method: "greedy",
            temperature: 0.7,
            min_new_tokens: 10,
            max_new_tokens: 200,
            top_p: 1, top_k: 50,
            repetition_penalty: 1.0,
            stop_sequence: ["<|endoftext|>", "<|endofcode|>"],
        },

        sambaNovaParameters: {
            max_tokens: 200,
            top_p: 1,
            top_k: 50,
            stop: ["<|endoftext|>", "<|endofcode|>"],
            temperature: 0,
        }
    }

    const [instructionForm, setInstructionForm] = useState(initialData);
    const [btnLoading, setBtnLoading] = useState(false)
    
    const { oragID, subOragID  } = useParams()
    const { addNewNotifcation, user } = useContext(Context)

    useEffect(() => {
        if (selectedCard) {
            const extractedSegment = labHelper.extractValues(selectedCard.prompt);

            const updatedData = {
                ...initialData,
                ...selectedCard,
                unique_name: copy ? '' : selectedCard.unique_name, // if copy model dont set the name
                selectedModel: selectedCard.modelId
            }
            console.log('updatedData: ',updatedData);
            
            if (!extractedSegment['Instruction:']) {
                updatedData['instruction'] = selectedCard.prompt || ''
            } else {
                updatedData['instruction'] = extractedSegment['Instruction:'] || ''
                updatedData['context'] = extractedSegment['Context:'] || ''
                updatedData['targetFormat'] = extractedSegment['Target Format:'] || ''
                updatedData['sampleInput'] = extractedSegment['Sample Input:'] || ''
                updatedData['sampleOutput'] = extractedSegment['Sample Output:'] || ''
                updatedData['inputData'] = ''
            }


            if (selectedCard?.model_type === 'IBM') {
                updatedData['watsonParameters'] = selectedCard?.parameters
            } else if (selectedCard?.model_type === 'Groq') {
                updatedData['groqParameters'] = selectedCard?.parameters
            } else if (selectedCard?.model_type === 'SambaNova') {
                updatedData['sambaNovaParameters'] = selectedCard?.parameters
            }
            console.log('updatedData: ', updatedData);
            
            setInstructionForm(updatedData)
        }
    }, [selectedCard, copy])

    const handleUpdateInstruction = async () => {
        const requiredFields = [
            {field: 'model_type', name: 'model type'},
            {field: 'unique_name', name: 'unique name'},
            {field: 'modelId', name: 'model'},
        ]

        const instructionObj = {
            "model_type": selectedCard.model_type, // send the original type
            "unique_name": instructionForm.unique_name,
            "modelId": instructionForm.selectedModel,
            "prompt": labHelper.ConstructPromptUnified({
                "instruction": instructionForm.instruction.trim(),
                "context": instructionForm.context.trim(),
                "target_format": instructionForm.targetFormat.trim(),
                "sample_input": instructionForm.sampleInput.trim(),
                "sample_output": instructionForm.sampleOutput.trim(),
                // "input_data": inputData,
            }),
            "parameters": getParameterType(instructionForm),
            "org_id": oragID,
            "sub_org_id": subOragID,
            "modelDescription": instructionForm.modelDescription,
            "user_id": user._id,
            "placeholders": {},
            "metadata": {}
        }

        if (!copy) {
            instructionObj['instructionId'] = selectedCard._id // add instructionId field if update
            // check if model type changed or not if changed add new_model_type to payload
            if (selectedCard.model_type !== instructionForm.model_type) {
                instructionObj['new_model_type'] = instructionForm.model_type
                instructionObj['creation_timestamp'] = selectedCard.creation_timestamp // add creation_timestamp field for creating new instruction in new type
            }
        }

        const checkFields = checkRequiredFields(instructionObj, requiredFields)
        if (!checkFields.isValid) {
            return addNewNotifcation(`Please fill in all required fields: ${checkFields.missingFields.map(item => (item.name)).join(', ')}`, 'warning')
        } else if (!instructionForm.instruction || instructionForm.instruction.trim() === '') {
            return addNewNotifcation(`Please fill in all required fields: instruction`, 'warning')
        }

        setBtnLoading(true)

        try {

            let res
            if (copy) {
                res = await instructionService.createModel(instructionObj)
                addNewNotifcation(`Instruction created successfully`, 'success')
            } else {
                res = await instructionService.updateModel(instructionObj)
                addNewNotifcation(`Instruction updated successfully`, 'success')
            }
            onSuccess(res.data)
            
            setOpenModal(false)
        } catch(error) {
            console.log('error: ', error);
            if (error.response?.status === 409) {
                addNewNotifcation(`Instruction with ${instructionForm.unique_name} already exists`, 'danger')
            } else {
                addNewNotifcation(`Something went wrong. Please try again`, 'danger')
            }
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="max-w-[850px] w-full">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Manage Instruction</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Manage instruction data.</DialogDescription>
                </DialogHeader>

                <InstructionForm form={instructionForm} setForm={setInstructionForm} update={!copy} />

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                    <Button disabled={btnLoading} onClick={handleUpdateInstruction} className="px-5 py-3 text-md">
                        {btnLoading ? copy ? 'Saving instruction...' : 'Updating instruction...' : copy ? 'Save instruction' : 'Update instruction'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateInstructionDialog