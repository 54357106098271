import { Context } from 'context/GlobalState'
import { OrganizationContext } from 'context/OrganizationContext'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getOrganizationMember } from 'utils/organizationUtils'
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { formatDateToDDMMYYY } from 'utils/timeUtils'
import { Cog, MoreVertical, Trash2 } from 'lucide-react'
import { Button } from 'components/ui/button'
import { OpCodeBuilderContext } from './OpCodeBuilderHAndC/OpCodeBuilderContext'
import AlertModal from 'components/Alertmodal'
import { opcodeService } from 'api/services/BEX/opcode.service'

const OpCodeBuilderCard = ({opCode, opCodes, onManage}) => {
    const [delModal, setDelModal] = useState(false)
    const [delBtn, setDelBtn] = useState(false)
    const [createdByName, setCreatedByName] = useState("")
    const [UpdatedByName, setUpdatedByName] = useState("")
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    const { actions, selectedOpCode, orgId, subOrgId } = useContext(OpCodeBuilderContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (opCode?.createdBy) {
            handleGetOrganizationMember(opCode.createdBy, setCreatedByName);
        } else {
            setCreatedByName(""); 
        }
    
        if (opCode?.updatedBy) {
            handleGetOrganizationMember(opCode.updatedBy, setUpdatedByName);
        } else {
            setUpdatedByName(""); 
        }
    }, [opCode])

    const handleGetOrganizationMember = async (memberId, setMemberName) => {
        const member = await getOrganizationMember(memberId, selectedOrganization)
        setMemberName(member)
    }
    
    const handleOpCodeSelectionForExecute = () => {
        navigate(`${opCode?.opcode_id}/opCode`)
    }

    const handleDeleteOpCode = async () => {
        try {
            setDelBtn(true)
            const body = {
                org_id: orgId,
                sub_org_id: subOrgId,
                opcode_ids: [opCode?.opcode_id],
            };
            const res = await opcodeService.deleteOpCodes(body);
            console.log(res)

            if (res.data.message === "opcodes deleted successfully") {
                addNewNotifcation(`Successfully deleted opcode: ${opCode?.opcode_id}`, "success");

                // Remove the deleted opCode from the list and update opCodes state
                const updatedOpCodes = opCodes.filter((w) => w?.opcode_id !== opCode?.opcode_id);
                actions({type:"SET_OP_CODES", payload:updatedOpCodes});

                if(opCode === selectedOpCode){
                    actions({type:"SET_SELECTED_OPCODE", payload:""})
                }

                setDelModal(false)
            }
        } catch (error) {
            console.error("Error deleting opCode:", error);
            addNewNotifcation("Error deleting opcode. Please try again.", "danger");
        } finally {
            setDelBtn(false)
        }
    };

    return (
        <Card>
            <CardHeader>
                <div className='flex w-full justify-between items-center'>
                    <CardTitle onClick={handleOpCodeSelectionForExecute} className='cursor-pointer hover:underline truncate'>{opCode?.opcode_id}</CardTitle>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <MoreVertical className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={onManage}>
                                <Cog className="mr-2 h-4 w-4" />
                                <span>Manage</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => setDelModal(true)} className="text-red-600">
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Delete</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent>
                <div className="flex gap-3 items-center">
                    <span className='rounded-full size-16 flex justify-center items-center bg-[#b1092a]'>
                        <Cog size={28} color='white'/>
                    </span>
                    <ul className="flex flex-col">
                        {/* <li>Purpose: </li> */}
                        <li className='text-sm text-muted-foreground'>
                            Created on: <span>{opCode?.created? formatDateToDDMMYYY(opCode?.created) : '-'}</span> 
                        </li>
                        <li className='text-sm text-muted-foreground'>
                            Created by: <span>{createdByName || '-'}</span> 
                        </li>
                        <li className='text-sm text-muted-foreground'>
                            Updated on: <span>{opCode?.updated && opCode?.updated !== '-'? formatDateToDDMMYYY(opCode?.updated) : '-'}</span> 
                        </li>
                        <li className='text-sm text-muted-foreground'>
                            Updated by: <span>{UpdatedByName || '-'}</span> 
                        </li>
                    </ul>
                </div>
            </CardContent>
            
            {/* delete opcode modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDeleteOpCode} />
        </Card>
    )
}

export default OpCodeBuilderCard