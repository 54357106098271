import React, { useState, useEffect, useContext } from 'react';
import { Button } from "components/ui/button";
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, DialogClose, } from "components/ui/dialog";
import { Bot, Clock, Settings2, User, Pencil } from 'lucide-react';
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import { Context } from 'context/GlobalState';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { Label } from "components/ui/label"
import { OpCodeBuilderContext } from '../../OpCodeBuilderHAndC/OpCodeBuilderContext';
import UpdateInstructionDialog from 'pages/PromptingAndRagTraining/components/Instructions/UpdateInstruction';
import { Badge } from 'components/ui/badge';
import { FilterBar, SearchBar } from 'pages/PromptingAndRagTraining/components/Instructions/Filters';
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';

export default function InstructionSelectionModal({  group, index, setInputGroups }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const [instructionDetails, setInstructionDetails] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [instructionUpdate, setInstructionUpdate] = useState(false);
    const [selectType, setSelectType] = useState("All");
    const { addNewNotifcation } = useContext(Context);
    const { instructions, IsInstructionLoading, fetchInstructions } = useContext(OpCodeBuilderContext)

    useEffect(() => {
        if (isOpen) {
            initializeSelection();
            setSearchTerm("")
        }
    }, [isOpen]);

    const initializeSelection = () => {
        if (group.unique_name) {
            setSelectedInstruction(group.unique_name);

            const instructionDetails = instructions.find(instruction => instruction.unique_name === group.unique_name)

            setInstructionDetails(instructionDetails || null)
        } else {
            setSelectedInstruction(null);
            setInstructionDetails(null);
        }
    };

    const handleInstructionSelect = (instruction) => {
        if (selectedInstruction === instruction.unique_name) {
            setSelectedInstruction(null);
            setInstructionDetails(null);
        } else {
            setSelectedInstruction(instruction.unique_name);
            setInstructionDetails(instruction);
        }
    };

    const handleSelectInstruction = () => {
        if (!selectedInstruction) {
            addNewNotifcation("Please select an instruction first.", "warning");
            return;
        }

        setInputGroups((prev) =>
            prev.map((group, i) => (i === index ? { ...group, unique_name: selectedInstruction, model_type:instructionDetails.model_type } : group))
        );

        // addNewNotifcation("Instruction selected.", "success");
        setIsOpen(false);
    };

    const handleFilterModels = () => {
        return instructions.filter(instruction =>
            (instruction.unique_name.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (selectType === 'All' || instruction.model_type === selectType) // search within selected type if not All
            );
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild className="w-full">
                <Button variant="outline" className="text-left overflow-hidden">
                    {group?.unique_name || `Select an Instruction`}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>Select an Instruction</DialogTitle>
                    <DialogDescription>
                        Choose an instruction and view its details.
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex items-center">
                        <div className="flex flex-col w-full sm:flex-row gap-4 items-center p-4">
                            <div className="flex-grow">
                                <SearchBar onSearch={(value) => setSearchTerm(value)} />
                            </div>
                            <FilterBar
                            onSearch={(value) =>  setSelectType(value)}
                            selectModelType={selectType}
                            />
                        </div>
                        <Button onClick={fetchInstructions} className="flex gap-2" type="button" disabled={IsInstructionLoading}>
                            {IsInstructionLoading ? "Loading Instructions..." : "Reload Instructions"}
                        </Button>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4">
                        {handleFilterModels().length === 0 && (
                            <div className="flex items-center justify-center w-full text-gray-500">
                                <span>No instruction(s) saved.</span>
                            </div>
                        )}
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 p-4">
                                {handleFilterModels().length > 0 && (
                                    handleFilterModels().map((instruction) => (
                                        <Button
                                            key={instruction?._id || instruction.unique_name}
                                            variant={selectedInstruction === instruction.unique_name ? "default" : "outline"}
                                            onClick={() => handleInstructionSelect(instruction)}
                                            className="justify-start h-auto text-left"
                                        >
                                            <div className="w-full py-2">
                                                <div className='flex w-full justify-between gap-2 items-center'>
                                                    <div className="font-semibold w-full truncate">{instruction.unique_name}</div>
                                                    <Badge className="px-1 rounded text-green-50">
                                                        {instruction?.model_type}
                                                    </Badge>
                                                </div>
                                            </div>
                                        </Button>
                                    )))}
                            </div>
                        </ScrollArea>
                        {instructionDetails && (
                            <ScrollArea className="max-h-[400px] min-w-[480px] max-w-[480px] pr-4">
                                <Card className="w-full my-2">
                                    <CardHeader>
                                        <div className="flex items-start justify-between">
                                            <div className="space-y-1">
                                                <CardTitle className="text-xl whitespace-pre-wrap break-all">{instructionDetails.unique_name}</CardTitle>
                                                <CardDescription className="text-xs">Instruction</CardDescription>
                                            </div>

                                            <Button onClick={() =>  setInstructionUpdate(true)} variant='outline' size='icon'>
                                                <Pencil size={18} />
                                            </Button>

                                            {/* update instruction dialog */}
                                            <UpdateInstructionDialog
                                            copy={false} selectedCard={instructionDetails}
                                            openModal={instructionUpdate} setOpenModal={setInstructionUpdate}
                                            onSuccess={(updatedInstruction) => setInstructionDetails(updatedInstruction)} />

                                        </div>
                                    </CardHeader>
                                    <CardContent className="space-y-2.5">
                                        <div className="space-y-2">
                                            <div className="flex items-center gap-2 text-xs">
                                                <User className="h-4 w-4" />
                                                <Label className="font-medium text-xs">Created By:</Label>
                                                <span className="text-muted-foreground">{instructionDetails.user_id || "-"}</span>
                                            </div>
                                            <div className="flex items-center gap-2 text-xs">
                                                <Clock className="h-4 w-4" />
                                                <Label className="font-medium text-xs">Modified By:</Label>
                                                <span className="text-muted-foreground">{instructionDetails.modified_by || "-"}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                            <div className="">
                                                <Label className="text-xs font-medium">Description</Label>
                                                <p className="text-xs text-muted-foreground">{instructionDetails.modelDescription || "-"}</p>
                                            </div>
                                            <div className="space-y">
                                                <Label className="text-xs font-medium">Prompt</Label>
                                                <p className="text-xs text-muted-foreground whitespace-pre-wrap break-all">{instructionDetails.prompt || "-"}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                            <div className="flex items-center gap-2">
                                                <Bot className="h-4 w-4" />
                                                <Label className="text-xs font-medium">Model ID</Label>
                                            </div>
                                            <code className="rounded bg-muted px-2 py-1 text-xs whitespace-pre-wrap break-all">{instructionDetails.modelId}</code>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                            <div className="flex items-center gap-2">
                                                <Settings2 className="h-4 w-4" />
                                                <Label className="text-xs font-medium">Parameters</Label>
                                            </div>
                                            <div className="grid gap-4 sm:grid-cols-2">
                                                {Object.entries(instructionDetails.parameters)
                                                    .filter(([key]) => key !== "repetition_penalty" && key !== "stop_sequence" && key !== "n" && key !== "stop" && key !== "stream" && key !== "seed" && key !== "response_format")
                                                    .map(([key, value]) => (
                                                        <div className="" key={key}>
                                                            <Label className="text-xs font-medium">{handler.formatToTitleCase(key)}</Label>
                                                            <p className="text-sm text-muted-foreground">{value}</p>
                                                        </div>
                                                    ))
                                                }
                                                
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <ScrollBar orientation="horizontal" />
                            </ScrollArea>
                        )}
                    </div>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>

                    {selectedInstruction && 
                        <Button variant="default" onClick={handleSelectInstruction} disabled={group.unique_name === selectedInstruction}>
                            Select Instruction
                        </Button>
                    }
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
