import { PromptLabData } from "../initialData";

export const PromptLabReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      // Check if the field belongs to 'form' or another part of the state
      return { 
        ...state, 
        form: { 
          ...state.form, // Spread the existing form state
          [action.field]: action.value // Update the specific field in form
        }
      };
      
    case "RESET_FORM":
      return { 
        ...state, 
        form: PromptLabData.form // Reset form to initial state
      };
    
    case "SET_INSTRUCTIONS":
      return {
        ...state,
        [action.field]: action.value // Set the instruction field
      };

    default:
      return state;
  }
};
