/* eslint-disable max-lines */
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import { Context } from "context/GlobalState";
import { ScrollArea } from "components/ui/scroll-area";
import { opcodeWSService } from "api/services/PROJECT-O/WEBSOCKETS/OpCode.websocket.service";
import { Terminal } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Badge } from "components/ui/badge";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "pages/PromptingAndRagTraining/components/aiLab.css"; 
import remarkGfm from "remark-gfm";
import CopyButton from "components/CopyButton";
import { OpCodeBuilderContext } from "./OpCodeBuilderHAndC/OpCodeBuilderContext";
import { Input } from "components/ui/input";
import OpCodeBuilderLoopContext from "./OpCodeBuilderLoopContext";

export default function OpCodeBuilderExecute({opCode, steps}) {
    const { orgId, subOrgId} = useContext(OpCodeBuilderContext)
    const [executeInput, setExecuteInput] = useState("")
    const [executionResults, setExecutionResults] = useState([])
    const [isExecuting, setIsExecuting] = useState(false)
    const [contextFields, setContextFields] = useState([])
    const [loopList, setLoopList] = useState([])
    const [errorResult, setErrorResult] = useState("")
    const [isRealTimeData, setisRealTimeData] = useState(false)
    const { addNewNotifcation } = useContext(Context);
    const resultsRef = useRef(null);

    useEffect(() => {
        if (resultsRef.current) {
            resultsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [executionResults]);

    useEffect(() => {
        try{
            setisRealTimeData(false)
            setContextFields(extractContextKeysFromSteps(steps))
        }catch(error) {
            console.log(error)
        }
    }, [opCode])

    const getMatches = (step) => {
        let matches = []

        if (["LLM", "LLM-Stream", "Identification", "Condition"].includes(step.step_type)) {
            // Match {{context['key']}} in input strings
            matches = step?.input?.match(/{{context\['(.*?)'\]}}/g);
        } else if (step?.step_type === "Non-LLM") {
            matches = step?.input?.query_text?.match(/{{context\['(.*?)'\]}}/g);
        } 
        else if (step?.step_type === "Loop") {
            const nestedMatches = []
            // matches = step?.input?.collection?.match(/{{context\['(.*?)'\]}}/g);
            // if(matches?.length > 0){
            //     const key = matches[0]?.match(/{{context\['(.*?)'\]}}/)[1];
            //     setLoopList(prevList => {
            //         if (prevList.some(item => item.key === key)) {
            //           return prevList; // Key already exists, return the same list
            //         }
            //         return [...prevList, { key: key, list: [] }];
            //       });
            // }
            const key = step?.input?.collection

            if(key){
                 setLoopList(prevList => {
                    if (prevList.some(item => item.key === key)) {
                      return prevList; // Key already exists, return the same list
                    }
                    return [...prevList, { key: key, list: [] }];
                  });
            }

            if(step?.input?.break_condition){
                const breakConditionMatches = step?.input?.break_condition?.match(/{{context\['(.*?)'\]}}/g);
                matches = [...matches, ...breakConditionMatches]
            }

            step.input.steps.forEach((nestedStep) => {
                nestedMatches.push(...getMatches(nestedStep))
            })

            matches=[...matches, ...nestedMatches]
        }

        return matches || []
    }
    
    const extractContextKeysFromSteps = (steps) => {
        const contextKeys = new Set();
      
        steps.forEach((step) => {
            let matches;
            // if (["LLM", "LLM-Stream", "Identification", "Condition"].includes(step.step_type)) {
            //     // Match {{context['key']}} in input strings
            //     matches = step?.input?.match(/{{context\['(.*?)'\]}}/g);
            // } else if (step?.step_type === "Non-LLM") {
            //     matches = step?.input?.query_text?.match(/{{context\['(.*?)'\]}}/g);
            // } 
            // else if (step?.step_type === "Loop") {
                
            //     matches = step?.input?.collection?.match(/{{context\['(.*?)'\]}}/g);
            //     if(step?.input?.break_condition){
            //         const breakConditionMatches = step?.input?.break_condition?.match(/{{context\['(.*?)'\]}}/g);
            //         matches = [...matches, ...breakConditionMatches]
            //     }
            // }

            matches = getMatches(step)

            if (matches) {
                matches?.forEach((match) => {
                    const key = match?.match(/{{context\['(.*?)'\]}}/)[1];
                    if (key !== "real_time_data") {
                        contextKeys.add(key);
                    } else {
                        setisRealTimeData(true)
                    }
                });
            }
        });
    
        // Create an array of objects with extracted keys
        const result = Array.from(contextKeys)?.map((key) => ({ [key]: "" }));
    
        return result;
    }

    // const isLoopList = (field) => {
    //     const loopItem = loopList.find(item => item.key === field)
        
    //     return loopItem? true : false
    // }
    
    const handleContextFieldChange = (index, field, value) => {
        const newFields = [...contextFields]
        newFields[index][field] = value
        setContextFields(newFields)
    }

    const handleExecute = async () => {
        if (!opCode) {
            addNewNotifcation("Please select a opcode.", "warning");
            return;
        }

        try {
            // if (executeInput.trim() === "") {
            //     addNewNotifcation("Please give an input.", "warning");
            //     return;
            // }

            // if (contextFields.some((field) => {
            //     const key = Object.keys(field)[0];
            //     return key.trim() === "" || field[key].trim() === "";
            // })) {
            //     addNewNotifcation("Please fill all context fields.", "warning");
            //     return;
            // }

            const context = contextFields.reduce((acc, field) => {
                const key = Object.keys(field)[0]; // Get the dynamic key
                // const foundList = loopList.find(item => item.key === key);
                // const value = foundList? foundList?.list?.filter(item => item.trim() !== "") || []: field[key] || ""; // Get the dynamic value
                acc[key] = field[key] || ""; // Add to the accumulator object
                return acc;
              }, {});

            const loop = loopList.reduce((acc, item) => {
                acc[item.key] = item.list?.filter(item => item.trim() !== "") || []
                return acc
            }, {})

            const emptyKeys = Object.keys(loop).filter(key => Array.isArray(loop[key]) && loop[key].length === 0);

            if (emptyKeys.length > 0) {
                addNewNotifcation(`${emptyKeys.join(" and ")} ${emptyKeys.length === 1 ? "is" : "are"} empty. Please add at least one item to each.`);
            }

            setIsExecuting(true);
            setErrorResult("")
            setExecutionResults([]);
            const body = {
                "opcode_id": opCode?.opcode_id,
                "org_id": orgId,
                "sub_org_id": subOrgId,
                "context": {real_time_data:executeInput,...context, ...loop},
                // "memory_log_id":window.crypto.randomUUID()
            }
            console.log("execute body:", body);

            const res = await opcodeWSService.executeOpCode(body)
            console.log("Execution result", res)
            if (typeof res === "object" && res.execution_log) {
                setExecutionResults(res.execution_log);
                addNewNotifcation("Opcode executed successfully.", "success");
            } else {
                if(res?.error){
                    setErrorResult(res?.error || "Error");
                }
                throw new Error(res?.error || JSON.stringify(res))
            }

        } catch (e) {
            console.error("Error executing opcode:", e);
            if (e?.response?.data?.detail === "Unsupported OpCode type: None"){
                addNewNotifcation("This opcode does not have a type (AutoOp/ChatOp). Please update your opcode.", "warning");
            } else {
                addNewNotifcation("Error when executing opcode. Please try again.", "danger");
            }
            setExecutionResults([]);
        } finally {
            setIsExecuting(false);
        }
    };

    const getStepIndex = (value) => {
        if(value){
            return typeof value === "string"? (opCode?.steps?.findIndex(step => step.step_id === value) + 2) : "Not found"
        }

        return "Not found"
    }

    // Memoized
    const RenderedResults = useMemo(() => {
        return executionResults?.map((result, index) => (
            <Card className="w-full mx-auto mb-4 bg-slate-50">
                <CardHeader className="flex flex-row items-center gap-2 pb-2">
                    <CardTitle>Step #{index + 2}</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                    <div className="space-y-2">
                        <div className="flex items-center gap-2">
                            <Badge variant="secondary" className="h-6">{result.Action}</Badge>
                            {/* <span className="text-sm text-muted-foreground">Completed</span> */}
                        </div>
                        
                        <div className="">
                            <div className="space-y-1">
                                {Object.entries(result.Outputs).map(([key, value], index) => (
                                    <div className="">
                                        <strong className="text-gray-500">{key}:</strong>
                                        <div key={index} className="bg-white h-fit border select-text shadow rounded-[4px] text-xs">
                                            <ScrollArea className="max-h-[500px] p-2 break-words text-xs whitespace-pre-wrap">
                                                {result["Step Type"] === "Non-LLM" ?
                                                    <p className="whitespace-pre-wrap text-xs">{value.trim()}</p>
                                                    
                                                    :

                                                    <Markdown className="ai-lab-markdown" remarkPlugins={[remarkGfm]} 
                                                        components={{
                                                        code({ node, inline, className, children, ...props }) {
                                                            console.log(node)
                                                            const match = /language-(\w+)/.exec(className || '');
                                                            const code = String(children).replace(/\n$/, '');
                                                            
                                                            inline = !className && !/\n/.test(code);
                                                            if (inline) {
                                                            // Inline Code Block
                                                            return (
                                                                <code
                                                                className="bg-gray-200 text-gray-800 rounded-[4px] px-1 text-xs font-mono"
                                                                style={{ whiteSpace: 'pre-wrap' }}
                                                                >
                                                                    {children}
                                                                </code>
                                                            );
                                                            }
                                                    
                                                            if (!inline && match) {
                                                            // Multi-Line Code Block with Language
                                                            return (
                                                                <div className="relative rounded-[12px]">
                                                                    <span className="absolute top-2 left-2 bg-gray-600 text-white text-xs px-2 py-1 rounded select-none">
                                                                        {match[1]}
                                                                    </span>
                                                                    <CopyButton code={code} className="text-gray-200" />
                                                                    <SyntaxHighlighter
                                                                        style={darcula}
                                                                        language={match[1] !== "markdown" ? match[1] : ""}
                                                                        PreTag="div"
                                                                        {...props}
                                                                        customStyle={{ paddingTop: '2.5rem' }}
                                                                    >
                                                                        {code}
                                                                    </SyntaxHighlighter>
                                                                </div>
                                                            );
                                                            }
                                                
                                                        // Multi-Line Code Block Without Language
                                                        return (
                                                            <div
                                                                className="bg-[#2c2c2c] rounded-md p-2 pt-[1.5rem] text-xs text-gray-200 font-mono whitespace-pre-wrap relative"
                                                                style={{ overflowX: 'auto' }}
                                                            >
                                                                <CopyButton code={code} className="text-gray-200" />
                                                                {children}
                                                            </div>
                                                        );
                                                    },
                                                    }}>
                                                        {result["Step Type"] === "Non-LLM - BeX Insights" 
                                                            ? `\`\`\`json\n${JSON.stringify(JSON.parse(value.trim()), null, 4)}\n\`\`\`` 
                                                            : result["Step Type"] === "Condition"? typeof value === "boolean"? value === true? "True" : "False" : `Step ${getStepIndex(value)}`
                                                            :
                                                            value.trim() }
                                                        {/* {value.trim()} */}
                                                    </Markdown>
                                            }

                                            </ScrollArea>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        ));
    }, [executionResults]);

    return (
        <div className="space-y-6 ">
            <div className="space-y-4 mt-5">
                <div>
                    <Label className="text-lg font-semibold">Context Fields</Label>
                    <div className="space-y-4 mt-2">
                        {isRealTimeData && 
                        <>
                            <Label cla>Real Time Data</Label>
                            <Textarea
                            placeholder="Enter real time data here..."
                            value={executeInput}
                            className="flex-1 text-black"
                            onChange={e=> setExecuteInput(e.target.value)}
                            />
                        </>
                        }
                        {contextFields.map((field, index) => (
                            <>
                                {/* {isLoopList(Object.keys(field)[0])? (
                                    <div className="flex flex-col gap-2">
                                        <Input
                                            placeholder="Name"
                                            value={Object.keys(field)[0]}
                                            className="flex-1 min-h-[36px] text-black"
                                            readOnly
                                        />
                                        <OpCodeBuilderLoopContext field={Object.keys(field)[0]} loopList={loopList} setLoopList={setLoopList}/>
                                    </div>
                                ): ( */}
                                <div key={index} className="flex items-center space-x-2">
                                    <Input
                                    placeholder="Name"
                                    value={Object.keys(field)[0]}
                                    className="flex-1 text-black"
                                    readOnly
                                    />
                                    <Input 
                                    placeholder="value"
                                    value={field[Object.keys(field)[0]]}
                                    onChange={(e) => handleContextFieldChange(index,  Object.keys(field)[0], e.target.value)}
                                    className="flex-1"
                                    />
                                </div>
                                {/* )} */}
                            </>
                        ))}
                    </div>
                </div>
                <div className="space-y-4 ">
                    {loopList.length > 0 && <Label className="text-lg font-semibold">Loop Fields</Label>}
                    {loopList.map(item => (
                        <div className="flex flex-col gap-2">
                            <Input
                                placeholder="Name"
                                value={item.key}
                                className="flex-1 min-h-[36px] text-black"
                                readOnly
                            />
                            <OpCodeBuilderLoopContext field={item.key} loopList={loopList} setLoopList={setLoopList}/>
                        </div>
                    ))}
                </div>
                <Button onClick={handleExecute} className="w-full" disabled={isExecuting || !opCode?.opcode_id}>
                    {isExecuting ? "Executing opcode..." : "Execute opcode"}
                </Button>
            </div>
    
            <hr />
    
            <div className="flex flex-row gap-2 items-center mx-2 my-4">
                <Terminal className="w-5 h-5 text-primary" />
                <Label className="text-xl">Execution Results</Label>
            </div>
    
            <div className="">
                {RenderedResults}
                {errorResult && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Error: </strong>
                        <span className="block sm:inline">{errorResult}</span>
                    </div>
                )}
                <div ref={resultsRef} />
            </div>
        </div>
    );}
