/* eslint-disable max-lines */
import React, { useContext, useState } from 'react'
import './email-extraction-styles.css'
import SetupCard from 'components/AppSetup/SetupCard'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/GlobalState'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Button } from 'components/ui/button'
import AlertModal from 'components/Alertmodal'
import Breadcrumb from 'components/Breadcrumb'
import { assistantInstanceService, channelService } from 'api'
import { Switch } from 'components/ui/switch'
import { Label } from 'components/ui/label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import { BotOff, Check, Copy } from 'lucide-react'
import { OrganizationContext } from "context/OrganizationContext";
import OpcodeInstructionDialog from './OpcodeCard'
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout'
import withLoader from 'layout/withLoader'
import { Input } from 'components/ui/input'
// import Breadcrumb from 'components/Breadcrumb'

export default function EmailExtractionDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Your email channel where you will be receiving your emails',
        'A set of hotel policies and hotel information where the BeX AI Assistant can read and understand'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistant for handling hotel emails is designed for hotel reservations teams to handle incoming hotel guest enquiries, booking requests and modifications.',
        'This BeX AI Assistant is capable of connecting to your hotel reservations systems, email systems, and generating a personalized reply to your hotel guests.'
    ];

    const navigate = useNavigate()
    const { selectedOrganization } = useContext(OrganizationContext);

    const { oragID, subOragID } = useParams()

    const handleDemoClick = () => {
        if (selectedOrganization) {
            navigate(`/organizations/${oragID}/sub/${subOragID}/bex-assistants/email-handling-demo`)
        } else {
            navigate(`/organizations`)
        }
    }

    return (
        <div className='email-extraction-dashboard-container'>
            <div className='email-extraction-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    <Breadcrumb maxPaths={4} />

                    <div className='dashboard-app-setup-card-container flex-col'>
                        <SetupCard 
                        overviewTitle={'BeX AI Assistant for Hotel Email Handling'}
                        overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                        title='Click now to setup your BeX AI Assistant' 
                        subTitle='What do you need to get started?'
                        list={SETUP_DEMO_LIST}
                        onDemoClick={handleDemoClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export const ActiveAssistant = () => {

    const { oragID, subOragID, assistantId } = useParams()

    const fetchAssistant = async () => {
        const response = await assistantInstanceService.getSubOrgAssistantInstanceById(oragID, subOragID, assistantId)
        return response.data
    }

    // using withLoader component to dynamically render the component with loader and error display (test mode)
    const MyComponentWithLoader = withLoader(ActiveAssistantComponent, fetchAssistant);

    return (
        <NestedPageLayout>
            <div className='my-3'>
                <Breadcrumb maxPaths={2} />
            </div>

            <MyComponentWithLoader />
        </NestedPageLayout>
    )
}

const ActiveAssistantComponent = ({ data }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [switchLoading, setSwitchLoading] = useState(false)
    const [showDelModal, setShowDelModal] = useState(false)
    const [deletedChannel, setDeletedChannel] = useState(null)
    const [assistant, setAssistant] = useState(data)

    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const handleToggleActive = () => {
        setSwitchLoading(true)

        const updateObj = {
            id: assistant._id,
            organizationId: oragID,
            subOrganizationId: subOragID,
            status: assistant.status === 'active' ? 'inactive' : 'active',
        }
        assistantInstanceService.updateAssistantInstance(updateObj)
        .then((res) => {
            console.log('res: ', res);
            setAssistant(prev => ({...prev, status: updateObj.status}))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setSwitchLoading(false)
        })
    }

    const handleRemoveAssistantChannel = async () => {

        setBtnLoading(true)
        try {

            const channelsObj = {
                organizationId: oragID,
                subOrganizationId: subOragID,
                channelId: deletedChannel._id,
                assistantId: ''
            }
            await channelService.updateChannel(channelsObj)
            setDeletedChannel(null)
            setAssistant(prev => ({...prev, channels: assistant.channels.filter(channel => channel._id !== deletedChannel._id)}))
            addNewNotifcation('channel removed successfully', 'success')
            setShowDelModal(false)
        } catch (error) {
            if (error.response?.status === 409) {
                addNewNotifcation('Assistant must have at least one channel connected to it','danger')
            } else {
                addNewNotifcation('Something went wrong','danger')
            }
            console.log('error: ', error);
        } finally {
            setBtnLoading(false)
        }

    }

    const handleRemoveEmailChannel = (item) => {
        if (assistant.channels?.length === 1) return
        setDeletedChannel(item)
        setShowDelModal(true)
    }

    const [copiedField, setCopiedField] = useState(null)

    const copyToClipboard = (value, label) => {
        navigator.clipboard.writeText(value)
        setCopiedField(label)
        setTimeout(() => setCopiedField(null), 2000)
    }

    const fields = [
        {label: 'OrganizationId', value: oragID},
        {label: 'Sub-OrganizationId', value: subOragID},
        {label: 'AssistantId', value: assistant._id},
    ]

    return (
        <>
            <div className='flex flex-col md:flex-row w-full gap-3'>

                <Card className='flex-grow'>
                    <CardHeader>
                        <CardTitle>AI Assistant</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-col items-center justify-center gap-4">
                        <div className="space-y-2 flex flex-col w-full">
                            {fields.map((field) => (
                                <div key={field.label} className="mb-4 last:mb-0">
                                    <Label htmlFor={field.label} className="mb-2 block">
                                        {field.label}
                                    </Label>
                                    <div className="flex items-center">
                                        <Input
                                            id={field.label}
                                            value={field.value}
                                            readOnly
                                            className="rounded-r-none h-10"
                                        />
                                        <Button
                                            onClick={() => copyToClipboard(field.value, field.label)}
                                            variant="outline"
                                            className="rounded-l-none border-l-0"
                                        >
                                            {copiedField === field.label ? (
                                                <Check className="h-4 w-4" />
                                            ) : (
                                                <Copy className="h-4 w-4" />
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>

                <Card className='w-full md:w-[350px] ml-auto'>
                    <CardHeader>
                        <div className='flex w-full justify-between'>
                            <CardTitle>AI Assistant Status</CardTitle>
                            <Switch disabled={switchLoading} checked={assistant.status === 'active'} onCheckedChange={handleToggleActive}  />
                        </div>
                    </CardHeader>
                    <CardContent className="flex flex-col items-center justify-center gap-4">
                        <div className="space-y-2 flex flex-col items-center">
                            {assistant.status === 'active' ? (
                                <BotIcon className="w-[100px] h-[100px] text-primary" />
                            ): (
                                <BotOff className="w-[100px] h-[100px] text-primary" />
                            )}
                            <p className="text-lg font-semibold">AI Assistant is {assistant.status === 'active' ? 'Active' : 'Inactive'}</p>
                            <p className="text-muted-foreground">
                                {assistant.status === 'active' && 'The AI assistant is ready to assist you.'}
                            </p>
                        </div>
                    </CardContent>
                </Card>
            </div>

            {/* channels card */}
            <Card className='mt-3'>
                <CardHeader className='flex flex-row items-center justify-between'>
                    <CardTitle>Channels</CardTitle>
                </CardHeader>
                <CardContent className="flex justify-between w-full overflow-hidden">
                    <div className='flex flex-col gap-3 w-full'>
                        {assistant.channels?.length > 0 ? assistant.channels.map((channel) => (
                            <div key={channel._id} className='flex justify-between items-center'>
                                <div className="flex items-center gap-2 flex-grow max-w-[70%]">
                                    <MailOpenIcon className="min-w-6 min-h-6 text-muted-foreground" />
                                    <div className='w-full'>
                                        <p className='truncate'>{channel.address}</p>
                                        <p className="text-muted-foreground">{channel?.created?.split('T')[0] || 'N/A'}</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button onClick={() => handleRemoveEmailChannel(channel)} variant='destructive'>
                                                    Unsubscribe
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                {assistant.channels?.length === 1
                                                    ? "Assistant must have at least one connected channel"
                                                    : "Delete channel"}
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>

                                </div>
                            </div>
                        )): <h3>No channels found for this assistant</h3>}
                    </div>
                </CardContent>
            </Card>

            {/* opcode card */}
            <OpcodeInstructionDialog />

            {/* alert modal */}
            <AlertModal openModal={showDelModal} setopenModal={setShowDelModal} 
            onDelete={handleRemoveAssistantChannel} loading={btnLoading} title='Are you sure you want to remove this channel' btnTitle='Confirm' />
        </>
    )
}

function BotIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
            <path d="M12 8V4H8" />
            <rect width="16" height="12" x="4" y="8" rx="2" />
            <path d="M2 14h2" />
            <path d="M20 14h2" />
            <path d="M15 13v2" />
            <path d="M9 13v2" />
        </svg>
    )
}

function MailOpenIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
            <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
            <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
        </svg>
    )
}
