import React, { useEffect, useRef, useState } from "react";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../components/ui/tooltip";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { Info } from 'lucide-react';
import { Textarea } from "../../../components/ui/textarea";

export default function StyledTextAreaCn({
    value,
    onChange,
    labelText,
    rows = 2,
    tooltipText,
    placeholder,
    disabled = false,
    required = false,
    readOnly = false,
    actions = null,
    maxRows = null
}) {

    const textareaRef = useRef(null)
    const containerRef = useRef(null)
    const [isFocused, setIsFocused] = useState(false);

    const adjustTextareaAndScroll = () => {
        const textarea = textareaRef.current
        const container = containerRef.current
        if (textarea && container) {
            const previousHeight = textarea.style.height
            
            // Reset height to auto and set to scrollHeight to grow
            textarea.style.height = 'auto'
            let newHeight = `${textarea.scrollHeight}px`
            
            // If maxRows is defined, limit the height
            if (maxRows) {
                const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight)
                const maxHeight = lineHeight * maxRows
                if (textarea.scrollHeight > maxHeight) {
                    newHeight = `${maxHeight}px`
                    textarea.style.overflowY = 'auto'
                } else {
                    textarea.style.overflowY = 'hidden'
                }
            }

            textarea.style.height = newHeight

            // Adjust container height and scroll
            if (previousHeight !== newHeight) {
                container.style.height = newHeight
                container.scrollTop = container.scrollHeight
            }
        }
    }
    const handleChange = (e) => {
        const textarea = e.target
        const { selectionStart, selectionEnd } = textarea
        
        onChange?.(e)
        
        requestAnimationFrame(() => {
          adjustTextareaAndScroll()
          if (textareaRef.current) {
            textareaRef.current.selectionStart = selectionStart
            textareaRef.current.selectionEnd = selectionEnd
          }
        })
    
        
      }

    useEffect(() => {
        adjustTextareaAndScroll()
    }, [value])
    useEffect(() => {
        window.addEventListener('resize', adjustTextareaAndScroll)
        return () => window.removeEventListener('resize', adjustTextareaAndScroll)
    }, [])
    
    return (
        <div className="flex flex-col gap-[2px] h-fit w-full">
            <div className="flex flex-row items-center gap-2">
                <TooltipProvider className="flex items-center justify-between">
                    {labelText && (
                        <Label htmlFor="email" className="select-none text-xs">
                            {labelText} {required && <span className="text-red-500">*</span>}
                        </Label>
                    )}

                    {tooltipText && (
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button variant="ghost" size="icon">
                                    <Info className="h-4 w-4" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent className="w-[300px]">
                                <p>{tooltipText}</p>
                            </TooltipContent>
                        </Tooltip>
                    )}
                </TooltipProvider>
            </div>
            <div className={`border rounded-md h-fit ${isFocused ? 'ring-1 ring-gray-400' : ''}`} ref={containerRef}>
                <Textarea
                    ref={textareaRef}
                    placeholder={placeholder ? placeholder : ""}
                    value={value}
                    onChange={handleChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className="w-full p-2 border border-gray-300 rounded-md text-xs border-none focus-visible:ring-0 shadow-none"
                    disabled={disabled}
                    readOnly={readOnly}
                    rows={rows}
                    style={{ resize: 'none', overflow: 'hidden' }}
                />
                {
                    actions && actions.some(action => action.visible) && 
                    (<div className="flex w-full justify-end select-none cursor-text" onClick={() => {
                        textareaRef.current.focus();
                        textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
                    }}>
                        <div className="flex gap-2 px-2 pb-2 cursor-default">
                            {actions.filter(action => action.visible).map((action) => (
                                <Button variant="outline" className="px-2 shadow-md opacity-90 hover:opacity-100 cursor-pointer" onClick={action.onClick} disabled={action?.disabled}>
                                    {action?.icon}
                                </Button>
                            ))}
                        </div>
                    </div>)
                }
                
            </div>
        </div>
    );
}
