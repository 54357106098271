import { SubOrgContext } from 'context/SubOrganizationContext';
import React, { useContext } from 'react'
import { Label } from 'components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { Input } from 'components/ui/input';
import { Combobox } from 'components/ui/combobox';

const StepNonLLMAPI2 = ({selectedFunctionProps, inputGroup, stepIndex, handleInputChange}) => {
    const { collections } = useContext(SubOrgContext);

    const formatToTitleCase = (str) => {
        return str
            .replace(/_/g, ' ')           // Replace underscores with spaces
            .replace(/\w\S*/g, function(word) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });                           // Convert to Title Case
    }

    const selectedCollectionObj = (id) => {
        let collectionObj = {name:"Select Item", _id:""}
        const foundeCollection = collections.find(item => item._id === id)

        if(id && foundeCollection){
            collectionObj = foundeCollection
        }

        return collectionObj
    }

    return (
        <>
            {/* Render Inputs Based on Properties */}
            {Object.entries(selectedFunctionProps)
            .filter(([param]) => {
                // Exclude specific parameters unconditionally
                if (param === "org_id" || param === "query_text" || param === "sub_org_id" || param === "filters" || param === "offset") {
                    return false;
                }

                // Exclude "top_n" only if "use_rerank" is set to true
                if (param === "top_n" && !inputGroup?.input?.["use_rerank"]) {
                    return false;
                }

                // Include all other parameters
                return true;
            }).map(([param], idx) => (
                <div key={idx} >
                    {param === "collection_id" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${stepIndex}`}>Collection</Label>

                            <Combobox 
                            comboboxWidth='w-full' 
                            items={collections} 
                            setter={(value) => handleInputChange(param, value)} 
                            selectedValue={selectedCollectionObj(inputGroup?.input?.[param] || "")} 
                            disabled={collections?.length === 0} 
                            />
                        </div>
                    ) : param === "use_rerank" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${stepIndex}`}>Use Rerank</Label>

                            <Select
                                defaultValue={inputGroup?.input?.[param] ? "true" : "false"}
                                onValueChange={(value) => handleInputChange(param, value)}
                                value={inputGroup?.input?.[param] ? "true" : "false"}
                            >
                                <SelectTrigger className="w-full bg-white">
                                    <SelectValue placeholder="Select Option" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem key={"rerank_true"} value={"true"}>
                                        True
                                    </SelectItem>
                                    <SelectItem key={"rerank_false"} value={"false"}>
                                        False
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    ) : param === "top_k" || param === "top_n" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${stepIndex}`}>{formatToTitleCase(param)}</Label>
                            <Input
                                type="number"
                                min="0"
                                id={`param-${param}-${stepIndex}`}
                                value={inputGroup?.input?.[param] || 0}
                                onChange={(e) => handleInputChange(param, parseInt(e.target.value) || 0)}
                                className="bg-white"
                            />
                        </div>
                    ) : param === "offset" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${stepIndex}`}>{formatToTitleCase(param)}</Label>
                            <Input
                                type="number"
                                min="0"
                                id={`param-${param}-${stepIndex}`}
                                value={inputGroup?.input?.[param] || 0}
                                onChange={(e) => handleInputChange(param, parseInt(e.target.value) || 0)}
                                className="bg-white"
                            />
                        </div>
                    )  : param === "search_type" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${stepIndex}`}>{formatToTitleCase(param)}</Label>
                            <Select
                                defaultValue={inputGroup?.input?.[param] ? inputGroup?.input?.[param] : "vector"}
                                onValueChange={(value) => handleInputChange(param, value)}
                                value={inputGroup?.input?.[param] ? inputGroup?.input?.[param] : "vector"}
                            >
                                <SelectTrigger className="w-full bg-white">
                                    <SelectValue placeholder="Select Option" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem key={"vector"} value={"vector"}>
                                        vector
                                    </SelectItem>
                                  
                                </SelectContent>
                            </Select>
                        </div>
                    ) : ""}
                </div>
            ))}
        </>
    )
}

export default StepNonLLMAPI2