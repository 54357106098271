import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SubOrgContext } from "context/SubOrganizationContext";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Context } from "context/GlobalState";

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${(props) => (props.$active ? "black" : "#0d638f")};
    text-transform: capitalize;
    pointer-events: ${(props) => (props.$active ? "none" : "")};
`;

export default function BreadcrumbsCustom({ maxPaths }) {
  // const { user } = useContext(Context);
  const { organizations } = useContext(Context);
  const { workflow } = useContext(SubOrgContext);

  const workflowAssistants = workflow?.workflowType?.assistants;

  const getAppName = (appId) => {
    return workflowAssistants.find((app) => app._id === appId)?.name;
  };

  const getOrgName = (orgId) => {
    return organizations.find((org) => org._id === orgId)?.name;
  };

  const DynamicOrgBreadcrumb = ({ match }) => (
      <span>{getOrgName(match.params.organizations)}</span>
  );

  const DynamicAppBreadcrumb = ({ match }) => (
      <span>{getAppName(match.params["bex-assistants"])}</span>
  );

  const routes = [
    {
      path: "/organizations/:organizations",
      breadcrumb: DynamicOrgBreadcrumb,
    },
    {
      path: "/organizations/:organizations/sub/",
      breadcrumb: null
    },
    {
      path: "/organizations/:organizations/sub/:sub",
      breadcrumb: null,
    },
    {
      path: "/organizations/:organizations/sub/:sub/bex-assistants/:bex-assistants",
      breadcrumb: DynamicAppBreadcrumb,
    },
    {
      path: "/organizations/:organizations/sub/:sub/knowledge-builder/:collectionId",
      breadcrumb: null,
    },
    {
      path: "/organizations/:organizations/sub/:sub/op-codes/:opCodeName",
      breadcrumb: null,
    },
    {
      path: "/organizations/:organizations/sub/:sub/ai-lab/instructions/:uniqueName/:modelType",
      breadcrumb: null,
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  const renderedBreadcrumbs = maxPaths ? breadcrumbs.slice(-maxPaths) : breadcrumbs;

  return (
      <Breadcrumb>
          <BreadcrumbList>
              {renderedBreadcrumbs.map(({ match, breadcrumb }, index) => {
                const decodedBreadcrumb = decodeURIComponent(breadcrumb.props.children); //prevents showing %20 in Collection Name
                return (
                  breadcrumb && (
                      <BreadcrumbItem key={index}>
                          {index < renderedBreadcrumbs.length - 1 ? (
                              <StyledLink to={match.pathname}>{decodedBreadcrumb}</StyledLink> 
                          ) : (
                              <span style={{ textTransform: "capitalize" }}>{decodedBreadcrumb}</span>
                          )}
                          {index < renderedBreadcrumbs.length - 1 && <BreadcrumbSeparator />}
                      </BreadcrumbItem>
                  )
                );
              })}
          </BreadcrumbList>
      </Breadcrumb>

  );
}
