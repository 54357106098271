import { TextSearch, EyeOffIcon, EyeIcon, CircleAlert } from "lucide-react";
import { Button } from "components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { useContext, useState } from "react";
import { milvusService } from "api/services/PROJECT-O/Milvus.service";
import { OrganizationContext } from "context/OrganizationContext";
import { SubOrgContext } from "context/SubOrganizationContext";
import { useParams } from "react-router-dom";
import { Card, CardContent, CardHeader } from "components/ui/card"
import { Switch } from "components/ui/switch"
import { ScrollArea } from "components/ui/scroll-area";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";

export function CollectionQueryModal({collectionName, selectedItems}) {
    const SEARCH_TYPES_ARR = [
        {name: 'vector', description: ''},
        {name: 'enhanced_vector', description: ''},
        {name: 'hybrid', description: ''},
        {name: 'keyword', description: ''},
    ]

    const [query, setQuery] = useState("");
    const [useRerank, setUseRerank] = useState(false)
    const [searchType, setSearchType] = useState('hybrid')
    const [topK, setTopK] = useState(10)
    const [topN, setTopN] = useState(3)
    const [open, setOpen] = useState(false);
    const [isQuerying, setIsQuerying] = useState(false);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);
    const { collectionId } = useParams()
    const [queryResults, setQueryResults] = useState([])
    const [message, setMessage] = useState("")
    const [viewerShownMap, setViewerShownMap] = useState({});
    const itemsLength = selectedItems?.length || 0

    const getItemsNames = () => {
        const itemsNames = selectedItems?.map(item => item.name)
        return itemsNames || []
    }

    const handleSendQuery = async (e) => {
        e.preventDefault()

        setQueryResults([])
        setViewerShownMap({})
        try{
            setIsQuerying(true)

            const fileNames = getItemsNames()
            const body = {
                "organization_id": selectedOrganization._id,
                "sub_organization_id": selectedSubOrganization._id,
                "collection_id": collectionId,
                "query_text": query,
                "offset": 0,
                "top_k": topK,
                "top_n": topN,
                "filters":{},
                "advanced_filters": fileNames.length > 0 ? [
                    {
                        "condition_type": "AND",
                        "queries": [
                            {
                            "query_type": "in",
                            "field": "file_key",
                            "values": fileNames
                            },
                        ]
                    }
                ] : [],
                "use_rerank": useRerank,
                "search_type": searchType
            }
            console.log(body)
            const res = await milvusService.hybridSearch(body)
            console.log("Milvus res", res)

            const results = res.data.results || [];
            if (results.length === 0) {
                setMessage("No matching documents found.")
                return;
            }

            // Set the updated results and presigned URLs in the state
            setQueryResults(results);
        } catch (error) {
            console.error("Error sending query", error)
        } finally {
            setIsQuerying(false)
        }
    }
    const handleClear = () => {
        setQuery("");
        setQueryResults([]);
        setViewerShownMap({});
    }

    const toggleViewer = (index) => {
        setViewerShownMap(prevState => ({
            ...prevState,
            [index]: !prevState[index] // Toggle the state for this document
        }));
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="ghost" className="flex gap-2 text-xs" disabled={!collectionName}>
                    <TextSearch className='w-4 h-4' /> Query {`(${itemsLength})`}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-6xl select-none h-fit max-h-[650px] min-h-[300px] overflow-hidden">
                <DialogHeader>
                    <DialogTitle>Query {itemsLength > 0 && `(${itemsLength} file${itemsLength > 1 ? 's' : ''} selected)`}</DialogTitle>
                    <DialogDescription>
                        {itemsLength > 0 ? 'The system will search across the selected files' : 'The system will search across the whole collection.'}
                    </DialogDescription>
                </DialogHeader>
                <form className="flex flex-row items-center space-x-2" onSubmit={handleSendQuery}>
                    <div className="grid flex-1 gap-2">
                        <Input value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter your query here." />
                    </div>
                    <Button type="submit" size="sm" className="px-3" onSubmit={handleSendQuery} disabled={isQuerying || !query}>
                        { isQuerying ? "Querying..." : "Send Query" }
                    </Button>
                    <Button variant="secondary" type="reset" size="sm" className="px-3" onClick={handleClear}>
                        Clear
                    </Button>
                </form>
                <div className="w-full max-w-[500px]">
                    <Label> Search type </Label>
                    <Select onValueChange={(value) => setSearchType(value)} id="vector-store-type" defaultValue={searchType}>
                        <SelectTrigger className="w-full capitalize">
                            <SelectValue className="capitalize" placeholder="Select vector store type" />
                        </SelectTrigger>
                        <SelectContent>
                            {SEARCH_TYPES_ARR.map((item, index) => (
                                <TooltipProvider key={index}>
                                    <Tooltip className='z-[1000]'>
                                        <TooltipTrigger asChild>
                                            <SelectItem className='capitalize w-full flex flex-row gap-1'  value={item.name}>
                                                <div className="flex items-center gap-1 w-full justify-between">
                                                    {item.name}
                                                    {item.description && (
                                                        <CircleAlert size={16} />
                                                    )}
                                                </div>
                                            </SelectItem>
                                        </TooltipTrigger>
                                        {item.description && (
                                            <TooltipContent className="bg-gray-900 text-white w-fit z-[1000]">
                                                <p>{item.description}</p>
                                            </TooltipContent>
                                        )}
                                    </Tooltip>
                                </TooltipProvider>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                        <Switch
                        id="use-rerank"
                        checked={useRerank}
                        onCheckedChange={setUseRerank}
                        />
                        <Label htmlFor="use-rerank">Use Rerank</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Label htmlFor="top-k">Top K:</Label>
                        <Input
                        id="top-k"
                        type="number"
                        value={topK}
                        onChange={(e) => setTopK(Number(e.target.value))}
                        className="w-20"
                        />
                    </div>
                    {useRerank &&
                    <div className="flex items-center space-x-2">
                        <Label htmlFor="top-n">Top N:</Label>
                        <Input
                        id="top-n"
                        type="number"
                        value={topN}
                        onChange={(e) => setTopN(Number(e.target.value))}
                        className="w-20"
                        />
                    </div>
                    }
                </div>
                <ScrollArea className="max-h-[400px] h-fit">
                    <div className="h-fit pr-4">
                        {queryResults.map((doc, index) => (
                            <Card key={index} className="w-full mb-4 bg-gray-100">
                                <CardHeader className="py-2 px-6">
                                    <div className="grid grid-cols-2 gap-2 text-xs">
                                        <div className="col-span-2">
                                            <span className="font-semibold">File Key: </span>
                                            <span className="text-gray-600 break-words">{doc.file_key}</span>
                                        </div>
                                        <div>
                                            <span className="font-semibold">Page Number: </span>
                                            <span>{doc.page_number || "-"}</span>
                                            
                                        </div>
                                        <div>
                                            <span className="font-semibold">Sheet Name: </span>
                                            <span>{doc.sheet_name || "-"}</span>
                                            
                                        </div>
                                        <div className="col-span-2">
                                            <span className="font-semibold">URL: </span>
                                            <a href={doc.presigned_url} target="_blank" className="hover:underline text-blue-400">{doc.document_name}</a>
                                        </div>
                                        <div>
                                            <span className="font-semibold">Score: </span>
                                            <span>{doc.distance}</span>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardContent className="space-y-2 py-2 px-6">
                                    <div>
                                        <h3 className="font-semibold mb-2 text-sm">Text:</h3>
                                        <ScrollArea className="max-h-[150px] w-full rounded-md p-0 border bg-white">
                                            <p className="text-xs whitespace-pre-wrap p-2 border-none">{doc.text.trim()}</p>
                                        </ScrollArea>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center space-x-2">
                                            <Switch
                                                checked={viewerShownMap[index] || false}
                                                onCheckedChange={() => toggleViewer(index)}
                                            />
                                            {viewerShownMap[index] ? (
                                                <EyeIcon className="h-4 w-4 text-gray-500" />
                                            ) : (
                                                <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                            )}
                                        </div>
                                        <div className="">
                                            {viewerShownMap[index] && doc.presigned_url && (
                                                doc.document_type === "application/pdf" ? (
                                                    // Render iframe for PDF files
                                                    <iframe src={doc.presigned_url} frameborder="0" className="w-full h-[400px]"></iframe>
                                                ) : (
                                                    // Render iframe for other document types (office files)
                                                    <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(doc.presigned_url)}`} frameborder="0" className="w-full h-[400px]"></iframe>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </ScrollArea>
                {message && (
                    <Label>
                        {message}
                    </Label>
                )}
                {/* <DialogFooter className="sm:justify-start">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                </DialogFooter> */}
            </DialogContent>
        </Dialog>
    );
}
