import { useState, useEffect, useRef } from "react"
import { Button } from "components/ui/button"
import { createWebSocketClient } from "api/clients/knowledgeBuilder.httpClient"

function convertTimestamp(timestamp) {
    try {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const date = new Date(timestamp * millisecondsPerDay);
        const formattedDate = date.toISOString();
        return formattedDate;
    } catch (error) {
        return 'N/A'
    }
  }

export default function ActivityLogViewer() {
    const [logs, setLogs] = useState([])
    const logContainerRef = useRef(null)

    useEffect(() => {
        const eventSource = createWebSocketClient("/logs/ws/logs")

        eventSource.onmessage = (event) => {
            setLogs((prevLogs) => [...prevLogs, event])
        }

        return () => {
            eventSource.close()
        }
    }, [])

    useEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight
        }
    }, [logContainerRef]) //Corrected dependency

    return (
        <div className="space-y-4">
            <div className="flex w-full justify-end space-x-2">
                <Button onClick={() => setLogs([])}>Clear Logs</Button>
            </div>
            <div
                ref={logContainerRef}
                className="h-[calc(100vh-200px)] overflow-y-auto border rounded-md p-4 bg-black text-white font-mono text-sm"
            >
                {logs.map((log) => (
                    <div key={log.timeStamp}>
                        <span className="text-gray-400">{convertTimestamp(log.timeStamp)}</span>{" "}
                        <span className="text-gray-400">{log.data}</span>{" "}
                        <span className="text-gray-400">{log.type}</span>{" "}
                        <span className="text-gray-400">{log.target.url}</span>{" "}
                    </div>
                ))}
            </div>
        </div>
    )
}
