import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from "components/ui/tooltip";
import { CircleAlert } from "lucide-react";

export function SelectDropdown({ options, value, onChange, placeholder }) {
    return (
        <Select onValueChange={onChange} defaultValue={value}>
            <SelectTrigger className="w-full capitalize">
                <SelectValue className="capitalize" placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                {options.map((item, index) => (
                    <TooltipProvider key={index}>
                        <Tooltip className='z-[1000]'>
                            <TooltipTrigger asChild>
                                <SelectItem className='capitalize w-full flex flex-row gap-1' value={item.name}>
                                    <div className="flex items-center gap-1 w-full justify-between">
                                        {item.name}
                                        {item.description && (
                                            <CircleAlert size={16} />
                                        )}
                                    </div>
                                </SelectItem>
                            </TooltipTrigger>
                            {item.description && (
                                <TooltipContent className="bg-gray-900 text-white w-fit z-[1000]">
                                    <p>{item.description}</p>
                                </TooltipContent>
                            )}
                        </Tooltip>
                    </TooltipProvider>
                ))}
            </SelectContent>
        </Select>
    );
}