import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
// import { Input } from "components/ui/input";
import '../prompting-and-rag-training.css';

import { Textarea } from "components/ui/textarea";
import React, { useRef } from "react";
import { Card, CardContent } from "components/ui/card";
import { Button } from "components/ui/button";
import { PageContent, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from 'components/NewDesignComponents/PageLayout';
import { Code, NotebookPen, SlidersHorizontal, TextSearch, TextCursorInput } from 'lucide-react'
import { useNavigate } from "react-router-dom";


const TabContent = ({ icon: Icon, title, description, tags, buttonText, buttonAction }) => (
    <Card className="w-full rounded h-full shadow-md bg-white overflow-hidden border">
        <CardContent className="p-0 flex flex-col md:flex-row items-center justify-center h-full w-full">
            <div className="h-full min-w-[200px] w-full md:w-1/3">
                <div className="bg-gray-200 w-full h-full flex items-center justify-center p-6">
                    <Icon className="w-32 h-32 text-[#b71e3d]" />
                </div>
            </div>
            <div className="h-full min-w-[300px] w-full md:w-2/3">
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-4 text-center md:text-left">{title}</div>
                    <p className="text-gray-700 text-sm text-center md:text-left">
                        {description}
                    </p>
                </div>
                <div className="px-6 pt-4 pb-4 text-center md:text-left">
                    {tags.map((tag, index) => (
                        <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">
                            {tag}
                        </span>
                    ))}
                </div>
                <div className="px-6 pb-4 flex justify-center md:justify-start">
                    <Button className="w-fit" onClick={buttonAction}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </CardContent>
    </Card>
);


export default function Laboratory() {
    
    const navigate = useNavigate()

    const onClickNavigate = (path) => {
        navigate(path);
    }

    const tabs = [
        {
            value: "prompt",
            label: "Prompt Lab",
            description: "Learn how to create a prompt",
            icon: TextCursorInput,
            content: (
                <TabContent
                    icon={TextCursorInput}
                    title="Prompt Lab"
                    description="The Prompt Lab equips enterprise workers with the skills to master prompt engineering and design workflow instructions for use with BeX Operational Code. Learn to perform tasks like summarization, extraction, and content generation, while exploring advanced techniques such as ReAct and Chain of Tought, all within the Prompt Lab."
                    tags={["#promptengineering", "#contentgeneration", "#workflowdesign"]}
                    buttonText="Live Prompt"
                    buttonAction={() => onClickNavigate(`prompt-lab`)}
                />
            )
        },
        {
            value: "code",
            label: "Code Sandbox",
            description: "Use AI to learn programming and try it out in the sandbox",
            icon: Code,
            content: (
                <TabContent
                    icon={Code}
                    title="Code Sandbox"
                    description="The Code Sandbox is the AI powered code generation and execution environment for building python or JS codes. The code functions can be exported as non LLM instructions for use in OpCodes when deployed."
                    tags={["#codegeneration", "#sandbox", "#programming"]}
                    buttonText="Code Sandbox"
                    buttonAction={() => onClickNavigate(`code-sandbox`)}
                />
            )
        },
        {
            value: "teacher",
            label: "AI Teacher",
            description: "Need help? AI teacher is here to help",
            icon: NotebookPen,
            content: (
                <TabContent
                    icon={NotebookPen}
                    title="AI Teacher"
                    description="Meet the AI Teacher, our built in learning tool to learn about anything in the BeX Platform, or you can create your customized knowledge based to use in the AI Teacher."
                    tags={["#learning", "#knowledgebase", "#aiteacher"]}
                    buttonText="AI Teacher"
                    buttonAction={() => onClickNavigate(`ai-teacher`)}
                />
            )
        },
        {
            value: "instructions",
            label: "Instructions",
            description: "Manage your saved instructions and use them in your workflows",
            icon: TextSearch,
            content: (
                <TabContent
                    icon={TextSearch}
                    title="Instructions"
                    description="An instruction is a carefully crafted system prompt, designed with specific parameters and a chosen foundation model (LLM) to meet your unique needs. It can be saved and seamlessly integrated into any workflow, ensuring consistency and efficiency in task execution."
                    tags={["#systemprompt", "#customai", "#taskautomation"]}
                    buttonText="Instruction"
                    buttonAction={() => onClickNavigate(`instructions`)}
                />
            )
        },
        {
            value: "tuning_lab",
            label: "Tuning Lab",
            description: "Tuning Lab Description",
            icon: SlidersHorizontal,
            content: (
                <TabContent
                    icon={SlidersHorizontal}
                    title="Tuning Lab"
                    description="The Tuning Lab provides the facility to tune your AI Models used in the platform. After tuning, they can be deployed to be used."
                    tags={["#tuning", "#aimodels", "#deployment"]}
                    buttonText="Tuning Lab"
                    buttonAction={() => onClickNavigate(`tuning-lab`)}
                />
            )
        },
    ];

    const scrollAreaRef = useRef(null);

    return (
        <PageLayout ref={scrollAreaRef}>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Master Prompt Engineering
                    </PageTitle>
                    <PageSubTitle>
                        Unlock AI Powers
                    </PageSubTitle>
                    <PageDescription>
                        Harness the power of intelligent AI to transform business execution. Streamline processes, make smarter decisions, and achieve unparalleled efficiency with workflows designed for the modern enterprise
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button className='w-full'> Add New Instruction </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent>
                <div className="text-center w-full">
                    <span className="text-2xl font-semibold"><span className="text-[#b1092a]">AI Lab</span>: All you need to master prompting</span>
                    <div className="pt-4">
                        <Tabs defaultValue={"prompt" || tabs[0].value} className="flex flex-row w-full">
                            <div className="w-1/2 flex flex-col pr-4">
                                <Textarea
                                    placeholder="Describe what kind of prompt you want to create"
                                    className="mr-4 mb-4 resize-none"                    
                                    />
                                <TabsList className="flex flex-col h-auto w-full space-y-2 p-1 p-2 bg-transparent">
                                    {tabs.map((tab) => (
                                        <TabsTrigger
                                            key={tab?.value}
                                            value={tab?.value}
                                            className="flex items-center justify-start px-4 py-2 w-full data-[state=active]"
                                            >
                                            <tab.icon className="w-8 h-8 min-w-8 min-h-8 mr-4"/>
                                            <div className="text-left text-wrap">
                                                <div className="text-sm font-semibold">{tab.label}</div>
                                                <div className="text-xs text-muted-foreground">{tab.description}</div>
                                            </div>
                                        </TabsTrigger>
                                    ))}
                                </TabsList>
                            </div>
                            {tabs.map((tab) => (
                                <TabsContent key={tab.value} value={tab.value} className="flex-1 w-1/2">
                                    {tab.content}
                                </TabsContent>
                            ))}
                        </Tabs>
                    </div>
                </div>
            </PageContent>
        </PageLayout>
    );
}