/* eslint-disable max-lines */
import { instructionService } from 'api/services/BEX/instruction.service'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import InstructionCard from './InstructionCard'
import { Context } from 'context/GlobalState'
import AlertModal from 'components/Alertmodal'
import { Button } from 'components/ui/button'
import { AppLoader } from 'components/LoaderSpinner'
import InstructionForm from './InstructionForm'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper'
import UpdateInstructionDialog from './UpdateInstruction'
import { checkRequiredFields, getParameterType } from './helper'
import { FilterBar, SearchBar } from './Filters'
import Breadcrumb from 'components/Breadcrumb';
import { OrganizationContext } from 'context/OrganizationContext'


export default function Instructions() {

    const [instructions, setInstructions] = useState([])
    const [selectModelType, setSelectModelType] = useState('All')
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)

    const [updateModal, setUpdateModal] = useState(false)
    const [delModal, setDelModal] = useState(false)
    const [delBtn, setDelBtn] = useState(false)
    const [selectedCard, setSelectedCard] = useState(false)
    const [isCopy, setIsCopy] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
        
    const { oragID, subOragID } = useParams()


    useEffect(() => {
        instructionService.listModels(oragID, subOragID)
        .then((res) => {
            setInstructions(res.data)
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])


    const handleDeleteInstruction = () => {
        setDelBtn(true)
        
        const delObj = {
            org_id: oragID,
            sub_org_id: subOragID,
            model_type: selectedCard.model_type,
            unique_names: [selectedCard.unique_name],
        }
        instructionService.deleteModels(delObj)
        .then((res) => {
            console.log('res: ', res);
            const uniqueName = res.data.find(item => item.unique_name)
            if (uniqueName && uniqueName?.status === 200) {
                addNewNotifcation('Instruction deleted successfully', 'success')
                setInstructions(prev => prev.filter(item => item.unique_name !== selectedCard.unique_name))
                setDelModal(false)
            } else if (uniqueName && uniqueName?.status === 409) {
                addNewNotifcation(`Failed to delete instruction. ${uniqueName.reason || 'This instruction is linked to opcodes'}`, 'danger')
            } else {
                addNewNotifcation('Failed to delete instruction. Something went wrong', 'danger')
            }
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setDelBtn(false)
        })
    }

    const getFilteredList = () => {
        return instructions.filter(item => 
            item.unique_name?.toLowerCase()?.includes((searchTerm.toLowerCase())) &&
            (selectModelType === 'All' || item.model_type === selectModelType))
    };
    
    // Handle search term change
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    // Handle search term change
    const handleType = (value) => {
        setSelectModelType(value);
    };

    const handleOnUpdateSuccess = (updatedInstruction) => {
        if (isCopy) {
            setInstructions(prev => ([...prev, updatedInstruction]))
        } else {
            setInstructions(prev => prev.map((item) => item.unique_name === selectedCard.unique_name ? updatedInstruction : item))
        }
    }


    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <div className='flex flex-col w-full h-full p-8 overflow-y-scroll'>
            <div className='my-3 px-2'>
                <Breadcrumb maxPaths={2} />
            </div>
            <div className='flex flex-col w-full my-4 p-2'>
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold">Instructions</h1>
                    <Button onClick={() => setShow(true)}> Add new instruction </Button>
                </div>
                <div className="mb-6 flex flex-col sm:flex-row gap-4 items-center">
                    <div className="flex-grow">
                        <SearchBar onSearch={handleSearch} />
                    </div>
                    <FilterBar
                    onSearch={handleType}
                    selectModelType={selectModelType}
                    />
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {getFilteredList().length > 0 ?
                    getFilteredList().map((item) => (
                        <InstructionCard
                        selectedOrganization={selectedOrganization}
                        key={item._id}
                        {...item}
                        onManage={() => {
                            setSelectedCard(item)
                            setUpdateModal(true)
                            setIsCopy(false)
                        }}
                        onCopy={() => {
                            setIsCopy(true)
                            setSelectedCard(item)
                            setUpdateModal(true)
                        }}
                        onDelete={() => {
                            setSelectedCard(item)
                            setDelModal(true)
                        }}
                        />
                    )) :
                    <p className="px-4">No instructions found.</p>
                }  
            </div>

            {/* create new instruction dialog */}
            <CreateNewInstructionDialog openModal={show} setOpenModal={setShow} setInstructions={setInstructions} />

            {/* update instruction dialog */}
            <UpdateInstructionDialog
            copy={isCopy} selectedCard={selectedCard} 
            openModal={updateModal} setOpenModal={setUpdateModal} 
            onSuccess={handleOnUpdateSuccess} // set instructions state on success
            />


            {/* delete instruction modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDeleteInstruction} />
        </div>
    )
}


const CreateNewInstructionDialog = ({ setInstructions, openModal, setOpenModal }) => {

    const initialData = {

        unique_name: '',
        model_type: 'IBM',
        modelDescription:'',
        selectedModel: '',

        instruction: '',
        context: '',
        targetFormat: '',
        sampleInput: '',
        sampleOutput: '',
        inputData: '',

        groqParameters: {
            max_tokens: 200,
            n: 1,
            top_p: 1,
            stop: ["<|endoftext|>", "<|endofcode|>"],
            temperature: 0,
            frequency_penalty: 1.2,
            presence_penalty: 0,
            response_format: {"type": "text"},
        },

        watsonParameters: {
            decoding_method: "greedy",
            temperature: 0.7,
            min_new_tokens: 10,
            max_new_tokens: 200,
            top_p: 1, top_k: 50,
            repetition_penalty: 1.0,
            stop_sequence: ["<|endoftext|>", "<|endofcode|>"],
        },
        sambaNovaParameters: {
            max_tokens: 200,
            top_p: 1,
            top_k: 50,
            stop: ["<|endoftext|>", "<|endofcode|>"],
            temperature: 0,
        }
    }

    const [instructionForm, setInstructionForm] = useState(initialData);
    const [btnLoading, setBtnLoading] = useState(false)
    
    const { oragID, subOragID  } = useParams()
    const { addNewNotifcation, user } = useContext(Context)

    const handleCreateInstruction = () => {
        const requiredFields = [
            {field: 'model_type', name: 'model type'},
            {field: 'unique_name', name: 'unique name'},
            {field: 'modelId', name: 'model'},
        ]
        
        const instructionObj = {
            "model_type": instructionForm.model_type,
            "unique_name": instructionForm.unique_name,
            "modelId": instructionForm.selectedModel,
            "prompt": labHelper.ConstructPromptUnified({
                "instruction": instructionForm.instruction.trim(),
                "context": instructionForm.context.trim(),
                "target_format": instructionForm.targetFormat.trim(),
                "sample_input": instructionForm.sampleInput.trim(),
                "sample_output": instructionForm.sampleOutput.trim(),
            }),
            "parameters": getParameterType(instructionForm),
            "org_id": oragID,
            "sub_org_id": subOragID,
            "modelDescription": instructionForm.modelDescription,
            "user_id": user._id,
            "placeholders": {},
            "metadata": {}
        }
        
        const checkFields = checkRequiredFields(instructionObj, requiredFields)
        if (!checkFields.isValid) {
            return addNewNotifcation(`Please fill in all required fields: ${checkFields.missingFields.map(item => (item.name)).join(', ')}`, 'warning')
        } else if (!instructionForm.instruction || instructionForm.instruction.trim() === '') {
            return addNewNotifcation(`Please fill in all required fields: instruction`, 'warning')
        }

        setBtnLoading(true)
        
        instructionService.createModel(instructionObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Instruction created successfully', 'success')
            setInstructions(prev => ([...prev, res.data]))

            setInstructionForm(initialData)
            setOpenModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 409) { 
                addNewNotifcation(`Instruction with ${instructionForm.unique_name} already exists`, 'danger')
            } else {
                addNewNotifcation(`Something went wrong. Please try again`, 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="max-w-[850px] w-full">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Create New Instruction</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Set up a new instruction.</DialogDescription>
                </DialogHeader>

                <InstructionForm form={instructionForm} setForm={setInstructionForm} update={false} />

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                    <Button disabled={btnLoading} onClick={handleCreateInstruction} className="px-5 py-3 text-md">
                        {btnLoading ? 'Creating instruction...' : 'Create instruction'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
