import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "components/ui/card";
import { Button } from "components/ui/button";
import { Copy, SendHorizontal, Check, RotateCcw, Info } from 'lucide-react';
import Editor from '@monaco-editor/react';
import { ScrollArea } from "components/ui/scroll-area";
import { v4 as uuidv4 } from 'uuid';
import { Prompt } from "../../context/helper/sandboxOps";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "components/ui/tooltip";
import { SampleList } from './SampleList';
import { Textarea } from "components/ui/textarea";
import { SandboxContext } from 'pages/PromptingAndRagTraining/context/SandboxContext';
import InstructionDialog from './InstructionDialog.jsx';
import { PageContent, PageLayout } from 'components/NewDesignComponents/PageLayout';
import Breadcrumb from 'components/Breadcrumb';


const INFO = `
The Code Sandbox uses the model 'mistralai/mixtral-8x7b-instruct-v01' which is trained to follow an instruction format:
<s> [INST] Instruction [/INST] Model answer</s>

This model has been optimised through supervised fine-tuning and direct preference optimisation (DPO) for careful instruction following.

Model Parameters:
Decoding: greedy
Max Tokens: 2000
Repetition Penalty: 1.2
Stopping Criteria: <|endoftext|>, <|endofcode|>
`;


// Sandbox Wrapper Component
const CodeSandbox = () => {
    return (
        <PageLayout>
            <PageContent>
                <div className="flex flex-col w-full h-full">
                    <div className='my-3 px-2'>
                        <Breadcrumb maxPaths={2} />
                    </div>
                    <div className="w-full p-2 grid grid-cols-3 gap-4 h-full">
                        <div className="col-span-1">
                            <CodePrompt/>
                        </div>
                        <div className="col-span-2">
                            <CodeEditor/>
                        </div>
                    </div>
                </div>
            </PageContent>
        </PageLayout>

    );
};


// Code Editror Component
const CodeEditor = () => {
    const {
        editorCode, setEditorCode,
        language, setLanguage,
        output, setOutput,
        isExecuting, setIsExecuting,
    } = useContext(SandboxContext);
    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    };


    useEffect(() => {
        let newCode = "";
        if (language === "python") newCode = editorCode.replace("//", "#");
        else if (language === "javascript") newCode = editorCode.replace("#", "//");
        setEditorCode(newCode);
    }, [language])

    return (
        <Card className="w-full flex flex-col justify-between h-[620px]">
            <CardHeader className="p-0">
                <InstructionDialog/>
            </CardHeader>
            <CardContent className="flex-1 grid grid-cols-1 p-0">
 
                {loading && (
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="loader">Loading VS Code...</div>
                    </div>
                )}
                <iframe
                    src={`${process.env.REACT_APP_AI_LAB_VS_CODE}/`}
                    style={{ border: 'none', width: '100%', height: '100%' }}
                    className='rounded-lg'
                    title="Embedded Site"
                    onLoad={handleLoad}
                />
            </CardContent>
        </Card>
    );
};


const CodePrompt = () => {
    const { setEditorCode, setLanguage, inputMessage, setInputMessage, sandBoxConvo, setSandboxConvo, loading, setLoading } = useContext(SandboxContext);

    const endOfMessagesRef = useRef(null);

    const parseMessage = useCallback((message) => {
        const regex = /```(\w*)\n([\s\S]*?)```|(\[\w+\][\s\S]*?\[\/\w+\])/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = regex.exec(message)) !== null) {
            const [fullMatch, language, code, altBlock] = match;
            const startIndex = match.index;
            const endIndex = regex.lastIndex;

            if (startIndex > lastIndex) {
                parts.push(message.slice(lastIndex, startIndex));
            }

            if (language) {
                parts.push(
                    <CodeSnippet setEditorCode={setEditorCode} key={parts.length} code={code} language={language} index={parts.length} />
                );
            } else if (altBlock) {
                const languageMatch = altBlock.match(/\[(\w+)\]/);
                const lang = languageMatch ? languageMatch[1] : '';
                const codeContent = altBlock.replace(/\[\w+\]/, '').replace(/\[\/\w+\]/, '').trim();
                parts.push(
                    <CodeSnippet setEditorCode={setEditorCode} key={parts.length} code={codeContent} language={lang.toLowerCase()} index={parts.length} />
                );
            } else {
                parts.push(
                    <CodeSnippet setEditorCode={setEditorCode} key={parts.length} code={''} language={''} index={parts.length} />
                );
            }

            lastIndex = endIndex;
        }

        if (lastIndex < message.length) {
            parts.push(message.slice(lastIndex));
        }

        return parts.map((part, index) => <React.Fragment key={index}>{part}</React.Fragment>);
    }, [setEditorCode]);


    // Send Prompt
    const OnSendPrompt = useCallback(async () => {
        if (inputMessage.trim() === "") return;

        setLoading(true);
        const newMessage = { role: 'user', message: inputMessage, _id: uuidv4() };
        setSandboxConvo(prevMessages => [...prevMessages, newMessage]);
        setInputMessage('');

        try {
            const response = await Prompt(inputMessage, sandBoxConvo.slice(-4));
            const botMessage = response ? { role: 'assistant', message: response, _id: uuidv4() } : { role: 'system', message: "Error generating code.", _id: uuidv4() };
            setSandboxConvo(prevMessages => [...prevMessages, botMessage]);
        } catch {
            setSandboxConvo(prevMessages => [...prevMessages, { role: 'system', message: "Error generating code.", _id: uuidv4() }]);
        } finally {
            setLoading(false);
        }
    }, [inputMessage, sandBoxConvo, setLoading, setSandboxConvo]);

    const handleResetPrompt = useCallback(() => {
        setSandboxConvo([]);
    }, [setSandboxConvo]);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [sandBoxConvo]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                // Shift + Enter: Insert a new line
                event.preventDefault(); // Prevent default behavior
                setInputMessage((prevText) => prevText + '\n');
            } else {
                // Enter: Run a function
                event.preventDefault(); // Prevent default behavior
                OnSendPrompt();
            }
        }
    };

    return (
        <Card className="w-full flex flex-col justify-between min-h-[600px] h-fit">
            <CardHeader className="h-16 border-b-[1px] border-gray-200 px-6 py-4 flex flex-row items-center justify-between">
                <CardTitle>CodeGen AI</CardTitle>
                <div className="flex gap-2 items-center">
                    <SampleList inputMessage={inputMessage} setInputMessage={setInputMessage} setLanguage={setLanguage} />
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Info className="h-5 w-5" />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-[300px] whitespace-pre-line" align="center" side="bottom">
                                <p>{INFO}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <Button variant="ghost" size="icon" className="flex items-center" onClick={handleResetPrompt}>
                        <RotateCcw className="w-5 h-5" />
                    </Button>
                </div>
            </CardHeader>

            <CardContent className="border-t flex flex-row gap-2 items-center py-2 px-0 h-full w-full">
                <ScrollArea className="max-h-[460px] min-h-[460px] h-full w-full">
                    <div className="py-4 px-3">
                        {sandBoxConvo.map((convo) => (
                            <div
                                key={convo._id}
                                className={`text-sm mb-4 flex ${convo.role === "user" ? "justify-end" : "flex-row gap-2"}`}>
                                <div className={`rounded-[16px] px-3 py-2 max-w-[90%] ${convo.role === "user" ? "bg-gray-200" : "w-full"}`}>
                                    <pre className='break-words whitespace-pre-line text-sm'>
                                        {parseMessage(convo.message)}
                                    </pre>
                                </div>
                            </div>
                        ))}
                        {loading && (
                            <div className="rag-typing mb-4">
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                            </div>
                        )}
                    </div>
                    <div ref={endOfMessagesRef} />
                </ScrollArea>
            </CardContent>

            <CardFooter className="border-t flex flex-row gap-2 items-center py-2 px-3 h-fit">
                <Textarea
                    className="shadow-none outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0"
                    placeholder="Type something..."
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <Button variant="ghost" size="icon" onClick={OnSendPrompt}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    );
};

const CodeSnippet = ({index, code, language, setEditorCode}) => {
    const editorRef = useRef(null);
    const [contentHeight, setContentHeight] = useState("200px");
    const [isCopied, setIsCopied] = useState(false);

    // Function to handle editor mounting
    const handleEditorDidMount = (editor, monaco) => {
        // Store the editor instance in the ref
        editorRef.current = editor;
        const contentHeight = editorRef.current.getModel().getLineCount() * 19;
        // console.log(contentHeight + "px");
        setContentHeight(contentHeight + "px")
        editorRef.current.layout();
    };

    useEffect(()=>{
        if (editorRef.current){
            // console.log(editorRef.current)

            const contentHeight = editorRef.current.getModel().getLineCount() * 19;
            // console.log(contentHeight + "px");
            setContentHeight(contentHeight + "px")
            editorRef.current.layout();

        }
    }, [code, contentHeight])

    // const OnTransferCode = () => {
    //     setEditorCode(code);
    // }
    // const OnAppendCode = () => {
    //     setEditorCode((prevCode) => prevCode + `\n${code}`);
    // }
    const handleCopy = () => {
        window.navigator.clipboard.writeText(code)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    return (
        <div className="p-0 m-0 w-full my-2">
            <CardHeader className="py-0 px-3 flex flex-row justify-between items-center rounded-t-[8px] border bg-white">
                <CardDescription>
                    {language}
                </CardDescription>
                <div className="flex gap-2 items-center">
                    {/* <Button variant="ghost" size="icon" className="flex items-center" onClick={OnAppendCode}>
                        <BetweenHorizonalStart className="w-5 h-5" />
                    </Button>
                    <Button variant="ghost" size="icon" className="flex items-center" onClick={OnTransferCode}>
                        <Code className="w-5 h-5" />
                    </Button> */}
                    <div className="">
                        <Button variant="ghost" className="flex items-center gap-2 hover:bg-transparent px-1" onClick={handleCopy}>
                            {isCopied ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
                            <label className='cursor-pointer'>
                                {isCopied ? "Copied!" : "Copy code"}
                            </label>
                        </Button>
                    </div>
                </div>
                
            </CardHeader>
            {/* <CardContent className="p-0"> */}
            <pre className="whitespace-pre-wrap bg-gray-200 my-0 view-lines w-full">
                <Editor
                    width="100%"
                    height={contentHeight}
                    language={language}
                    value={code}
                    theme="vs-dark"
                    options={{
                        readOnly: true,
                        lineNumbers: "off",
                        minimap: {enabled: false},
                        scrollBeyondLastLine: false,
                        overviewRulerLanes: 0
                    }}
                    onMount={handleEditorDidMount}
                />
            </pre>
            {/* </CardContent> */}
        </div>
    )
}


export default CodeSandbox;