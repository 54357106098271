import { useState, useEffect, useContext } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { User, Clock, Bot, Settings2 } from 'lucide-react';
import { Label } from 'components/ui/label';
import { FilterBar, SearchBar } from 'pages/PromptingAndRagTraining/components/Instructions/Filters';
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';
import { Badge } from 'components/ui/badge';
import { Context } from 'context/GlobalState';
import promptLabHelper from 'pages/PromptingAndRagTraining/context/helper/PromptLabHelper';

function DetailsCard({ instructionDetails }) {
    return (
        <ScrollArea className="max-h-[400px] min-w-[480px] max-w-[480px] pr-4">
            <Card className="w-full my-2">
                <CardHeader>
                    <div className="flex items-start justify-between">
                        <div className="space-y-1">
                            <CardTitle className="text-xl whitespace-pre-wrap break-all">{instructionDetails?.unique_name}</CardTitle>
                            <CardDescription className="text-xs">Instruction</CardDescription>
                        </div>
                    </div>
                </CardHeader>
                <CardContent className="space-y-2.5">
                    <div className="space-y-2">
                        <div className="flex items-center gap-2 text-xs">
                            <User className="h-4 w-4" />
                            <Label className="font-medium text-xs">Created By:</Label>
                            <span className="text-muted-foreground">{instructionDetails?.user_id || "-"}</span>
                        </div>
                        <div className="flex items-center gap-2 text-xs">
                            <Clock className="h-4 w-4" />
                            <Label className="font-medium text-xs">Modified By:</Label>
                            <span className="text-muted-foreground">{instructionDetails?.modified_by || "-"}</span>
                        </div>
                    </div>
                    <hr />
                    <div className="space-y-2">
                        <div className="">
                            <Label className="text-xs font-medium">Description</Label>
                            <p className="text-xs text-muted-foreground">{instructionDetails?.modelDescription || "-"}</p>
                        </div>
                        <div className="space-y">
                            <Label className="text-xs font-medium">Prompt</Label>
                            <p className="text-xs text-muted-foreground whitespace-pre-wrap break-all">{instructionDetails?.prompt || "-"}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="space-y-2">
                        <div className="flex items-center gap-2">
                            <Bot className="h-4 w-4" />
                            <Label className="text-xs font-medium">Model ID</Label>
                        </div>
                        <code className="rounded bg-muted px-2 py-1 text-xs whitespace-pre-wrap break-all">{instructionDetails?.modelId}</code>
                    </div>
                    <hr />
                    <div className="space-y-2">
                        <div className="flex items-center gap-2">
                            <Settings2 className="h-4 w-4" />
                            <Label className="text-xs font-medium">Parameters</Label>
                        </div>
                        <div className="grid gap-4 sm:grid-cols-2">
                            {instructionDetails?.parameters && Object.entries(instructionDetails?.parameters)
                                .filter(([key]) => key !== "repetition_penalty" && key !== "stop_sequence" && key !== "n" && key !== "stop" && key !== "stream" && key !== "seed" && key !== "response_format")
                                .map(([key, value]) => (
                                    <div className="" key={key}>
                                        <Label className="text-xs font-medium">{promptLabHelper.formatToTitleCase(key)}</Label>
                                        <p className="text-sm text-muted-foreground">{value}</p>
                                    </div>
                            ))
                            }
                        </div>
                    </div>
                </CardContent>
            </Card>
            <ScrollBar orientation="horizontal" />
        </ScrollArea>
    );
}

export default function CommonInstructionSelectionModal({setForm, openModal, setOpenModal, onLoadInstruction = null}) {
    const [selectType, setSelectType] = useState("All");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const [instructionDetails, setInstructionDetails] = useState(null);
    const { dispatch, instructions, fetchInstructions, isLoadingInstructions } = useContext(AiLabContext);
    const { addNewNotifcation } = useContext(Context);

    const reloadInstructions = async () => {
        try {
            dispatch({type: "SET_INSTRUCTIONS", field: "instructions", value: [] })
            await fetchInstructions();
        } catch (error){
            console.error(error);
            addNewNotifcation("Error fetching instructions. Please try again.", "danger")
        }
    }

    const handleInstructionSelect = (instruction) => {
        if (selectedInstruction === instruction?.unique_name) {
            setSelectedInstruction(null);
            setInstructionDetails(null);
        } else {
            console.log(instruction)
            setSelectedInstruction(instruction?.unique_name);
            setInstructionDetails(instruction);
        }
    };

    const handleInstructionLoad = () => {
        const instructionToLoad = instructions.find((inst) => inst?.unique_name === selectedInstruction)
        const prompt = promptLabHelper.extractValues(instructionToLoad?.prompt)

        setForm("unique_name", instructionToLoad?.unique_name || '');
        setForm("description", instructionToLoad?.modelDescription || '');
        setForm("model_type", instructionToLoad?.model_type || '');
        setForm("selectedModel", instructionToLoad?.modelId || '');
        setForm("instruction", prompt["Instruction:"] || '');
        setForm("context", prompt["Context:"] || '');
        setForm("targetFormat", prompt["Target Format:"] || '');
        setForm("sampleInput", prompt["Sample Input:"] || '');
        setForm("sampleOutput", prompt["Sample Output:"] || '');
        setForm("region", instructionToLoad?.region || '');
        if (!prompt["Instruction:"]){
            setForm("instruction", instructionToLoad?.prompt || '');
        }

        if (instructionToLoad?.model_type === "IBM"){
            setForm("watsonParameters", instructionToLoad?.parameters)
        }
        else if (instructionToLoad?.model_type === "IBM"){
            setForm("groqParameters", instructionToLoad?.parameters)
        }

        if (onLoadInstruction) onLoadInstruction(instructionToLoad);
        setOpenModal(false);
    };

    const handleFilterModels = () => {
        if (!instructions) return []
        return instructions?.filter(instruction =>
            (instruction.unique_name.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (selectType === 'All' || instruction.model_type === selectType) // search within selected type if not All
            );
    };

    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>Select an Instruction</DialogTitle>
                    <DialogDescription>
                        Choose an instruction and view its details.
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex items-center">
                        <div className="flex flex-col w-full sm:flex-row gap-4 items-center p-4">
                            <div className="flex-grow">
                                <SearchBar onSearch={(value) => setSearchTerm(value)} />
                            </div>
                            <FilterBar
                            onSearch={(value) =>  setSelectType(value)}
                            selectModelType={selectType}
                            />
                        </div>
                        <Button className="flex gap-2" type="button" onClick={reloadInstructions} disabled={isLoadingInstructions}>
                            {isLoadingInstructions ? 'Loading Instructions...' : 'Reload Instructions'}
                        </Button>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                        {instructions?.length === 0 && (
                            <div className='flex items-center justify-center w-full text-gray-500'>
                                <span>No instructions saved.</span>
                            </div>
                        )}
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 p-4">
                                {handleFilterModels().length > 0 && (
                                    handleFilterModels().map((instruction) => (
                                        <Button
                                            key={instruction?._id || instruction.unique_name}
                                            variant={selectedInstruction === instruction.unique_name ? "default" : "outline"}
                                            onClick={() => handleInstructionSelect(instruction)}
                                            className="justify-start h-auto text-left"
                                        >
                                            <div className="w-full py-2">
                                                <div className='flex w-full justify-between gap-2 items-center'>
                                                    <div className="font-semibold w-full truncate">{instruction.unique_name}</div>
                                                    <Badge className="px-1 rounded text-green-50">
                                                        {instruction?.model_type}
                                                    </Badge>
                                                </div>
                                            </div>
                                        </Button>
                                    )))}
                            </div>
                        </ScrollArea>
                        {instructionDetails && <DetailsCard instructionDetails={instructionDetails} />}
                    </div>
                </div>
                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                    <Button className="px-5 py-3 text-md" onClick={handleInstructionLoad} disabled={!selectedInstruction}>
                        Load Instruction
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}