import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css'; // Import PDF viewer styles
import { Button } from 'components/ui/button';
import { ZoomIn, ZoomOut, ArrowLeft, ArrowRight, Undo2 } from 'lucide-react';

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.2.67/pdf.worker.min.mjs";


const PDFViewer = ({ document, page, setPage }) => {
    const [pdfDoc, setPdfDoc] = useState(null);
    const [scale, setScale] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [iframeError, setIframeError] = useState(false);
    const canvasRef = useRef(null);

    // Load PDF document
    useEffect(() => {
        console.log("triggered")
        const loadPdf = async () => {
            try {
                const loadingTask = pdfjsLib.getDocument(document?.presigned_url || '');
                const pdf = await loadingTask.promise;
                setPdfDoc(pdf);
                setNumPages(pdf.numPages);
                console.log(page, pdf.numPages)
                if (page > pdf.numPages) {
                    console.log("resetting page")
                    setPage(1);
                    renderPage(pdf, 1, scale);
                } else if (page >= 1) {
                    console.log("rendering page")
                    setPage(page);
                    renderPage(pdf, page, scale);
                }
            } catch (error) {
                console.error('Error loading PDF:', error);
                setIframeError(true);
            }
        };

        loadPdf();
    }, [document?.presigned_url]);

    useEffect(() => {
        setPage(page);
        renderPage(pdfDoc, page, scale);
    }, [page]);

    // Render a specific page
    const renderPage = async (pdfDoc, pageNum, scale) => {
        try{
            const page = await pdfDoc.getPage(pageNum);
            const viewport = page.getViewport({ scale });
    
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
    
            // Set canvas dimensions to match the PDF page's original size
            canvas.height = viewport.height;
            canvas.width = viewport.width;
    
            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
    
            await page.render(renderContext).promise;
        } catch (error) {
            console.log(error)
        }
    };

    // Handle page change
    const handlePageChange = (newPage) => {
        if (newPage > numPages) {
            setPage(1);
            renderPage(pdfDoc, 1, scale);
        } else if (newPage >= 1) {
            setPage(newPage);
            renderPage(pdfDoc, newPage, scale);
        }
    };

    // Handle zoom
    const handleZoom = (newScale) => {
        setScale(newScale);
        renderPage(pdfDoc, page, newScale);
    };

    const resetZoom = () => {
        setScale(1);
        renderPage(pdfDoc, page, 1);
    };

    return (
        <div className="h-full flex flex-col w-full">
            <div className="w-full h-full flex flex-col">
                {/* Toolbar for page navigation and zoom */}
                <div className="z-10 bg-white p-2 top-0 left-0 right-0 flex justify-between items-center shadow-md h-12 select-none">
                    {/* Page navi */}
                    <div className='flex flex-row gap-2 items-center'>
                        <Button
                            onClick={() => handlePageChange(page - 1)}
                            disabled={page <= 1}
                            size="icon"
                            variant="ghost"
                        >
                            <ArrowLeft className='w-5 h-5'/>
                        </Button>
                        <span className="text-sm">
                            Page {page} of {numPages}
                        </span>
                        <Button
                            onClick={() => handlePageChange(page + 1)}
                            disabled={page >= numPages}
                            size="icon"
                            variant="ghost"
                        >
                            <ArrowRight className='w-5 h-5'/>
                        </Button>
                    </div>

                    {/* zoom */}
                    <div className='flex flex-row gap-2 items-center'>
                        <span className="text-sm">Zoom: {Math.round(scale * 100)}%</span>
                        <Button
                            onClick={() => handleZoom(scale - 0.2)}
                            disabled={scale <= 0.5}
                            size="icon"
                            variant="ghost"
                        >
                            <ZoomOut className='w-5 h-5'/>
                        </Button>
                        <Button
                            onClick={() => handleZoom(scale + 0.2)}
                            disabled={scale >= 2}
                            size="icon"
                            variant="ghost"
                        >
                            <ZoomIn className='w-5 h-5'/>
                        </Button>
                        <Button
                            onClick={resetZoom}
                            size="icon"
                            variant="ghost"
                        >
                            <Undo2 className='w-5 h-5'/>
                        </Button>
                    </div>
                </div>

                {/* Container for canvas with scroll */}
                <div className="w-full h-full overflow-auto bg-gray-300 py-12">
                    <canvas
                        ref={canvasRef}
                        className="border mx-auto my-auto shadow"
                    />
                </div>

                {/* Error message */}
                {iframeError && (
                    <div className=" inset-0 flex items-center justify-center bg-red-100 text-red-600">
                        Failed to load PDF. Please check the URL and try again.
                    </div>
                )}
            </div>
        </div>
    );
};

export default PDFViewer;