import { Label } from "components/ui/label";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import React, {useEffect, useState } from 'react'
import StepNonLLMAPI2 from './StepNonLLMAPI2';
import NextStep from '../NextStep';
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';
import DynamicInputSelector from '../DynamicInputSelector';
import InputValidation from 'components/InputValidation';

const StepNonLLMAPI = ({inputGroup, stepIndex, inputGroups, setInputGroups, functionRegistry, apis, nested}) => {
    const [selectedFunctionProps, setSelectedFunctionProps] = useState({});
    const output = Object.keys(inputGroup?.output)[0]

    useEffect(() => {
            if(inputGroup.registry_key === "RAG_API"){
                if(functionRegistry["RAG_API"]?.properties){
                    handleSorting(functionRegistry["RAG_API"]?.properties || {})
                }
            }
    }, [stepIndex, functionRegistry])

    const handleSorting = (obj) => {
        if(obj){
            const keyOrder = ["collection_id", "search_type", "use_rerank"];
    
            // Separate the fixed keys and the rest
            const sortedObj = Object.fromEntries(
                [...keyOrder, ...Object.keys(obj).filter(k => !keyOrder.includes(k))]
                .map(key => [key, obj[key]])
            );
    
            setSelectedFunctionProps(sortedObj || {});
        }
    }

    const handleInputChange = (field, value) => {
        console.log(field)
        const newInputGroups = [...inputGroups];
        if (field === "registry_key") {
            newInputGroups[stepIndex].registry_key = value;
            handleSorting(functionRegistry[value]?.properties || {});
        } else 
        if (field === "output") {
            console.log(value)
            newInputGroups[stepIndex].output = {[value.trim()]:"compiled_text"};
        } else if (selectedFunctionProps[field] === "fixed") {
            if (field === "top_k" || field === "top_n" || field === "offset") {
                newInputGroups[stepIndex].input[field] = parseInt(value) || 0;
            } else if (field === "use_rerank") {
                newInputGroups[stepIndex].input[field] = value === "true";
            } else {
                newInputGroups[stepIndex].input[field] = field === "collection_id"? value?._id : value;
            }
        } else if (selectedFunctionProps[field] === "dynamic") {
            newInputGroups[stepIndex].input[field] = value;
        }
        setInputGroups(newInputGroups);
    };

    // const handleOutputDisable = () => {
    //     if(Object.keys(inputGroup?.output)[0].trim() !== ""){
    //         return (
    //             // For LLM steps
    //             inputGroups.slice(stepIndex + 1).some(
    //                 nextGroup => (nextGroup.step_type === "LLM" || nextGroup.step_type === "LLM-Stream") && extractOutputValues(nextGroup.input).find(outp => outp === Object.keys(inputGroup?.output)[0])
    //             )
    //             ||
    //             // For Non-LLM steps
    //             inputGroups.slice(stepIndex + 1).some(
    //                 nextGroup => nextGroup.step_type === "Non-LLM" && extractOutputValues(nextGroup.input.query_text).find(outp => outp === Object.keys(inputGroup?.output)[0])
    //             )
    //             // ||
    //             // For Non-LLM steps
    //             // inputGroups.slice(index + 1).some(
    //             //     nextGroup => nextGroup.step_type === "Non-LLM - BeX Insights" && nextGroup.input?.includes(Object.keys(inputGroup?.output)[0])
    //             // )
    //         )
    //     }
      
    //     return false
    // }
    
    return (
        <div className={`grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 items-center gap-4 w-full`}>
           
            <div>
                <Label htmlFor={`functionName-${stepIndex}`}>API Name</Label>
                <Select
                    onValueChange={(value) => handleInputChange("registry_key", value)}
                    value={inputGroup?.registry_key || ""}
                >
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select an API" />
                    </SelectTrigger>
                    <SelectContent>
                        {apis.map((funcName) => (
                            <SelectItem key={funcName} value={funcName}>
                                {funcName}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <StepNonLLMAPI2
            inputGroup={inputGroup}
            stepIndex={stepIndex}
            handleInputChange={handleInputChange}
            inputGroups={inputGroups}
            selectedFunctionProps={selectedFunctionProps}
            />  
 
            {inputGroup?.registry_key && (
                <>
                    <DynamicInputSelector 
                    previousOutputs={handler.getPreviousOutputs(inputGroups, stepIndex)} 
                    onCombinedResultChange={(result) => handleInputChange("query_text", result) }
                    inputGroup={inputGroup}
                    inputText={inputGroup?.input.query_text}
                    inputGroups={inputGroups}
                    setInputGroups={setInputGroups}
                    stepIndex={stepIndex}
                    nested={nested}
                    />

                    <div >
                        <Label htmlFor={`output-${stepIndex}`}>Step Output key</Label>
                        <InputValidation
                            id={`output-${stepIndex}`}
                            value={ Object.keys(inputGroup?.output)[0]  || ""}
                            onChange={(e) => handleInputChange("output", e.target.value)}
                            className="bg-white"
                            validation={handler.validateOutputName}
                            disabled={handler.isOutputUsedInLaterSteps(inputGroups, output, stepIndex)}
                        />
                    </div>
                </>
            )}

            <div>
                <NextStep
                inputGroup={inputGroup}
                inputGroups={inputGroups}
                setInputGroups={setInputGroups}
                stepIndex={stepIndex}
                />
            </div>
        </div>   
    )
}

export default StepNonLLMAPI