import React, { useContext} from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/ui/dialog";
import { Context } from "context/GlobalState";
import { channelService } from "api";
import { WhatsAppChannelInput } from './WhatsAppSetup';


export default function WhatsAppUpdate({ isOpen, setOpenWhatsAppModal, channel, setEmailChannels }) {

    const { addNewNotifcation } = useContext(Context);

    const handleOnSubmit = async (channelObj) => {
        const channelObj1 = {
            organizationId: channelObj.organizationId,
            subOrganizationId: channelObj.subOrganizationId,
            channelId: channelObj._id,
            topic: channelObj.topic,
            address: channelObj.address,
            assistantId: channelObj?.assistantId === 'none' ? '' : channelObj?.assistantId,
        };

        try {
            const res = await channelService.updateChannel(channelObj1);
            console.log('res: ', res);
            setEmailChannels((prev) =>
                prev.map((item) => (item._id === channelObj._id ? { ...channelObj } : { ...item }))
            );
            addNewNotifcation('Channel Updated successfully', 'success');
            setOpenWhatsAppModal(false);
        } catch (error) {
            console.log('error: ', error);
            if (error.response?.status === 404) {
                addNewNotifcation('Channel not found', 'danger');
            } else {
                addNewNotifcation('Something went wrong', 'danger');
            }
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={setOpenWhatsAppModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Update WhatsApp Channel</DialogTitle>
                </DialogHeader>
                <WhatsAppChannelInput
                newChannelObj={channel}
                isUpdate={true}
                onSubmit={handleOnSubmit}
                setOpenEmailModal={setOpenWhatsAppModal}
                />
            </DialogContent>
        </Dialog>
    );
}
