import React from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
// import { Button } from "components/ui/button"
// import { X } from 'lucide-react'
import { Label } from 'components/ui/label'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler'

const StepIdSelector = ({ label, setInputGroups, isTrue, stepIndex, inputGroup, inputGroups, selectedStep }) => {

    const handleStepSelect = (stepId) => {
        if (stepId ) {
            const newInputGroup = {
                ...inputGroup, 
                next_step:{
                    ...inputGroup.next_step,
                    [isTrue? "true_branch" : "false_branch"]: [stepId]
                    }
            }
            console.log(newInputGroup)
            setInputGroups(inputGroups.map((group, i) => i === stepIndex? newInputGroup : group))
        }
    }


    return (
        <div className="">
            <Label className="block text-sm font-medium text-gray-700">{label}</Label>
            {/* {!isLastStep &&  */}
            <Select
                onValueChange={(value) => handleStepSelect(value)}
                value={selectedStep[0] || ""}
            >
                <SelectTrigger className="w-full bg-white">
                    <SelectValue placeholder="Select step" />
                </SelectTrigger>
                <SelectContent>
                    {handler.getNextSteps(inputGroups, stepIndex).map((item) => (
                        <SelectItem key={item.nextStep.label} value={item.nextStep.value}>
                            {item.nextStep.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}

export default StepIdSelector

