/* eslint-disable max-lines */
import React from 'react'
import InputGroup from './InputGroup'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Step } from 'components/NewDesignComponents/VerticalSteps'
import handler from './OpCodeBuilderHAndC/OpCodeBuilderHandler'

const InputGroups = ({inputGroupsState, otherProps, stepTypesState}) => {
    const {inputGroups, setInputGroups} = inputGroupsState
    const {stepTypes, setStepTypes} = stepTypesState
    const {constantStepTypes, opType, checkOpcodeName, userInput = "", orgId=0, subOrgId=0, parentId} = otherProps 

    const handleAddGroup = (position, currentStep) => {
        console.log(`Add step ${position} ${currentStep + 1}`)
        let newInputGroups = [...inputGroups];
        const newStepType = [...stepTypes];
        const currentIndex = Number(currentStep) 

        // Find the index of the current step  
        if (currentIndex === -1) {
            console.error("Current step not found");
            return;
        }

        const newInputGroup = {
            step_id: window?.crypto?.randomUUID(),
            step_type: `${opType === 'AutoOp'? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: " {{context['real_time_data']}}",  
            output: { Output: "", Output_map: {} },
            next_step: [],
            accumulate_output: true,
            is_first_step: false
        }
    
        if (position === 'before' || position === 'after') {
            const indexOffset = position === 'before' ? 0 : 1;
            const insertionIndex = currentIndex + indexOffset;

            if (position === 'before') {
                // Update the `next_step` of the new step to point to the current step
                newInputGroup.next_step = [newInputGroups[currentIndex].step_id];
                
                // Update the previous step's `next_step` to point to the new step, if applicable
                if (currentIndex > 0 && (currentIndex - 1 !== 0)) {
                    newInputGroups[currentIndex - 1].next_step = [newInputGroup.step_id];
                }
            } else if (position === 'after') {
                // Update the `next_step` of the new step to point to the next step of the current step
                newInputGroup.next_step = currentIndex !== 0? newInputGroups[currentIndex]?.next_step[0]? 
                [newInputGroups[currentIndex]?.next_step[0]] : [] : []
    
                // Update the `next_step` of the current step to point to the new step
                if(currentIndex !== 0 && newInputGroups[currentIndex]?.step_type !== "Condition"){
                    newInputGroups[currentIndex].next_step = [newInputGroup.step_id];
                }

                if(newInputGroups[currentIndex]?.step_type === "Condition"){
                    newInputGroups[currentIndex].next_step = {true_branch:[newInputGroup.step_id], false_branch:[newInputGroup.step_id]}
                }
            }

            newInputGroups.splice(insertionIndex, 0, newInputGroup);
            newStepType.splice(insertionIndex, 0, constantStepTypes[1].name);
        } else {
            console.error("Invalid position");
        }
    
        setInputGroups(newInputGroups);
        setStepTypes(newStepType)
    };

    const handleTypeSelect = (value, index) => {
        const newType = constantStepTypes.find(item => item.name === value).name

        setStepTypes(stepTypes.map((type,i) => i === index? newType : type))

        let newInputGroup = {}

        const currentInputGroup = inputGroups.find((_,i) => i === index)

        const inputText = currentInputGroup?.step_type === "Non-LLM"? (currentInputGroup?.input?.query_text || "") 
        : (currentInputGroup?.step_type=== "Condition"? "" : currentInputGroup?.input || "")

        const outputVar = currentInputGroup?.step_type === "Non-LLM"? (Object.keys(currentInputGroup?.output)[0] || "") 
        : (currentInputGroup?.output?.Output || "")
        
        const nextStepValue = currentInputGroup.step_type !== "Condition"? currentInputGroup?.next_step || [] 
        : currentInputGroup?.next_step?.true_branch || []

        const llmObj = {
            step_id: "",
            step_type: `${newType === "LLM"? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: inputText,  
            output: { Output: outputVar, Output_map: {} },
            next_step: nextStepValue,
            accumulate_output: true,
            is_first_step: false
        }

        if (newType === "LLM") {
            newInputGroup = llmObj
        } else if (newType === "LLM-Stream") {
            newInputGroup = llmObj
        } else if (newType === "Non-LLM") {
            newInputGroup = {
                step_id: "",
                step_type: "Non-LLM",
                registry_key: "",
                input: {
                    "org_id": orgId,
                    "sub_org_id": subOrgId,
                    "collection_id": "",
                    "use_rerank": true,
                    "query_text": inputText,  
                    "top_k": 10,
                    "top_n": 3,
                    "offset": 0,
                    "filters": {},
                    "search_type": "vector",
                },
                output: { [outputVar]: "compiled_text" },
                next_step: nextStepValue,
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Condition"){
            newInputGroup = {
                step_id: "",
                step_type: "Condition",
                input: "",
                output: { Output: outputVar },
                next_step: {
                    true_branch: nextStepValue,
                    false_branch: nextStepValue
                },
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Loop"){
            newInputGroup = {
                step_id: "",
                step_type: "Loop",
                input: {
                    collection: "",
                    item: "loopItem",
                    max_iterations: "",
                    parallel:true,
                    // delimiters: [""],
                    // break_condition: "",
                    steps: [
                        {
                            step_id: window?.crypto?.randomUUID(),
                            step_type: `${opType === 'AutoOp'? "LLM" : "LLM-Stream"}`,
                            unique_name: "",
                            model_type: "",
                            input: " {{context['real_time_data']}}",  
                            output: { Output: "", Output_map: {} },
                            next_step: [],
                            accumulate_output: true,
                            is_first_step: true
                        }
                    ],
                },
               output: { Output: outputVar},
               next_step: nextStepValue,
               accumulate_output: true,
               is_first_step: false
            }
        }

        const newInputGroups = inputGroups.map((inputGroup,i) => i === index? {...newInputGroup, step_id:inputGroup.step_id} : inputGroup)
        setInputGroups(newInputGroups)
    }
    
    const handleRemoveGroup = (id) => {
        console.log(`Delete step ${id + 1}`, stepTypes)
        const inputGroupDeleted = inputGroups?.find((_, index) => index === id)
        const outputText = inputGroupDeleted?.output?.Output || Object.keys(inputGroupDeleted?.output)[0] || ""
        const nextStepValue =  inputGroupDeleted?.step_type === 'Condition'? inputGroupDeleted?.next_step?.true_branch || [] : inputGroupDeleted?.next_step || []


        const updatedInputGroups = inputGroups?.map(group =>{
            if(group?.step_type !== "Condition" ){
                if(group.step_type === 'Identification' || group.step_type === 'Loop'){
                    return group
                }

                let newGroup = {...group}

                 if (group?.next_step[0] === inputGroupDeleted?.step_id) {
                        newGroup = { ...group, next_step: nextStepValue };
                }

               
                if(group.step_type === "Non-LLM" && outputText){
                    if(group.input.query_text.includes(outputText)){
                        newGroup = { ...newGroup, input:{...newGroup.input, query_text: handler.removeDeletedOutput(group.input.query_text || "", outputText)}}
                    }
                } else if(outputText) {
                    if(group.input.includes(outputText)){
                        newGroup = { ...newGroup, input: handler.removeDeletedOutput(group.input || "", outputText)} 
                    }

                    if(group?.output?.Output_map && Object.keys(group?.output?.Output_map).length > 0 && outputText in group.output.Output_map){
                        delete newGroup.output.Output_map[outputText]
                    }
                }

                return newGroup
            }

            if(group?.step_type === "Condition"){
                let true_branch = group?.next_step?.true_branch
                let false_branch = group?.next_step?.false_branch

                if (group?.next_step?.true_branch[0] === inputGroupDeleted?.step_id) {
                    true_branch =  nextStepValue 
                }

                if (group?.next_step?.false_branch[0] === inputGroupDeleted?.step_id) {
                    false_branch =  nextStepValue 
                }

                return {...group, next_step:{true_branch, false_branch}}
            }

            return group
        } )

        let newInputGroups = updatedInputGroups.filter((_, index) => index !== id)

        setInputGroups(newInputGroups)

        setStepTypes(stepTypes.filter((_, index) => index !== id))
    }

    return (
        <div className='space-y-2'>
            {inputGroups?.map((inputGroup, index) => (
                <Step
                indicator={`${index + 1}`}
                stepIndex={index}
                canAddBefore={index === 0 ? false : true}
                canAddAfter={index !== 0? true : inputGroups.length === 1? true : false}
                isDraggable={false }
                canDelete={index === 0 ? false : true}
                isDisabled={checkOpcodeName()}
                onAddStep={handleAddGroup}
                onDeleteStep={handleRemoveGroup}
                key={inputGroup?.step_id || index}
                title={`Opcode Step ${index + 1}`}
                itemId={parentId}
                step_id={inputGroup.step_id}
                >
                    <div key={inputGroup?.step_id || index} className="flex flex-col gap-2 w-full">
                        <Select onValueChange={(value) => handleTypeSelect(value, index)} 
                        id="step_type" 
                        className="w-full" value={stepTypes[index]}
                        disabled={index === 0}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select step type" />
                            </SelectTrigger>
                            <SelectContent>
                                {index === 0 ? (
                                    <SelectItem key="0" value="Identification">
                                        Identification
                                    </SelectItem>
                                    ) : (
                                    constantStepTypes.slice(1).map((item) => (
                                        <SelectItem key={item.id} value={item.name}>
                                            {item.name}
                                        </SelectItem>
                                    ))
                                )}
                            </SelectContent>
                        </Select>
                        <div className="flex flex-col gap-4">
                            <InputGroup 
                                stepIndex={index}
                                inputGroup={inputGroup} 
                                inputGroups={inputGroups}
                                setInputGroups={setInputGroups}
                                userInput={userInput}
                            />
                            <hr/>
                        </div>
                    </div>
                </Step>
                ))}
        </div>
    )
}
export default InputGroups