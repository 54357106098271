import React, { useContext, useEffect, useState } from 'react'
import { assistantInstanceService, opcodeAssistantService } from 'api'
import { useParams } from 'react-router-dom'
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import AssistantForm from './AssistantForm';
import { Context } from 'context/GlobalState';
import { AppLoader } from 'components/LoaderSpinner';



const ManageAssistantDialog = ({ show, setShow, selectedCard, setAssistantInstances }) => {

    const initialData = {
        title: '',
        description: '',
        opcodes: [],
        default_opcode: '',
    }

    const [formData, setFormData] = useState(initialData)

    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading ] = useState(false)
    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()


    useEffect(() => {
        setLoading(true)
        if (show) {
            opcodeAssistantService.getOpcodeAssistant(oragID, subOragID, selectedCard._id)
            .then((res) => {
                const { opcodes, default_opcode }  = res.data
                setFormData({
                    ...selectedCard,
                    opcodes,
                    default_opcode
                })
            }).catch((error) => {
                console.log('error: ', error);
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [show])

    const handleUpdateAssistant = async () => {

        if (!formData.title || formData.opcodes.length <= 0 || !formData.default_opcode) {
            return addNewNotifcation('Please fill in all required fields', 'warning')
        }

        setBtnLoading(true)
        const updateObj = {
            id: selectedCard._id,
            organizationId: oragID,
            subOrganizationId: subOragID,
            title: formData.title,
            description: formData.description
        }

        const opCodeObj = {
            "org_id": oragID,
            "sub_org_id": subOragID,
            "assistant_name": selectedCard._id,
            "opcodes": formData.opcodes,
            "default_opcode": formData.default_opcode
        }

        try {
            const res = await assistantInstanceService.updateAssistantInstance(updateObj)
            await opcodeAssistantService.updateOpcodeAssistant(opCodeObj)
            console.log('res: ', res);
            setAssistantInstances(prev => prev.map(item => item._id === selectedCard._id ? {...formData} : {...item}))
            addNewNotifcation('assistant info updated successfully', 'success')
            setShow(false)
        } catch(error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-lg bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Update assistant</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Update assistant information.</DialogDescription>
                </DialogHeader>

                {loading ? <AppLoader /> : (
                    <div>
                        <AssistantForm form={formData} setForm={setFormData} />
                    </div>
                )}

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant='outline' onClick={() => setShow(false)}>Cancel</Button>
                    <Button disabled={btnLoading} onClick={handleUpdateAssistant}>{btnLoading ? 'Updating assistant...' : 'Update assistant'}</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ManageAssistantDialog