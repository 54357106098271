import { NestedPageLayout } from "components/NewDesignComponents/PageLayout"
import Breadcrumb from "components/Breadcrumb"
import React, { useContext, useEffect, useState } from "react"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { Label } from "components/ui/label"
import { Context } from "context/GlobalState"
import { Bot, Clock, Settings2, User } from "lucide-react"
import { instructionService } from "api/services/BEX/instruction.service"
import { useParams } from "react-router-dom"
import { getOrganizationMember } from "utils/organizationUtils"
import { useNavigate } from "react-router-dom"
import { AppLoader } from 'components/LoaderSpinner'
import { OrganizationContext } from "context/OrganizationContext"

export default function InstructionDetails() {
    
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [createdByName, setCreatedByName] = useState(false)
    const [modifiedByName, setModifiedByName] = useState(false)
    const [instructionDetails, setInstructionDetails] = useState({})
    const [linkedOpcodes, setLinkedOpcodes] = useState([])

    const { oragID, subOragID } = useParams()
    const { modelType, uniqueName } = useParams()
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    
    const instruction = uniqueName

    const initializeInstruction = async () => {
        console.log("Retrieving instruction details for ", instruction)
        try {
            setLoadingDetails(true)
            const result = await instructionService.getModel(
                oragID,
                subOragID,
                modelType,
                uniqueName,
            )
            console.log(result)
            setInstructionDetails(result.data)
        } catch (error) {
            console.error("Error getting opcode details:", error)
            addNewNotifcation("Failed to retrieve instruction details. Please try again.", "danger")
        } finally {
            setLoadingDetails(false)
        }
    }

    const initializeOpcodeInstruction = async () => {
        console.log("Retrieving opcode details")
        try {
            setLoadingDetails(true)
            const result = await instructionService.getLinkedOpcodes(
                oragID,
                subOragID,
                modelType,
                uniqueName,
            )
            console.log(result)
            setLinkedOpcodes(result.data)
        } catch (error) {
            console.error("Error getting opcode details:", error)
            addNewNotifcation("Failed to retrieve linked opcodes. Please try again.", "danger")
        } finally {
            setLoadingDetails(false)
        }
    }

    useEffect(() => {
        initializeInstruction()
        initializeOpcodeInstruction()
    }, [instruction])

    const getMembersNames = async () => {
        try {

            const createByPromise = getOrganizationMember(instructionDetails.user_id, selectedOrganization)
            const modifiedByPromise = getOrganizationMember(instructionDetails.modified_by, selectedOrganization)
            const [createBy, modifiedBy]  = await Promise.all([createByPromise, modifiedByPromise])

            setCreatedByName(createBy)
            setModifiedByName(modifiedBy)
        } catch(error) {
            console.log("error: ", error)
        }
    }

    useEffect(() => {
        getMembersNames()
    }, [instructionDetails.user_id])


    return (
        <NestedPageLayout>
            <div className="my-3 px-2">
                <Breadcrumb maxPaths={3} />
            </div>
            <div>
                {loadingDetails ? ( 
                    <div className='flex flex-grow flex-col justify-center items-center'><AppLoader size={50} /> </div> 
                ) : (
                    <div>
                        <Card className="w-full my-2">
                            <CardHeader>
                                <div className="flex items-start justify-between">
                                    <div className="space-y-1">
                                        <CardTitle className="text-xl whitespace-pre-wrap break-all">{instructionDetails.unique_name}</CardTitle>
                                        <CardDescription className="text-xs">Instruction</CardDescription>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent className="space-y-2.5">
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-xs">
                                        <User className="h-4 w-4" />
                                        <Label className="font-medium text-xs">Created By:</Label>
                                        <span className="text-muted-foreground">{createdByName || "-"}</span>
                                    </div>
                                    <div className="flex items-center gap-2 text-xs">
                                        <Clock className="h-4 w-4" />
                                        <Label className="font-medium text-xs">Modified By:</Label>
                                        <span className="text-muted-foreground">{modifiedByName || "-"}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="space-y-2">
                                    <div className="">
                                        <Label className="text-xs font-medium">Description</Label>
                                        <p className="text-xs text-muted-foreground">{instructionDetails.modelDescription || "-"}</p>
                                    </div>
                                    <div className="space-y">
                                        <Label className="text-xs font-medium">Prompt</Label>
                                        <p className="text-xs text-muted-foreground whitespace-pre-wrap break-all">
                                            {instructionDetails.prompt || "-"}
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                        <Bot className="h-4 w-4" />
                                        <Label className="text-xs font-medium">Model ID</Label>
                                    </div>
                                    <code className="rounded bg-muted px-2 py-1 text-xs whitespace-pre-wrap break-all">
                                        {instructionDetails.modelId}
                                    </code>
                                </div>
                                <hr />
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                        <Settings2 className="h-4 w-4" />
                                        <Label className="text-xs font-medium">Parameters</Label>
                                    </div>
                                    <div className="grid gap-4 sm:grid-cols-2">
                                        {instructionDetails?.parameters &&
                                            Object.entries(instructionDetails.parameters)
                                            .filter(
                                                ([key]) =>
                                                key !== "n" && key !== "stop" && key !== "stream" && key !== "seed" && key !== "response_format",
                                            )
                                            .map(([key, value]) => (
                                                <div className="" key={key}>
                                                    <Label className="text-xs font-medium">{formatToTitleCase(key)}</Label>
                                                    <p className="text-sm text-muted-foreground">{value}</p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <ListOfOpCodes
                            linkedOpcodes={linkedOpcodes.opcodes}
                        />
                    </div>
                )}
            </div>
        </NestedPageLayout>
    )
}

function formatToTitleCase(str) {
  return str
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Convert to Title Case
}

const ListOfOpCodes = ({ linkedOpcodes }) => {
    const navigate = useNavigate()

    const handleNavigate = (opcode) => {
        console.log("Actions: ", opcode)
        navigate(`../../../op-codes/${opcode}/opCode`), {replace: true}
    }

    return (
        <div className="flex w-full mt-4 flex-col">
            <Card>
                <CardHeader>
                    <div className="flex w-full justify-between">
                        <CardTitle> List of Linked OpCodes </CardTitle>
                    </div>
                </CardHeader>

                <CardContent>
                    <div>
                        {linkedOpcodes && linkedOpcodes.length > 0 ? (
                            <ul>
                                {linkedOpcodes.map((opcode, index) => (
                                    <li key={index}>
                                        <span
                                        onClick={() => {
                                            console.log(opcode)
                                            handleNavigate(opcode)
                                        }}
                                        className="text-xs cursor-pointer"
                                        >
                                            {opcode}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <span className="text-xs">No linked opcodes</span>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

