import React, { useEffect, useState } from 'react'
import StepNonLLMAPI from './StepNonLLMAPI'
// import { Label } from 'components/ui/label'
import { opCodeService } from 'api/services/PROJECT-O/OpCodeBuilder.service';
// import StepNonLLMBeXInsights from './StepNonLLMBeXInsights'
// import { OpCodeBuilderContext } from '../../OpCodeBuilderHAndC/OpCodeBuilderContext'
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'

const StepNonLLM = ({stepIndex, inputGroup, inputGroups, setInputGroups, nested}) => {
    const [functionRegistry, setFunctionRegistry] = useState({});
    const [apis, setApis] = useState([]);

    useEffect(() => {
        // Fetch the list of functions and their properties from FUNCTION_REGISTRY
        const fetchFunctions = async () => {
            try {
                const res = await opCodeService.getFunctionRegistry();
                if (res.data && typeof res.data === 'object') {
                    console.log(res.data)
                    setFunctionRegistry(res.data);
                    setApis(Object.keys(res.data).filter(key => key === "RAG_API"));
                }
            } catch (err) {
                console.error("Error fetching functions:", err);
            }
        };

        fetchFunctions();
    }, []);
    
    // const {orgId, subOrgId} = useContext(OpCodeBuilderContext)

    // const handleSelectNonLLM = (value) => {
    //     let newInputGroup
    //     if(value === 'api'){
    //         newInputGroup = {
    //             step_id: "",
    //             step_type: "Non-LLM",
    //             unique_name: null,
    //             model_type: null,
    //             registry_type: "api",
    //             registry_key: "",
    //             fixed_inputs: {
    //                 "org_id": orgId,
    //                 "sub_org_id": subOrgId,
    //                 "collection_id": "",
    //                 "use_rerank": true,
    //                 "top_k": 10,
    //                 "top_n": 3,
    //                 "offset": 0,
    //                 "filters": {}
    //             },
    //             dynamic_inputs: {
    //                 "query_text": (nested? index === 0? "real_time_data" : "" : "")
    //             },
    //             output: { Output: "", Output_map: null },
    //             steps:null,
    //             next_step: {branch_name:[]},
    //             accumulate_output: true,
    //             is_first_step: false
    //         }
    //     }
    //     else if (value === "function") {
    //             newInputGroup = {
    //                 step_id: "",
    //                 step_type: "Non-LLM - BeX Insights",
    //                 unique_name: null,
    //                 model_type: null,
    //                 registry_type: "function",
    //                 registry_key: "",
    //                 fixed_inputs: {},
    //                 dynamic_inputs: (nested? index === 0? ["real_time_data"] : [] : []),
    //                 output: { Output: "", Output_map: null },
    //                 steps:null,
    //                 next_step: {branch_name:[]},
    //                 accumulate_output: true,
    //                 is_first_step: false
    //             }
    //     } 
    //     const newInputGroups = inputGroups.map((inputGroup,i) => i === index? {...newInputGroup, step_id:inputGroup.step_id} : inputGroup)
    //     setInputGroups(newInputGroups)
    // }

    return (
        <div className="flex flex-col gap-2 w-full">
            {/* <Select onValueChange={(value) => handleSelectNonLLM(value)} 
            id="step_type" 
            className="w-full" value={inputGroup.registry_type}
            >
                <SelectTrigger className="bg-white">
                    <SelectValue placeholder="Select Non-LLM type" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="function">BeX Insights</SelectItem>
                    <SelectItem value="api">RAG API</SelectItem>
                </SelectContent>
            </Select> */}
            <StepNonLLMAPI 
            inputGroup={inputGroup} 
            stepIndex={stepIndex} 
            inputGroups={inputGroups} 
            setInputGroups={setInputGroups}
            functionRegistry={functionRegistry}
            apis={apis}
            nested={nested}
            />
            {/* {inputGroup?.registry_type === "api" ? (
                <StepNonLLMAPI 
                inputGroup={inputGroup} 
                index={index} 
                inputGroups={inputGroups} 
                setInputGroups={setInputGroups}
                getPreviousOutputs={getPreviousOutputs}
                getNextSteps={getNextSteps}
                />
                )
            : inputGroup?.registry_type === "function" ? (
                <StepNonLLMBeXInsights 
                inputGroup={inputGroup} 
                index={index} 
                inputGroups={inputGroups} 
                setInputGroups={setInputGroups} 
                getPreviousOutputs={getPreviousOutputs}
                getNextSteps={getNextSteps}
                />
            ) : "" } */}
        </div>
        
    )
}

export default StepNonLLM