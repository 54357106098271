import { AiTeacherData } from "../initialData";

export const AiTeacherReducer = (state, action) => {
  switch (action.type) {
    case "SET_OPCODES":
      return {
        ...state,
        aiTeacherOpcodes: action.value,
      };
    case "ADD_MESSAGE":
      return {
        ...state,
        aiTeacherMessages: [
          ...state.aiTeacherMessages,
          action.value,
        ],
      };
    case "CHANGE_MODE":
      return {
        ...state,
        mode: action.value,
      };
    case "SET_FILE_SYSTEM":
      return {
        ...state,
        fileSystem: action.value,
      };
    case "RESET_FILE_SYSTEM":
      return {
        ...state,
        fileSystem: AiTeacherData.fileSystem,
      };
    case "SET_DOCUMENT":
      return {
        ...state,
        selectedDocument: action.value,
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.value,
      };
    case "RESET_MESSAGES":
      return { 
        ...state, 
        aiTeacherMessages: AiTeacherData.aiTeacherMessages
      };
    case "UPDATE_RAG_FIELD":
      return { 
        ...state, 
        ragForm: {
          ...state.ragForm,
          [action.field]: action.value
        }
      };
    case "SET_LOADING":
      return { 
        ...state, 
        isAiTeacherMessageLoading: action.value,
      };

    default:
      return state;
  }
};
