/* eslint-disable max-lines */
import { operatorOptions } from "./OpCodeBuilderContext";

class OpCodeBuilderHandler {
    // Validate values (should not be empty and follow variable naming rules)
    validateOpCodeName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Workflow ID field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Workflow ID cannot contain spaces.` };
        }
        if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
            return { isValid: false, message: `Workflow ID can only contain letters, numbers, and underscores, and must not start with a number.` };
        }
        if (value.length < 4){
            return { isValid: false, message: `Workflow ID should be at least 4 characters long.` };
        }
        return { isValid: true, message: `Valid Workflow ID.` };
    }

    validateOutputName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Step Output field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Step Output cannot contain spaces.` };
        }
        if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
            return { isValid: false, message: `Step Output can only contain letters, numbers, and underscores, and must not start with a number.` };
        }
        if (value.length < 4){
            return { isValid: false, message: `Step Output should be at least 4 characters long.` };
        }
        
        return { isValid: true, message: `Valid Output name.` };
    }

    validateGlobalVariableName(value) {
        // value = value.trim()
        // if (!value) {
        //     return { isValid: false, message: `Field cannot be empty.` };
        // }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Global Variable Name should be at least 4 characters long.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Global Variable Name cannot contain spaces.` };
        }
        return { isValid: true, message: `Valid value.` };
    }
    
    validateVariableName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Variable Name field cannot be empty.` };
        }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Variable Name should be at least 4 characters long.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Variable Name cannot contain spaces.` };
        }
        return { isValid: true, message: `Valid value.` };
    }

    validateInputName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Step Input field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Step Input cannot contain spaces.` };
        }
        if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
            return { isValid: false, message: `Step Input can only contain letters, numbers, and underscores, and must not start with a number.` };
        }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Step Input should be at least 4 characters long.` };
        }
        return { isValid: true, message: `Step Input valid.` };
    }

    extractOutputValues =(text) => {
        const regex = /output\[['"]([a-zA-Z0-9_]+)['"]\]/g;
        let matches;
        const result = [];
        
        while ((matches = regex.exec(text)) !== null) {
            const value = matches[1];
            if (!result.includes(value)) {
            result.push(value);
            }
        }
        
        return result;
    }

    isOutputUsedInLaterSteps = (inputGroups, output, stepIndex) => {
        if(output !== "") {
            return inputGroups.slice(stepIndex + 1).some((group) => {
            if (group.step_type === "LLM" || group.step_type === "LLM-Stream") {
                return this.extractOutputValues(group.input).find(outp => outp === output)
            }
            if (group.step_type === "Non-LLM") {
                return this.extractOutputValues(group.input.query_text).find(outp => outp === output)
            }

            if (group.step_type === "Loop") {
                if(group?.input?.break_condition){
                    return this.extractOutputValues(group?.input?.break_condition).find(outp => outp === output)
                } 

                return false
            }

            return false
            })
        }
    }

    getPreviousOutputs = (inputGroups, currentIndex) => {
        if (currentIndex <= 0) return [];
        
        return inputGroups
            .slice(0, currentIndex)
            .map((group, i) => ({
                stepIndex:i,
                stepId:group.step_id,
                prevOutp:{
                value: group.step_type === "Non-LLM"? Object.keys(group?.output)[0] : (group?.output?.Output || ""),
                label: `Step ${i + 1} Output: ${group.step_type === "Non-LLM"? Object.keys(group?.output)[0] : group?.output?.Output}`,
                }
            }))
            .filter((item) => item.prevOutp.value.trim() !== "");
    };

    getNestedPreviousOutputs = (inputGroups, indicators) => {
        // if (currentIndex <= 0 && !nested) return [];
        
        return inputGroups
            // .slice(0, currentIndex)
            .map((group, i) => ({
                stepIndex:i,
                stepId:group.step_id,
                prevOutp:{
                value: group.step_type === "Non-LLM"? Object.keys(group?.output)[0] : (group?.output?.Output || ""),
                label: `Step ${this.getIndicatorNumber(group.step_id, indicators) || i + 1} Output: ${group.step_type === "Non-LLM"? 
                    Object.keys(group?.output)[0] : group?.output?.Output}`,
                }
            }))
            .filter((item) => item.prevOutp.value.trim() !== "");
    };

    getNextSteps = (inputGroups, currentIndex) => {
        // .slice(currentIndex + 1)
        return inputGroups
            .filter((group, index) => index !== currentIndex &&  group.step_type !== "Identification")
            .map((group) => ({
                stepIndex: (inputGroups.findIndex(g => g.step_id ===  group.step_id) + 1 || ""),
                nextStep:{
                value: group.step_id,
                // label: `Step ${i + currentIndex + 1}`,
                label: `Step ${(inputGroups.findIndex(g => g.step_id ===  group.step_id) + 1 || "")}`,
                }
        }))
            
    };

    removeSpecificContextFromOtherSteps = (setInputGroups, inputGroups, contextToRemove) => {
        setInputGroups(inputGroups.map(step => {
            let inputText;
            let loopBreakCondition
            if (step.step_type === "Non-LLM") {
                inputText = step.input.query_text;
            } else if (step.step_type === "Loop") {
                // inputText= typeof step.input.collection === "string"? step.input.collection : ""
                if(step?.input?.break_condition ){
                    loopBreakCondition = step?.input?.break_condition || ""
                }
            } else {
                inputText = step.input;
            }
        
            if (inputText) {
                const regex = new RegExp(`\\{\\{context\\['${contextToRemove}'\\]\\}}`, 'g');
                const updatedText = inputText.replace(regex, '');
        
                if (step.step_type === "Non-LLM") {
                    step.input.query_text = updatedText;
                }
                //  else if (step.step_type === "Loop") {
                //     step.input.collection = updatedText;
                // } 
                else {
                    step.input = updatedText;
                }
            }

            if(loopBreakCondition){
                const regex = new RegExp(`\\{\\{context\\['${contextToRemove}'\\]\\}}`, 'g');
                const updatedText = inputText.replace(regex, '');
                step.input.break_condition = updatedText;
            }
        
            return step;
        }))
    }

    isConditionIncomplete = (condition) => {
        const parts = condition.split(/\s+/).filter(Boolean)
        
        if (parts.length < 3) {
          return true
        }
      
        const [left, operator, right] = parts
        const validOperators = operatorOptions.map(op => op.value)
      
        if (!left || !operator || !right) {
          return true
        }
      
        if (!validOperators.includes(operator)) {
          return true
        }
      
        return false
    }

    parseCondition = (condition) => {
        const operatorOptions = [">", "<", "==", ">=", "<=", "!=", "in", "not in", "is", "is not"];
        
        if(typeof condition === "string"){
            for (const operator of operatorOptions) {
                const parts = condition?.split(operator) || [];
                if (parts.length > 0) {
                    const left = parts[0]?.trim() || undefined;
                    const right = parts[1]?.trim() || undefined;
                    return { left, operator:operator || undefined, right };
                }
            }
        }
        
        return { left: undefined, operator: typeof condition === "string"? condition?.trim() : "", right: undefined };
    }

    checkStepDependency(stepId, steps, nested) {
        try{
            const visited = new Set();
            const validSteps = new Set();
        
            const getNextSteps = (step) => {
                if (step.step_type === "Condition") {
                    return [
                        ...(step.next_step.true_branch || []),
                        ...(step.next_step.false_branch || [])
                    ];
                } else {
                    return step.next_step || [];
                }
            }
        
            const markReachableSteps = (currentStepId) => {
                if (visited.has(currentStepId)) return;
                visited.add(currentStepId);
                validSteps.add(currentStepId);
        
                const currentStep = steps.find(step => step.step_id === currentStepId);
                if (!currentStep) return;
        
                const nextSteps = getNextSteps(currentStep);
                for (let nextStepId of nextSteps) {
                    markReachableSteps(nextStepId);
                }
            }
        
            // Mark steps reachable from the first two steps
            if(nested){
                steps.slice(0, 1).forEach(step => {
                    markReachableSteps(step.step_id)
                });

            } else {
                steps.slice(0, 2).forEach(step => {
                    validSteps.add(step.step_id);
                    const nextSteps = getNextSteps(step);
                    nextSteps.forEach(markReachableSteps);
                });
            }
    
            // Check if the given stepId is in the set of valid steps
            return validSteps.has(stepId);
        } catch (error) {
            console.log(error)
        }
    }

    formatToTitleCase(str) {
        return str
            .replace(/_/g, ' ')           // Replace underscores with spaces
            .replace(/\w\S*/g, function(word) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });                           // Convert to Title Case
    }

    removeDeletedOutput = (text, inputText) => {
        const regex = /\{\{(.*?)\}\}/g; // Match anything inside double curly braces
        const placeholders = new Set();
        const inputTrimmed = inputText.trim();
      
        let output = text.replace(regex, (match, p1) => {
          const placeholder = p1.trim();
      
          // Check if the current placeholder matches inputText
          if (placeholder === `output['${inputTrimmed}']`) {
            return ''; // Remove this placeholder
          }
      
          if (placeholders.has(placeholder)) {
            return ''; // Remove duplicates
          }
      
          placeholders.add(placeholder);
          return match; // Keep the original match if unique
        });
      
        return output.replace(/\s+/g, ' ').trim(); // Remove extra spaces
    };

    generateStepNumbers(steps, prefix = "") {
        let result = [];
        
        steps.forEach((step, index) => {
            let stepNumber = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
            result.push({ step_id: step.step_id, step_type: step.step_type, step_number: stepNumber });
    
            if (step.step_type === "Loop" && step.input.steps) {
                result.push(...this.generateStepNumbers(step.input.steps, stepNumber));
            }
        });
    
        return result;
    }
    
    getIndicatorNumber = (stepId, indicators) => {
        const step = indicators.find(indicator => indicator.step_id === stepId)
        return step?.step_number
    }
}

// Example usage
const handler = new OpCodeBuilderHandler();
export default handler;
