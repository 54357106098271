import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout'
import Breadcrumb from 'components/Breadcrumb'
import React, { useContext, useEffect, useState } from 'react'
import { channelService } from 'api'
import { useParams } from 'react-router-dom'
import withLoader from 'layout/withLoader'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { OrganizationContext } from 'context/OrganizationContext'
import { getOrganizationMember } from 'utils/organizationUtils'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Separator } from "components/ui/separator"
import {  Palette, Type, Maximize2 } from "lucide-react"
import UpdateWebChannelWrapper from '../WebChatUpdate'



export default function ChannelDetails() {

    const { oragID, subOragID, channelId } = useParams()

    const fetchAssistant = async () => {
        const response = await channelService.getChannelById(oragID, subOragID, channelId)
        return response.data
    }

    // using withLoader component to dynamically render the component with loader and error display (test mode)
    const MyComponentWithLoader = withLoader(ChannelDetailsData, fetchAssistant);

    return (
        <NestedPageLayout>
            <div className='my-3'>
                <Breadcrumb maxPaths={2} />
            </div>

            <MyComponentWithLoader />
        </NestedPageLayout>
    )

}

const ChannelDetailsData = ({ data }) => {

    const [channelDetails, setChannelDetails] = useState(data)
    const [createdByName, setCreatedByName] = useState('-')

    const { selectedOrganization } = useContext(OrganizationContext)
    
    useEffect(() => {
        getOrganizationMember(channelDetails.createdBy, selectedOrganization)
        .then((name) => {
            setCreatedByName(name)
        }).catch((error) => {
            console.log('error: ', error);
        })
    }, [])
    
    return (
        <div>
            
            <Card className='flex-grow'>
                <CardHeader>
                    <CardTitle className="flex justify-between items-center">
                        <span>Channel Information</span>
                        <Badge>{channelDetails.type}</Badge>
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-col gap-2'>
                            <Label className='text-gray-500'> Address </Label>
                            <span className='font-[500]'>{channelDetails.address}</span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <Label className='text-gray-500'> Topic </Label>
                            <span className='font-[500]'>{channelDetails.topic}</span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <Label className='text-gray-500'> Created </Label>
                            <span className='font-[500]'>{new Date(channelDetails.created).toDateString()}</span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <Label className='text-gray-500'> Created by </Label>
                            <span className='font-[500]'>{createdByName || '-'}</span>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* web chat channel config */}
            {channelDetails.type === "Webchat" && (
                <div className='mt-4'>
                    <WebChatConfig channelDetails={channelDetails} setChannelDetails={setChannelDetails} config={channelDetails.webChatConfig || {}} />
                </div>
            )}
        </div>
    )

}

const WebChatConfig = ({ config, channelDetails, setChannelDetails }) => {
    
    const [updateDialog, setUpdateDialog] = useState(false)
    const [dialogType, setDialogType] = useState('')

    const handleOpenDialog = (type) => {
        setUpdateDialog(true)
        setDialogType(type)
    }

    return (
        <Card className="w-full">
            <CardHeader>
                <CardTitle>Web chat Configuration</CardTitle>
            </CardHeader>

            <CardContent>
                <div className="space-y-6">
                    <ConfigSection
                        icon={<Type className="w-5 h-5" />}
                        title="Appearance & Launcher Configuration"
                        content={
                            <div className='flex flex-col gap-6'>
                                <div className='space-y-2'>
                                    <ColorDisplay label="Accent" color={config.accentColor} />
                                    <ColorDisplay label="Text Accent Color" color={config.textAccentColor} />
                                </div>
                                <div className='space-y-2'>
                                    <span className='flex items-center gap-2'> <Maximize2 className="w-3 h-3" /> Dimensions </span>

                                    <ConfigItem
                                    label="Greeting Message Dimensions"
                                    value={`${config.greetingMessageWidth} x ${config.greetingMessageHeight}`}
                                    />
                                </div>

                                <div className='mt-4'>
                                    <div className='flex flex-col items-end w-full'>
                                        <Button onClick={() => handleOpenDialog('appearance-launcher')}>
                                            Manage appearance & launcher
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                    />

                    <Separator />

                    <ConfigSection
                        icon={<Palette className="w-5 h-5" />}
                        title="Home Screen Configuration"
                        content={
                            <div className="flex flex-col gap-6">
                                <div>
                                    <ConfigItem label="Assistant Name" value={config.assistantName} />
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div>
                                        <h4 className="font-semibold mb-2">Primary color (Chat & Header)</h4>
                                        <div className="space-y-2">
                                            <ColorDisplay label="Primary" color={config.primaryColor} />
                                            <ColorDisplay label="Secondary" color={config.secondaryColor} />
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="font-semibold mb-2">Text color (Chat & Header)</h4>
                                        <div className="space-y-2">
                                            <ColorDisplay label="Text Primary" color={config.textPrimaryColor} />
                                            <ColorDisplay label="Text Secondary" color={config.textSecondaryColor} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <span className='flex items-center gap-2'> <Maximize2 className="w-3 h-3" /> Size </span>
                                    <div className="space-y-2">
                                        <ConfigItem label="Chat Dimensions" value={`${config.greetingMessageWidth} x ${config.greetingMessageHeight}`} />
                                    </div>
                                </div>

                                <div>
                                    <ConfigItem label="Greeting message" value={config.greetingMessage} />
                                    <ConfigItem label="Conversation starters" value={config.conversationStarters?.map((starter, index) => (
                                        <li key={index} className="text-sm">
                                            {starter}
                                        </li>
                                        ))} />
                                </div>

                                <div className='mt-4'>
                                    <div className='flex flex-col items-end w-full'>
                                        <Button onClick={() => handleOpenDialog('home-screen')}>
                                            Manage Home Screen
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>

                {/* isOpen, setOpenEmailModal, channel, setEmailChannel, type  */}

                {/* update web chat channel dialog */}
                <UpdateWebChannelWrapper
                channel={channelDetails}
                setEmailChannel={setChannelDetails}
                isOpen={updateDialog}
                setOpenEmailModal={setUpdateDialog} 
                type={dialogType} />

            </CardContent>
        </Card>
    )
}

const ConfigSection = ({ icon, title, content }) => (
    <div>
        <h3 className="flex items-center space-x-2 font-semibold text-lg mb-3">
            {icon}
            <span>{title}</span>
        </h3>
        {content}
    </div>
)
  
const ConfigItem = ({ label, value }) => (
    <div className="mb-2">
        <span className="font-medium">{label}:</span> <span className="text-sm">{value}</span>
    </div>
)

const ColorDisplay = ({ label, color }) => (
    <div className="flex items-center space-x-2">
        <div className="w-6 h-6 rounded-full border" style={{ backgroundColor: color }}></div>
        <span className="text-sm">
            {label}: {color}
        </span>
    </div>
)