import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'components/ui/dialog'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollArea } from "components/ui/scroll-area";
import { Button } from 'components/ui/button';
import { Context } from 'context/GlobalState';
import { ChevronDown, Circle } from 'lucide-react';
import { SearchBar } from 'pages/PromptingAndRagTraining/components/Instructions/Filters';
import { formatDateToDDMMYYY } from 'utils/timeUtils';
import { OrganizationContext } from 'context/OrganizationContext';

const OpCodeSelectionModal = ({ opcodes, disabled, onOpcodeSelect, defaultSelectedOpcode, defaultSelectedOpcodes, multiSelect=false }) => {

    const [opcodeSelectObj, setOpcodeSelectObj] = useState(null);
    const [selectedOpcodes, setSelectedOpcodes] = useState([])
    
    const [selectedOpcode, setSelectedOpcode] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)

    useEffect(() => {
        
        if (multiSelect) {
            if (defaultSelectedOpcodes) {
                setSelectedOpcodes(defaultSelectedOpcodes)
                setOpcodeSelectObj(opcodes.find(item => item.opcode_id === defaultSelectedOpcode))
            }
        } else {
            if (defaultSelectedOpcode) {
                setSelectedOpcode(defaultSelectedOpcode)
            }
        }
   
    }, [defaultSelectedOpcode, defaultSelectedOpcodes])


    
    const handleOpcodeSelect = (opcode) => {
        if (selectedOpcode === opcode.opcode_id) {
            resetOpcodeDetails();
        } else {
            setSelectedOpcode(opcode.opcode_id);
            setOpcodeSelectObj(opcode);
        }
    };

    const resetOpcodeDetails = () => {
        setSelectedOpcode(null);
        setOpcodeSelectObj(null)
    };

    const handleDone = () => {
        if (multiSelect) {
            if (!selectedOpcodes) {
                addNewNotifcation("Please select at least one opcode first.", "warning");
                return;
            }
            onOpcodeSelect(selectedOpcodes);
        } else {
            if (!selectedOpcode) {
                addNewNotifcation("Please select an opcode first.", "warning");
                return;
            }
            onOpcodeSelect(selectedOpcode);
        }
    };

    const handleFilterOpcodes = () => {
        return opcodes.filter(opcode => (opcode.opcode_id.toLowerCase().includes(searchTerm.toLowerCase())));
    }

    // Handle search term change
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleMultiSelect = (e, opCode) => {
        e.stopPropagation()

        if (selectedOpcodes.includes(opCode.opcode_id)) {
            setSelectedOpcodes(prev => prev.filter(item => item !== opCode.opcode_id))
        } else {
            setSelectedOpcodes(prev => ([...prev,  opCode.opcode_id]))
        }

    }

    const getCreatedBy = (createBy) => {
        if (selectedOrganization.superAdmin._id === createBy) {
            return selectedOrganization?.superAdmin?.name || '-'
        } else {
            const member = selectedOrganization.members.find(item => item.id._id === createBy)
            return member?.id?.name || '-'
        }
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button disabled={disabled} variant="outline" className="w-full">
                    <ChevronDown className="mr-2 h-4 w-4" />
                    {disabled ? 'loading...' : (
                        multiSelect ? defaultSelectedOpcodes.length > 0 ? <span className='w-full max-w-[400px] truncate'> {defaultSelectedOpcodes.slice(0, 2).join(', ')}... </span> : 'Select opcodes' : defaultSelectedOpcode ? (
                            <span> {defaultSelectedOpcode} </span>
                        ) : 'Select an opcode'
                    )}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>Select an Opcode</DialogTitle>
                    <DialogDescription>
                        Choose an opcode and see its details.
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex flex-col md:flex-row gap-4">
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="mb-6 flex flex-col sm:flex-row gap-4 items-center p-4">
                                <div className="flex-grow">
                                    <SearchBar onSearch={handleSearch} />
                                </div>
                            </div>
                            <div className="grid grid-cols-3 grid-rows-5 gap-4 p-4">
                                {handleFilterOpcodes().length > 0 ? (
                                    handleFilterOpcodes().map((opcode) => (
                                        <Button
                                            key={opcode.opcode_id}
                                            variant={selectedOpcode === opcode.opcode_id ? "default" : "outline"}
                                            onClick={() => handleOpcodeSelect(opcode)}
                                            className="justify-start h-auto text-left overflow-hidden px-2"
                                        >
                                            <div className="w-full flex gap-1">
                                                {multiSelect && (
                                                    <Button onClick={(e) => handleMultiSelect(e, opcode)} size='icon' variant='ghost' className='relative min-w-[24px]'>
                                                        {selectedOpcodes.includes(opcode.opcode_id) ? (
                                                            <div className='min-w-[22px] min-h-[22px] absolute top-[8px] rounded-full bg-black border border-white' />
                                                        ) : (
                                                            <Circle />
                                                        )}
                                                    </Button>
                                                )}
                                                <div className='flex flex-grow flex-col w-full'>
                                                    <div className="font-semibold w-full truncate">{opcode.opcode_id}</div>
                                                    <div className="text-xs text-muted-foreground">{opcode.type}</div>
                                                </div>

                                            </div>
                                        </Button>
                                    ))
                                ) : (
                                    <p>No opcodes available</p>
                                )}
                            </div>
                        </ScrollArea>
                        {selectedOpcode && (
                            <div className="md:w-1/3 p-4 bg-muted rounded-md h-96 max-h-96 overflow-y-auto">
                                <h2 className="mb-2 font-semibold text-xl">{selectedOpcode}</h2>

                                {opcodeSelectObj?.description && (
                                    <div className="mb-4">
                                        <p>{opcodeSelectObj?.description}</p>
                                    </div>
                                )}

                                <div className="mb-4">
                                    <h4 className="font-semibold">created:</h4>
                                    <p>{opcodeSelectObj?.created? formatDateToDDMMYYY(opcodeSelectObj?.created) : '-'}</p>
                                </div>

                                {opcodeSelectObj?.updated && (
                                    <div className="mb-4">
                                        <h4 className="font-semibold">updated:</h4>
                                        <p>{opcodeSelectObj?.updated? formatDateToDDMMYYY(opcodeSelectObj?.updated) : '-'}</p>
                                    </div>
                                )}
                                {opcodeSelectObj?.createdBy && (
                                    <div className="mb-4">
                                        <h4 className="font-semibold">Create by:</h4>
                                        <p>{getCreatedBy(opcodeSelectObj?.createdBy) || '-'}</p>
                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                    <div className="flex justify-end space-x-2"> 
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        {multiSelect ? selectedOpcodes.length > 0 && (
                            <div className="flex justify-end">
                                <DialogTrigger asChild>
                                    <Button variant="default" onClick={handleDone}>Select</Button>
                                </DialogTrigger>
                            </div>
                        ) : selectedOpcode && (
                            <div className="flex justify-end">
                                <DialogTrigger asChild>
                                    <Button disabled={defaultSelectedOpcode === selectedOpcode} variant="default" onClick={handleDone}>Select</Button>
                                </DialogTrigger>
                            </div>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default OpCodeSelectionModal