/* eslint-disable max-lines */
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import React, { useEffect, useState } from 'react'
// import { MinusCircle, Plus } from 'lucide-react'
// import { Button } from 'components/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import NestedSteps from './NestedSteps'
import NextStep from '../NextStep'
import ConditionBuilder from '../StepCondition/ConditionBuilder'
// import { sideOptions } from '../../OpCodeBuilderHAndC/OpCodeBuilderContext'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler'
import { Switch } from 'components/ui/switch'
// import InputValidation from 'components/InputValidation'

const StepLoop = ({stepIndex, inputGroup, inputGroups, userInput, setInputGroups}) => {
    // const [collectionArr, setCollectionArr] = useState("")
    // const [selectedOpt, setselectedOpt] = useState("context")
    const [selectedBreakOpt, setSelectedBreakOpt] = useState("max_iterations")
    // const [optValue, setOptValue] = useState("")
    const [nestedPrevOutputs, setNestedPrevOutputs] = useState([])
    // const [collectionName, setCollectionName] = useState("")
    const parentId = window.crypto.randomUUID()
    const previousOutputs = handler.getPreviousOutputs(inputGroups, stepIndex)
    // const output = inputGroup?.output.Output 
    // const newOptionsList = sideOptions.slice(0,2)
    // const sideOptsList = stepIndex === 0? newOptionsList.filter(opt => opt.value !== "output") : newOptionsList
    // const finalSideOptsList = nested? sideOptsList : stepIndex === 1? newOptionsList.filter(opt => opt.value !== "output") : newOptionsList

    useEffect(() => {
        if(inputGroup?.input?.max_iterations !== undefined && inputGroup?.input?.max_iterations !== null ){
            setSelectedBreakOpt("max_iterations")
        } else if(inputGroup?.input?.break_condition !== undefined && inputGroup?.input?.break_condition !== null) {
            setSelectedBreakOpt("break_condition")
        }

        // const collection = inputGroup?.input?.collection
        // if(collection){
        //     setOptValue(collection)
        // }

        // if(typeof collection === "string"){
        //     // if(collection.startsWith("{{context['real_time_data']}}")){
        //     //     setselectedOpt("realtime")
        //     //     setOptValue("real_time_data")
        //     // } else 
        //     if (collection.startsWith(`"{{context[`)) {
        //         setselectedOpt("context")
        //         const Value = collection.match(/context\['([^']+)'\]/)?.[1] || ""
        //         setOptValue(Value)
        //     } else if (collection.startsWith(`"{{output["`)) {
        //         setselectedOpt("output")
        //         const Value = collection.match(/output\['([^']+)'\]/)?.[1] || ""
        //         setOptValue(Value)
        //     }
        // } 
        // else {
        //     setselectedOpt("static")
        //     setCollectionArr(collection)
        // }
    
    }, [inputGroup])

    // const handleOptSelection = (name) => {
    //     const newInputGroup = {...inputGroup}

    //     // if(name === "realtime"){
    //     //     newInputGroup.input.collection = "{{context['real_time_data']}}"
    //     //     setOptValue("real_time_data"); 
    //     // } else if(name === "static") {
    //     //     newInputGroup.input.collection = [""]
    //     //     setOptValue(""); 
    //     // } else {
    //     newInputGroup.input.collection = ""
    //     setOptValue(""); 
    //     // }

    //     setselectedOpt(name)
    //     setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
    // }

    // const handleCollectionString = (name, value) => {
    //     const newInputGroup = {...inputGroup}

    //     if(name === "context" && value){
    //         newInputGroup.input.collection = `"{{context['${value}']}}"`
    //     } else if(name === "output" && value) {
    //         newInputGroup.input.collection = `"{{output['${value}']}}"`
    //     } 
    //     // else {
    //     //     newInputGroup.input.collection = ""
    //     // }
        
    //     setOptValue(value); 
    //     setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
    // }

    const handleCollectionChange = (value) => {
        const newInputGroup = {...inputGroup}
        newInputGroup.input.collection = value
        // setCollectionName(value)
        setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
        // setOptValue(value)
    }

    const handleBreakOptSelection = (value) => {
        const newInputGroup = {...inputGroup}

        if(value === "break_condition"){
            newInputGroup.input.break_condition = ""
            delete newInputGroup.input.max_iterations
        } else {
            newInputGroup.input.max_iterations = ""
            delete newInputGroup.input.break_condition
        }
        
        setSelectedBreakOpt(value)
        setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
    }

    const handleInputChange = (name, value) => {
        const newInputGroup = {...inputGroup}
        if(name === "break_condition") {
            newInputGroup.input[name] = value 
        } else if (name === "max_iterations") {
            newInputGroup.input[name] = Math.abs(Number(value))
        } else {
            newInputGroup.output.Output = value.trim()
        }

        setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
    }

    const handleParallel = (value) => {
        const newInputGroup = {...inputGroup}
        newInputGroup.input.parallel = value
        setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
    }

	// const handleCollectionArray = (e, collectionIndex) => {
    //     const { value } = e.target
    //     const colArr = [...collectionArr]
    //     colArr[collectionIndex] = value
    //     setCollectionArr(colArr)
    //     const newInputGroup = {...inputGroup}
    //     const collArray =  colArr.filter(item => item.trim() !== "")
    //     newInputGroup.input.collection = collArray.length > 0 ? collArray : [""]
    //     setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
	// }

	// const addCollectionField = (position) => {
    //     setCollectionArr((prevGroups) => {
    //         const newGroups = [...prevGroups]; // Create a copy of the previous array
    //         newGroups.splice(position, 0, ''); // Insert the new item at the specified position
    //         return newGroups; 
    //     })
	// }

	// const removeCollectionField = (position) => {
	
    //     if (collectionArr.length > 1) {
    //         const newInputGroup = {...inputGroup}
    //         const newGroups = [...collectionArr]; 
    //         newGroups.splice(position, 1)
    //         newInputGroup.input.collection = newGroups
    //         setCollectionArr(newGroups);
    //         setInputGroups(prev => prev.map(step => step.step_id === inputGroup.step_id? newInputGroup : step))
    //     }
	// }

	return (
    <div className='w-full space-y-7'>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
            <div className="">
                <Label htmlFor="collection">List Name</Label>
                <Input
                id={`collection`}
                name="collection"
                value={inputGroup?.input?.collection}
                onChange={(e) => handleCollectionChange(e.target.value.trim())}
                placeholder="Enter list name here"
                className="border-0 focus-visible:ring-0 focus-visible:ring-offset-0 bg-white"
                />
            </div>
            
            <div className="">
                <Label htmlFor={`input`} className="">
                    Parallel
                </Label>
                <div className="flex items-center bg-white gap-2 h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
                    <Switch id="real-time-data" checked={inputGroup?.input?.parallel} onCheckedChange={handleParallel} />
                    <span className="text-xs text-gray-400">Include Parallel</span>
                </div>
            </div>
            {/* <Select value={selectedOpt} onValueChange={(value) => handleOptSelection(value)}>
                <SelectTrigger id={`select-opt`} className="bg-white">
                    <SelectValue placeholder={`Select Option`} />
                </SelectTrigger>
                <SelectContent>
                    {finalSideOptsList.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                            {option.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select> */}

            {/* {selectedOpt === "context" && 
                // <ContextModal 
                // currentValue={optValue} 
                // onSelect={(context) => handleCollectionString("context", context)} 
                // inputGroups={inputGroups}
                // setInputGroups={setInputGroups}
                // initialCondition={inputGroup?.input?.collection || ""}
                // side={"leftSide"}
                // />
              
            } */}

            {/* {selectedOpt === "static" && (
                <div className="grid grid-cols-3 gap-2 items-center">
                    {collectionArr.map((coll, collIndex) => (
                        <div key={collIndex} className="flex items-center space-x-1">
                            <div className="flex-grow flex items-center space-x-1 bg-white rounded-md border border-input">
                                <Input
                                id={`collection-${collIndex}`}
                                name="collection"
                                value={coll}
                                onChange={(e) => handleCollectionArray(e, collIndex)}
                                placeholder="Enter collection variable name"
                                className="border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                                />
                            </div>
                            <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => addCollectionField(collIndex + 1)}
                            className="h-9 px-2"
                            aria-label="Add field after"
                            >
                                <Plus className="h-4 w-4 ml-1" />
                            </Button>
                            {collectionArr.length > 1 && (
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => removeCollectionField(collIndex)}
                                    aria-label="Remove field"
                                >
                                    <MinusCircle className="h-4 w-4" />
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
            )} */}

            {/* {selectedOpt === "output" && (
                <Select value={optValue} onValueChange={(value) => handleCollectionString("output", value)}>
                    <SelectTrigger className="bg-white">
                        <SelectValue placeholder="Select previous output" />
                    </SelectTrigger>
                    <SelectContent>
                        {previousOutputs.map((output) => (
                            <SelectItem key={output.stepId} value={output.prevOutp.value}>
                                {output.prevOutp.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            )} */}

            {/* {selectedOpt === "realtime" && (
                <Input placeholder="Real Time Data" value="real_time_data" className="bg-white" disabled/>
            )} */}
        </div>

        <NestedSteps 
        parentId={parentId} 
        mainStepIndex={stepIndex} 
        inputGroup={inputGroup}
        inputGroups={inputGroups} 
        setInputGroups={setInputGroups}
        userInput={userInput}
        setNestedPrevOutputs={setNestedPrevOutputs}
        />

        <div className="flex-1 w-full space-y-2">
            <Label htmlFor="collection">Break Loop</Label>
            <Select value={selectedBreakOpt} onValueChange={(value) => handleBreakOptSelection(value)}>
                <SelectTrigger id={`select-opt`} className="bg-white">
                    <SelectValue placeholder={`Select Option`} />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem key={"break_condition"} value={"break_condition"}>
                        Condition
                    </SelectItem>
                    <SelectItem key={"max_iterations"} value={"max_iterations"}>
                        Max Iterations
                    </SelectItem>
                </SelectContent>
            </Select>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {selectedBreakOpt === "break_condition" && 
                    <div className='col-span-3'>
                        <ConditionBuilder
                        stepIndex={stepIndex}
                        inputGroups={inputGroups}
                        setInputGroups={setInputGroups}
                        onConditionChange={(value) => handleInputChange("break_condition", value)}
                        initialCondition={inputGroup?.input?.break_condition || ""}
                        previousOutputs={[...previousOutputs, ...nestedPrevOutputs]}
                        loop={true}
                        />
                    </div>
                }

                {selectedBreakOpt === "max_iterations" && 
                    <div className="col-span-1">
                        <Label htmlFor="max_iterations">Max Iterations</Label>
                        <Input
                        id="max_iterations"
                        name="max_iterations"
                        type="number"
                        min={1}
                        value={inputGroup?.input?.max_iterations || ""}
                        onChange={(e) => handleInputChange("max_iterations", e.target.value)}
                        placeholder="Enter maximum iterations"
                        className="bg-white"
                        />
                    </div>
                }

                {/* <div className="">
                    <Label htmlFor={`output-${stepIndex}`}>Step Output key</Label>
                    <InputValidation
                        id={`output-${stepIndex}`}
                        value={output || ""}
                        onChange={(e) => handleInputChange("Output", e.target.value)}
                        className="bg-white"
                        validation={handler.validateOutputName}
                        disabled={handler.isOutputUsedInLaterSteps(inputGroups, output, stepIndex)}
                    />
                </div> */}
                <div className="col-span-1">
                    <NextStep
                    inputGroup={inputGroup}
                    inputGroups={inputGroups}
                    setInputGroups={setInputGroups}
                    stepIndex={stepIndex}
                    />
                </div>
            </div>
        </div>

    </div>
    )
}

export default StepLoop