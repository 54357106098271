import React, { useContext } from 'react'
import StyledTextArea from "../StyledTextArea";
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';

const AnatomyLivePrompt = ({ isExecute=false, actions = {} }) => {
    const { state, dispatch } = useContext(AiLabContext);
    const handleFormChange = (field, value) => {
        dispatch({
          type: 'UPDATE_FIELD',
          field,
          value,
        });
    };

    return (
        <div className='flex flex-col gap-4 px-1 py-1'>
            <StyledTextArea
                value={state?.promptLab?.form?.instruction}
                onChange={(e)=> handleFormChange("instruction", e.target.value)}
                labelText="Instruction"
                tooltipText="Provide specific tasks or questions for the language model to address. This sets the purpose
                and direction for the response."
                required={true}
                actions={actions?.instruction || []}
            />
            <StyledTextArea
                value={state?.promptLab?.form?.context}
                onChange={(e)=> handleFormChange("context", e.target.value)}
                labelText="Context"
                tooltipText="Supply background information or details that help the language model understand the scenario
                or topic, ensuring relevant and accurate responses."
                required={false}
                actions={actions?.context || []}
                maxRows={20}
            />
            <StyledTextArea
                value={state?.promptLab?.form?.targetFormat}
                onChange={(e)=> handleFormChange("targetFormat", e.target.value)}
                labelText="Target Format"
                tooltipText="Specify the structure and style of the response. This ensures that the output is consistent
                with your needs, whether it's a specific layout, tone, or type of information."
                required={false}
            />
            <div className="grid grid-cols-2 gap-4">
                <StyledTextArea
                    value={state?.promptLab?.form?.sampleInput}
                    onChange={(e)=> handleFormChange("sampleInput", e.target.value)}
                    labelText="Sample Input"
                    tooltipText="Offer an example of what the input data might look like. This helps clarify the format
                    and type of data expected."
                    required={false}
                />
                <StyledTextArea
                    value={state?.promptLab?.form?.sampleOutput}
                    onChange={(e)=> handleFormChange("sampleOutput", e.target.value)}
                    labelText="Sample Output"
                    tooltipText="Show a representative example of the desired response. This demonstrates the format
                    and quality of output you are looking for from the language model."
                    required={false}
                />
            </div>
            {isExecute && (
                <StyledTextArea
                disabled={!isExecute}
                value={state?.promptLab?.form?.inputData}
                onChange={(e)=> handleFormChange("inputData", e.target.value)}
                labelText="Input Data"
                tooltipText="Include any data or details that the language model needs to process in order to generate
                a meaningful response. This can include text, numbers, or structured data."
                required={false}
                />
            )}
        </div>
    );
}

export default AnatomyLivePrompt