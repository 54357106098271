import React from 'react';
import Markdown from 'react-markdown';
import { Button } from 'components/ui/button';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import CopyButton from '../CopyButton';
import '../aiLab.css';
import { Check } from 'lucide-react';

export const AssistantMessage = ({ content, onReferenceClick, canClickRef = false, header = "" }) => {
    // Function to handle button click
    const handleCitationClick = (name, page) => {
        onReferenceClick(name, page);
    };

    // Function to render text with citations replaced by buttons
    const renderTextWithCitations = (text) => {
        const citationRegex = /<docName>(.*?)<\/docName><page>(.*?)<\/page>/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = citationRegex.exec(text)) !== null) {
            const [fullMatch, name, page] = match;
            const index = match.index;

            // Add text before the citation
            if (index > lastIndex) {
                parts.push(text.slice(lastIndex, index));
            }

            // Add the citation button
            parts.push(
                <Button
                    key={`${name}-${page}-${index}`}
                    onClick={() => handleCitationClick(name, page)}
                    className="bg-black rounded-full px-2 py-0.5 text-white h-fit text-xs"
                >
                    {name}, Page {page}
                </Button>
            );

            lastIndex = index + fullMatch.length;
        }

        // Add remaining text after the last citation
        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }

        return parts;
    };

    return (
        <div className="flex mb-4 mt-4 gap-2 items-start min-h-full">
            <div className="bg-white text-gray-900 p-3 min-h-full">
                {header &&
                    <div className=" flex flex-row gap-2 bg-white text-xs italic text-gray-400">
                        <p>Collection: {header} </p>
                        {canClickRef ? <Check className='w-4 h-4 text-green-600'/> : ''}
                    </div>
                }
                {/* <hr className='bg-gray-200 w-full'/> */}
                <div className="text-xs break-words whitespace-pre-wrap min-h-full">
                    <Markdown
                        className="ai-lab-markdown"
                        remarkPlugins={[remarkGfm]}
                        components={{
                            // Custom renderer for code blocks (unchanged)
                            code({ node, inline, className, children, ...props }) {
                                const match = /language-(\w+)/.exec(className || '');
                                const code = String(children).replace(/\n$/, '');

                                inline = !className && !/\n/.test(code);
                                if (inline) {
                                    return (
                                        <code
                                            className="bg-gray-200 text-gray-800 rounded-[4px] px-1 text-xs font-mono"
                                            style={{ whiteSpace: 'pre-wrap' }}
                                        >
                                            {children}
                                        </code>
                                    );
                                }

                                if (!inline && match) {
                                    return (
                                        <div className="relative rounded-[12px]">
                                            <span className="absolute top-2 left-2 bg-gray-600 text-white text-xs px-2 py-1 rounded select-none">
                                                {match[1]}
                                            </span>
                                            <CopyButton code={code} className="text-gray-200" />
                                            <SyntaxHighlighter
                                                style={darcula}
                                                language={match[1]}
                                                PreTag="div"
                                                {...props}
                                                customStyle={{ paddingTop: '2.5rem' }}
                                            >
                                                {code}
                                            </SyntaxHighlighter>
                                        </div>
                                    );
                                }

                                return (
                                    <div
                                        className="bg-[#2c2c2c] rounded-md p-2 pt-[1.5rem] text-xs text-gray-200 font-mono whitespace-pre-wrap relative"
                                        style={{ overflowX: 'auto' }}
                                    >
                                        <CopyButton code={code} className="text-gray-200" />
                                        {children}
                                    </div>
                                );
                            },
                            // Custom renderer for paragraphs to handle citations
                            p({ node, children }) {
                                const text = React.Children.toArray(children).join('');
                                const renderedText = renderTextWithCitations(text);
                                return <p>{renderedText}</p>;
                            },
                            ul({ node, children }) {
                                return (
                                    <ul>
                                        {React.Children.map(children, (child) => {
                                            if (child?.type === 'li') {
                                                const text = React.Children.toArray(child.props.children).join('');
                                                const renderedText = renderTextWithCitations(text);
                                                return <li>{renderedText}</li>;
                                            }
                                            return child;
                                        })}
                                    </ul>
                                );
                            },
                        }}
                    >
                        {content}
                    </Markdown>
                </div>
            </div>
        </div>
    );
};

export const UserMessage = ({ content }) => (
    <div className="flex mb-4 mt-4 gap-2 items-start justify-end">
        <div className="bg-gray-100 text-gray-800 rounded-lg p-3">
            <p className="text-xs break-words whitespace-pre-wrap">{content}</p>
        </div>
    </div>
);
