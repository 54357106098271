import React from 'react';
import { Card, CardHeader, CardContent, CardDescription } from "components/ui/card";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "../aiLab.css";
import remarkGfm from "remark-gfm";
import CopyButton from '../CopyButton';
import { Button } from 'components/ui/button';

export function Output({ output, handleSendPrompt, btnLoading }) {

    return (
        <Card className="w-full flex flex-col h-full justify-between">
            {/* HEADER */}
            <CardHeader className="border-b-[1px] border-gray-200 px-6 py-4 select-none">
                {/* <CardTitle className="text-sm">Prompt Output</CardTitle> */}
                <CardDescription className="text-xs flex flex-row justify-between items-center">
                    <div className="h-full flex items-center">
                        The generated output will be displayed here.
                    </div>
                    {/* <div className="flex items-center">
                        <Button className='px-1' size='icon' variant="ghost" disabled>
                            <Download size={16} className='w-5 h-5' />
                        </Button>
                    </div> */}
                    <Button disabled={btnLoading} onClick={handleSendPrompt}>
                        Generate
                    </Button>
                </CardDescription>    
            </CardHeader>

            {/* CONTENT */}
            <CardContent className="p-0 flex flex-col px-6 py-4 flex-grow">
                <div className='break-words text-xs min-h-[122px] whitespace-pre-wrap'>
                    <Markdown className="ai-lab-markdown" remarkPlugins={[remarkGfm]} 
                        components={{
                        code({ inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            const code = String(children).replace(/\n$/, '');
                            
                            inline = !className && !/\n/.test(code);
                            if (inline) {
                            // Inline Code Block
                            return (
                                <code
                                className="bg-gray-200 text-gray-800 rounded-[4px] px-1 text-xs font-mono"
                                style={{ whiteSpace: 'pre-wrap' }}
                                >
                                    {children}
                                </code>
                            );
                            }
                    
                            if (!inline && match) {
                            // Multi-Line Code Block with Language
                            return (
                                <div className="relative rounded-[12px]">
                                    <span className="absolute top-2 left-2 bg-gray-600 text-white text-xs px-2 py-1 rounded select-none">
                                        {match[1]}
                                    </span>
                                    <CopyButton code={code} className="text-gray-200" />
                                    <SyntaxHighlighter
                                        style={darcula}
                                        language={match[1] !== "markdown" ? match[1] : ""}
                                        PreTag="div"
                                        {...props}
                                        customStyle={{ paddingTop: '2.5rem'  }}
                                    >
                                        {code}
                                    </SyntaxHighlighter>
                                </div>
                            );
                            }
                    
                            return (
                                <div
                                    className="bg-[#2c2c2c] rounded-md p-2 pt-[1.5rem] text-xs text-gray-200 font-mono whitespace-pre-wrap relative"
                                    style={{ overflowX: 'auto' }}
                                >
                                    <CopyButton code={code} className="text-gray-200" />
                                    {children}
                                </div>
                            );
                        },
                    }}>
                        {output}
                    </Markdown>
                </div>
            </CardContent>
        </Card>
    );
}