
class PromptLabHelper {
    formatToTitleCase = (str) => {
        return str
            .replace(/_/g, ' ')           // Replace underscores with spaces
            .replace(/\w\S*/g, function(word) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });                           // Convert to Title Case
    }

    extractValues(inputString) {
        // Regular expression to match the labels and capture values between them
        const labels = ["Instruction:", "Context:", "Target Format:", "Sample Input:", "Sample Output:"];
        const pattern = new RegExp(
          `(${labels.join('|')})\\s*(.*?)(?=(?:${labels.join('|')})|\\s*$)`,
          'gs'
        );
      
        const result = {};
        let match;
      
        // Find all matches using regular expression
        while ((match = pattern.exec(inputString)) !== null) {
          const label = match[1].trim();
          const value = match[2].trim();
          
          result[label] = value;
        }
      
        return result;
    }
}

const promptLabHelper = new PromptLabHelper();
export default promptLabHelper;