import { projectOHttpClient, createWebSocketClient } from "api/clients/project-o.httpClient";

class AssistantWsService {
    constructor() {
        this.assistant = null;
        this.heartbeatInterval = null;
        this.maxRetries = 3;
    }

    setAssistant = (assistant_id) => {
        this.assistant = assistant_id;
    }

    /**
     * Sends data to the assistant for processing.
     *
     * @async
     * @function sendToAssistant
     * @param {string} organizationId - The ID of the organization.
     * @param {string} subOrganizationId - The ID of the sub-organization.
     * @param {string} opcodeId - The operation code identifier.
     * @param {Object} inputData - The context dictionary
     * @returns {Promise<any>} - A promise that resolves with the response from the assistant.
     */
    async sendToAssistant(organizationId, subOrganizationId, opcodeId, inputData) {
        if (!this.assistant) {
            throw new ReferenceError("No BeX assistant was set.");
        }
        if (!opcodeId) {
            throw new ReferenceError("No OpCode ID provided.");
        }


        const payload = {
            assistant_name: this.assistant,
            org_id: organizationId,
            sub_org_id: subOrganizationId,
            input_data: inputData,
            opcode_id: opcodeId,
            mode: "Specific",
            log_id: "999"
        };

        try {
            const triggerResult = await projectOHttpClient.post("/assistant/autoop/execute", payload);

            if (!triggerResult.data.task_id) {
                throw new Error("Task ID not found in the response.");
            }

            return this.connectWebSocket(triggerResult.data.task_id);
        } catch (error) {
            console.error("Failed to trigger assistant operation:", error);
            throw error;
        }
    }

    connectWebSocket(taskId) {
        return new Promise((resolve, reject) => {
            let retryCount = 0;

            const onOpen = (e, wsClient) => {
                console.log("WebSocket connected from BeX Assistant");
                wsClient.send(JSON.stringify({ type: "init" }));

                this.heartbeatInterval = setInterval(() => {
                    if (wsClient.readyState === WebSocket.OPEN) {
                        wsClient.send(JSON.stringify({ type: "ping" }));
                        console.log("Ping sent");
                    }
                }, 15000); // 15 seconds
            };

            const onMessage = (e) => {
                // console.log(JSON.parse(e.data))
                if(JSON.parse(e.data).state === "SUCCESS"){
                    resolve(JSON.parse(e.data))
                }
            };

            const onError = (e, wsClient) => {
                console.error("WebSocket error:", e);

                if (retryCount < this.maxRetries) {
                    const backoffDelay = Math.pow(2, retryCount) * 1000; // Exponential backoff
                    console.log(`Attempting to reconnect in ${backoffDelay / 1000} seconds... (Attempt ${retryCount + 1}/${this.maxRetries})`);

                    setTimeout(() => {
                        retryCount++;
                        this.createWebSocketClient(taskId, onOpen, onMessage, onError, onClose);
                    }, backoffDelay);
                } else {
                    console.error("Max retry attempts reached");
                    reject("Failed to establish WebSocket connection after multiple attempts");
                }
            };

            const onClose = () => {
                console.log("WebSocket closed");
                clearInterval(this.heartbeatInterval);
            };

            this.createWebSocketClient(taskId, onOpen, onMessage, onError, onClose);
        });
    }

    createWebSocketClient(taskId, onOpen, onMessage, onError, onClose) {
        createWebSocketClient(
            `/assistant/autoop/${taskId}/ws`,
            { onOpen, onMessage, onError, onClose }
        );
    }
}

const assistantWsService = new AssistantWsService();
export { assistantWsService };