/* eslint-disable max-lines */
import React, { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { SendHorizontal, RotateCcw } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { AiLabContext } from 'pages/PromptingAndRagTraining/context/AiLabContext';
import { AssistantMessage, UserMessage } from './MessageDisplay';
import { milvusService } from 'api/services/PROJECT-O/Milvus.service';
import { useParams } from 'react-router-dom';
import { AiTeacherHelper } from './helper';
import { assistantWsService } from 'api/services/PROJECT-O/WEBSOCKETS/Assistant.websocket.service';
import { Context } from 'context/GlobalState';
import { SubOrgContext } from 'context/SubOrganizationContext';

const AIChatBox = () => {
    const { state, dispatch } = useContext(AiLabContext);
    const { addNewNotifcation } = useContext(Context);
    const { collections } = useContext(SubOrgContext);
    const { oragID, subOragID } = useParams();

    const [userMessage, setUserMessage] = useState('');
    const [asstLoadingMessage, setAsstLoadingMessage] = useState('');
    const [shouldScroll, setShouldScroll] = useState(false);

    const scrollTargetRef = useRef(null);
    const containerRef = useRef(null);

    const { aiTeacher } = state || {};
    const { aiTeacherMessages: messages, mode, ragForm, fileSystem, isAiTeacherMessageLoading } = aiTeacher || {};
    const { COMMON_ORG, COMMON_SUBORG, AI_TEACHER_ASST_ID: ASST_ID, aiTeacherOpcodes: modes } = aiTeacher || {};

    useEffect(() => {
        if (shouldScroll && scrollTargetRef.current && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
            setShouldScroll(false);
        }
    }, [shouldScroll]);

    const handleOnChangeMode = useCallback((mode) => {
        dispatch({ type: "CHANGE_MODE", value: mode });
    }, [dispatch]);

    const handleSendMessage = async () => {
        if (!userMessage.trim()) return;

        try {
            setUserMessage('');
            dispatch({ type: "SET_LOADING", value: true });

            const userMsgObject = { role: "user", content: userMessage.trim() };
            dispatch({ type: "ADD_MESSAGE", value: userMsgObject });
            setShouldScroll(true);

            setAsstLoadingMessage("Searching through the collection...");
            const RAGForm = {
                organization_id: oragID,
                sub_organization_id: subOragID,
                collection_id: ragForm?.collection_id,
                query_text: userMessage.trim(),
                top_k: 10,
                offset: 0,
                top_n: 2,
                use_rerank: true,
                filters: {},
                search_type: "hybrid"
            };

            const searchResult = await milvusService.hybridSearch(RAGForm);
            const { compiled_text } = AiTeacherHelper.compileRAGResults(searchResult?.data);

            setAsstLoadingMessage("Thinking...");
            const asstQuery = AiTeacherHelper.appendRAGandMSG(compiled_text, userMessage);

            assistantWsService.setAssistant(ASST_ID);
            const response = await assistantWsService.sendToAssistant(
                COMMON_ORG,
                COMMON_SUBORG,
                modes[mode]?.opcode,
                { real_time_data: asstQuery.trim() }
            );

            const lastStepOutput = response?.result?.execution_log?.slice(-1)[0]?.Outputs?.ai_response || '';
            const assistantResponse = lastStepOutput || "The server is busy. Please try again later.";

            const assistantMsgMessage = {
                role: "assistant",
                content: assistantResponse,
                collection: collections.find((item) => item._id === ragForm?.collection_id)?.name || ""
            };
            dispatch({ type: "ADD_MESSAGE", value: assistantMsgMessage });

        } catch (error) {
            console.error("Error in handleSendMessage:", error);
            addNewNotifcation(error.message || "An error occurred. Please try again.", "error");
        } finally {
            setAsstLoadingMessage("");
            dispatch({ type: "SET_LOADING", value: false });
        }
    };

    const onReferenceClick = useCallback((name, page) => {
        if (fileSystem.length === 0 || AiTeacherHelper.findFile(name, fileSystem) === null) {
            addNewNotifcation("Please make sure you are in the same collection as the response and the collection tag is checked.", "warning");
            return;
        }

        const file = AiTeacherHelper.findFile(name, fileSystem);
        if (file && file !== aiTeacher?.selectedDocument) {
            dispatch({ type: "SET_DOCUMENT", value: file });
        }
        if (file && page && page !== aiTeacher?.currentPage) {
            dispatch({ type: "SET_CURRENT_PAGE", value: parseInt(page) });
        }
    }, [fileSystem, aiTeacher, dispatch, addNewNotifcation]);

    const handleOnClearMessages = useCallback(() => {
        dispatch({ type: "RESET_MESSAGES" });
    }, [dispatch]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
    }, [handleSendMessage]);

    return (
        <Card className="w-full flex flex-col justify-between h-fit min-h-[480px] max-h-[480px]">
            <CardHeader className="max-h-[72px] border-b-[1px] border-gray-200 px-4 py-3.5 flex flex-row flex-nowrap items-center gap-2 select-none">
                <div className="w-full flex flex-col">
                    <CardTitle className="text-sm">AI Teacher</CardTitle>
                    <CardDescription className="text-xs">Study with an AI.</CardDescription>
                </div>
                <div className="w-fit flex flex-row gap-2 items-center justify-end">
                    <Select value={mode} onValueChange={handleOnChangeMode}>
                        <SelectTrigger id="mode-select" className="w-[180px] text-left">
                            <SelectValue placeholder="Select Mode" />
                        </SelectTrigger>
                        <SelectContent className="w-56" align="end">
                            {modes && Object.entries(modes).map(([key, item], index) => (
                                <SelectItem value={key} key={index} disabled={key === "quiz"}>
                                    <div>
                                        <span className="font-bold">{item?.name}</span>
                                        <p className="text-xs text-gray-500">{item?.description}</p>
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </CardHeader>

            <CardContent className="flex flex-col justify-end gap-4 p-0 flex-grow">
                <div className="h-[340px] px-2 overflow-y-auto" ref={containerRef}>
                    {messages.filter((message) => message.role !== "system").map((chat, index) => (
                        <div key={index}>
                            {chat.role === "user" ? (
                                <UserMessage content={chat.content} />
                            ) : (
                                <AssistantMessage
                                    content={chat.content}
                                    onReferenceClick={onReferenceClick}
                                    canClickRef={fileSystem.length !== 0 && collections.find((coll) => ragForm?.collection_id === coll._id)?.name === chat?.collection}
                                    header={chat?.collection}
                                />
                            )}
                            {isAiTeacherMessageLoading && ((index === messages.length - 2 && messages[messages.length - 1].role === "assistant") || (index === messages.length - 1 && messages[messages.length - 1].role === "user")) && (
                                <div className="italic text-gray-400 text-sm mb-4 ml-3">
                                    <span>{asstLoadingMessage}</span>
                                </div>
                            )}
                        </div>
                    ))}
                    <div ref={scrollTargetRef} />
                </div>
            </CardContent>

            <CardFooter className="h-16 max-h-16 border-t-[1px] border-gray-200 px-4 py-4 flex flex-row flex-nowrap gap-2">
                <Input
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="Ask the AI Teacher..."
                    className="col-span-1 h-10 text-xs border-none shadow-none focus-visible:ring-0 focus-visible:ring-offset-0 focus:ring-0 ring-0 focus:outline-0 focus-visibe:outline-none outline-none"
                />
                <Button variant="ghost" size="icon" className="px-2" disabled={!userMessage.trim()} onClick={handleSendMessage}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
                <Button variant="ghost" size="icon" className="px-2" onClick={handleOnClearMessages}>
                    <RotateCcw className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    );
};

export default AIChatBox;