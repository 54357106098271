import React from 'react'
import { Outlet } from 'react-router-dom'
import { AiLabProvider } from './AiLabContext'

const AiLabWrapper = () => {
    return (
        <AiLabProvider>
            <Outlet />
        </AiLabProvider>
    )
}

export default AiLabWrapper