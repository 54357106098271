import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Textarea } from 'components/ui/textarea'
import React, { useEffect, useState } from 'react'
import OpCodeSelectionModal from './OpCodeSelectionModal'
import { opcodeService } from 'api/services/BEX/opcode.service'
import { useParams } from 'react-router-dom'

export default function AssistantForm({ form, setForm }) {

    const [opcodes, setOpcodes] = useState([])
    const [loading, setLoading] = useState(true)

    const { oragID, subOragID } = useParams()

    useEffect(() => {
        LoadOpcodes()
    }, [])

    const LoadOpcodes = async () => {
        try {
            const res = await opcodeService.listOpCodes(oragID, subOragID);
            setOpcodes(res.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='space-y-4'>
            <div className="space-y-2">
                <Label htmlFor="name" className="text-md">Name <small className='text-red-500'>*</small> </Label>
                <Input
                    value={form.title}
                    onChange={(e) => setForm(prev => ({...prev, title: e.target.value}))}
                    id="name"
                    placeholder="Enter assistant name"
                    className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                />
            </div>
            <div className="space-y-2">
                <Label htmlFor="description" className="text-md">Description</Label>
                <Textarea
                    value={form.description}
                    onChange={(e) => setForm(prev => ({...prev, description: e.target.value}))}
                    id="description"
                    placeholder="Enter assistant description"
                    className="max-h-[120px] w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                />
            </div>

            <div className="space-y-2">
                <div className="w-full">
                    <Label> Opcodes <small className='text-red-500'>*</small></Label>
                    <OpCodeSelectionModal
                    disabled={loading}
                    opcodes={opcodes}
                    defaultSelectedOpcodes={form.opcodes}
                    multiSelect={true}
                    onOpcodeSelect={(res) => setForm(prev => ({...prev, opcodes: res}))}
                    />
                </div>
            </div>
            <div className="space-y-2">
                <div className="w-full">
                    <Label> Default opcode <small className='text-red-500'>*</small></Label>
                    <OpCodeSelectionModal
                    disabled={loading}
                    opcodes={opcodes}
                    defaultSelectedOpcode={form.default_opcode}
                    multiSelect={false}
                    onOpcodeSelect={(res) => setForm(prev => ({...prev, default_opcode: res}))}
                    />
                </div>
            </div>
        </div>
    )
}
