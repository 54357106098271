const { bexHttpClient } = require("api/clients");

class ModelService {
    async getModels(data) {
        const response = await bexHttpClient.post(`/llm/models/get-llm-models`, data);
        return response;
    }
}

const modelService = new ModelService();
export { modelService };